import usermanagementconstants from "./usermanagementconstants";

const initialState = {
  userManagementApiLoading: undefined,
  createUserRoleResp: undefined,
  updateUserRoleResp: undefined,
  getUserRolesResp: undefined,
  getAllPermissionsResp: undefined,
  getPermissionsByRoleResp: undefined,
  createRolePermissionResp: undefined,
  getAllStationsResp: undefined,
  deleteRolesResp: undefined,
  userManagementErrorResp: undefined,
};

export default function userManagement(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case usermanagementconstants.CHANGE_USER_MANAGEMENT_API_LOADING_STATUS:
      return { ...state, userManagementApiLoading: payload };
    case usermanagementconstants.CREATE_USER_ROLE_SUCCESS:
      return { ...state, createUserRoleResp: payload };
    case usermanagementconstants.UPDATE_USER_ROLE_SUCCESS:
      return { ...state, updateUserRoleResp: payload };
    case usermanagementconstants.GET_USER_ROLES_SUCCESS:
      return (state = { ...state, getUserRolesResp: action.payload });
    case usermanagementconstants.GET_ALL_PERMISSIONS_SUCCESS:
      return (state = { ...state, getAllPermissionsResp: action.payload });
    case usermanagementconstants.GET_PERMISSIONS_BY_ROLE_SUCCESS:
      return (state = { ...state, getPermissionsByRoleResp: action.payload });
    case usermanagementconstants.CREATE_ROLE_PERMISSION_SUCCESS:
      return (state = { ...state, createRolePermissionResp: action.payload });
    case usermanagementconstants.GET_ALL_STATIONS_SUCCESS:
      return (state = { ...state, getAllStationsResp: action.payload });
    case usermanagementconstants.DELETE_ROLES_SUCCESS:
      return (state = { ...state, deleteRolesResp: action.payload });
  

    case usermanagementconstants.CREATE_USER_ROLE_FAIL:
    case usermanagementconstants.GET_USER_ROLES_FAIL:
    case usermanagementconstants.UPDATE_USER_ROLE_FAIL:
    case usermanagementconstants.GET_ALL_PERMISSIONS_FAIL:
    case usermanagementconstants.GET_PERMISSIONS_BY_ROLE_FAIL:
    case usermanagementconstants.CREATE_ROLE_PERMISSION_FAIL:
    case usermanagementconstants.GET_ALL_STATIONS_FAIL:
    case usermanagementconstants.DELETE_ROLES_FAIL:
      return { ...state, userManagementErrorResp: payload };
    default:
      return state;
  }
}
