import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import SuppliersIcon from "../../assets/images/warehouse/header/suppliers.png";

export const actions = (id, history,validation,toggleDeleteModal,setSearch,setActiveTabState,getSupplierApiCall) => [
  {
    id: "new",
    label: "New",
    icon: NewIcon,
    clickHandler:() => {
        history.push("/warehousevendors/new");
    }
  },
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler : () => {
      if(id === "new") validation.resetForm();
      else getSupplierApiCall();
      setActiveTabState("details")
    },
  },
  {
    id: "vendors",
    label: "Vendors",
    icon: SuppliersIcon,
    clickHandler:() => history.push("/warehousevendors")
  },
  {
    id: "delete",
    label: "Delete",
    icon: DeleteIcon,
    clickHandler : toggleDeleteModal,
  },
];

export const tabsOptions = [
    {
        id: "details",
        name: "Details"
    },
    {
        id: "all_invoices",
        name: "All Invoices"
    },
    {
        id: "paid",
        name: "Paid"
    },
    {
        id: "unpaid",
        name: "Unpaid"
    },
    // {
    //     id: "attachment",
    //     name: "Attachment"
    // }
]

export const supplierColumnsList = [
  {
    id: "supplierNumber",
    name: "Supplier. Num",
    cellClassName: "fw-medium",
  },
  {
    id: "companyName",
    name: "Company",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "supplierName",
    name: "Supplier Name",
  },
];

export const mapSupplierDetails = (apiResp, setValues, initialValidationValues) => {
  const {
    active,
    supplier,
    supplierAccountNumber,
    typeOfVendor,
    supplierName,
    salutation,
    firstName,
    middleInitial,
    lastName,
    gstNumber,
    flatNumber,
    street,
    // suite,
    landmark,
    city,
    st,
    zip,
    country,
    phone,
    fax,
    email,
    website,
    paymentTerms,
    creditLimit,
    discount,
    fedTaxID,
    comments,
    memo,
    creditBalance,
    defaultAccount,
    printYtd,
    stateTaxID,
    isReport,
    discountPer,
    isOutOfState,
    isLocalTaxesPaid,
    salesPhone,
    salesEmail,
    salesFirstName,
    salesLastName,
    salesPhone2,
    salesEmail2,
    salesFirstName2,
    salesLastName2
  } = apiResp.data.supplier;
  const values = {
    active,
    supplier,
    supplierAccountNumber: supplierAccountNumber || "",
    typeOfVendor: typeOfVendor || "",
    supplierName: supplierName || "",
    salutation: salutation || "",
    firstName: firstName || "",
    middleInitial: middleInitial || "",
    lastName: lastName || "",
    gstNumber: gstNumber || "",
    flatNumber: flatNumber || "",
    street: street || "",
    // suite: suite || "",
    landmark: landmark || "",
    city: city || "",
    st: st || "",
    zip: zip || "",
    country: country || "",
    phone: phone || "",
    fax: fax || "",
    email: email || "",
    website: website || "",
    paymentTerms: paymentTerms || "",
    creditLimit: creditLimit || "",
    discount: discount || "",
    fedTaxID: fedTaxID || "",
    comments: comments || "",
    memo: memo || "",
    creditBalance: creditBalance || "",
    defaultAccount: defaultAccount || "",
    printYtd,
    stateTaxID: stateTaxID || "",
    isReport,
    discountPer: discountPer || "",
    isOutOfState,
    isLocalTaxesPaid,
    salesPhone: salesPhone || "",
    salesEmail: salesEmail || "",
    salesFirstName : salesFirstName || "",
    salesLastName : salesLastName || "",
    salesPhone2 : salesPhone2 || "",
    salesEmail2 : salesEmail2 || "",
    salesFirstName2 : salesFirstName2 || "",
    salesLastName2 : salesLastName2 || ""
  }
  setValues(values);
  initialValidationValues.current= values;
};
