import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Customer
import customer from "./customer/customerreducer";

//Supplier
import supplier from "./supplier/supplierreducer"

//Inventory
import inventory from "./inventory/inventoryreducer";

//PurchaseOrder
import purchaseOrder from "./purchaseorder/purchaseorderreducer";

//Sales
import sales from "./sales/salesreducer";

//MasterData
import masterData from "./masterdata/masterdatareducer";

//UserManagement
import userManagement from "./usermanagement/usermanagementreducer";

//Employee
import employee from "./employee/employeereducer";

//Department
import department from "./department/departmentreducer";

//Department
import designation from "./designation/designationreducer";

//Reports
import zoho from "./zoho/zohoreducer";

//zoho
import reports from "./reports/reportsreducer";

const rootReducer = combineReducers({
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    customer,
    supplier,
    inventory,
    purchaseOrder,
    sales,
    masterData,
    userManagement,
    employee,
    department,
    designation,
    reports,
    zoho
});

export default rootReducer;