import ResetIcon from "../../../assets/images/warehouse/salespage/reset.png";
import BarcodeIcon from "../../../assets/images/warehouse/salespage/bar-code-icon.png";
import AttachmentIcon from "../../../assets/images/warehouse/salespage/attachment.png";
import DeleteIcon from "../../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../../assets/images/warehouse/salespage/new.png";
import InventoryIcon from "../../../assets/images/warehouse/header/inventory.png";
import bundlesIcon from "../../../assets/images/warehouse/salespage/bundles-icon.png";
import bundleIcon from "../../../assets/images/warehouse/salespage/bundle.png";
//Helper

export const actions = (
  history,
  inventoryDetailsValidation,
  // inventoryFinancialValidation,
  justifyPillsToggle,
  setSearch,
  toggleDeleteModal,
  id,
  getBundleItemApiCall,
  getInventoryItemApiCall,
  setUpcSearch,
  path,
  handleBundleReset
) => [
  {
    id: "new",
    label: "New",
    icon: NewIcon,
    clickHandler: () => {
      history.push("/warehouseinventory/new");
    },
  },
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: () => {
      if (id === "new") {
        inventoryDetailsValidation.resetForm();
        // inventoryFinancialValidation.resetForm();
        setSearch("");
        setUpcSearch("");
      } else if (path.startsWith(`/warehouseinventory/bundles-createbundle/${id}`)) {
        getInventoryItemApiCall();
      } else if (path.startsWith(`/warehouseinventory/bundles-createbundle`)) {
        handleBundleReset();
      }
      else{
        // getBundleItemApiCall();
        setUpcSearch("");
      }
      justifyPillsToggle("details");
    },
  },
  {
    id: "inventory",
    label: "Inventory",
    icon: InventoryIcon,
    clickHandler: () => history.push("/warehouseinventory"),
  },
  {
    id: `${ id === 'bundles'? 'create_bundle':'bundles'}`,
    label:`${ id === 'bundles'? 'Create Bundle':'Bundles'}`,
    icon: `${ id === 'bundles'? bundleIcon:bundlesIcon}`,
    clickHandler:() => history.push(`/warehouseinventory/${ id === 'bundles'? 'bundles-createbundle':'bundles'}`)

  },
  
  // {
  //   id: "barcode",
  //   label: "Barcode",
  //   icon: BarcodeIcon,
  // },
  // {
  //   id: "attachment",
  //   label: "Attachment",
  //   icon: AttachmentIcon,
  // },
  {
    id: "delete",
    label: "Delete",
    icon: DeleteIcon,
    clickHandler: toggleDeleteModal,
  },
];