import React, { useMemo } from "react";
import { Button, Label, Input } from "reactstrap";
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateAddBarcodeColumns } from "./helper";
const AddBarcodes = ({ barcodes, setDetails, toggleBarcodeModal }) => {
  const addNewRow = () => {
    setDetails((prev) => ({
      ...prev,
      barcodes: [
        ...prev.barcodes,
        {
          description: "",
          upcCode: "",
          units: "",
        },
      ],
    }));
  };
  const removeRow = (index) => {
    setDetails((prev) => {
      const updatedBarcodes = [...prev.barcodes];
      updatedBarcodes.splice(index, 1);
      return {
        ...prev,
        barcodes: updatedBarcodes,
      };
    });
  };
  const fieldChngHndlr = (e,index) => {
      const {name, value} = e.target;
      setDetails((prev) => {
        const updatedBarcodes = [...prev.barcodes],
        rowData = {...updatedBarcodes[index]};
        updatedBarcodes.splice(index, 1, {
            ...rowData,
            [name] : value,
        });
        return {
          ...prev,
          barcodes: updatedBarcodes,
        };
      });
  }
  const columns = useMemo(() => generateAddBarcodeColumns(removeRow,fieldChngHndlr), []);
  return (
    <div className="pt-4 px-2">
      <WhTableContainer
        columns={columns}
        data={barcodes}
        className="custom-header-css"
        divClass="table-responsive"
        tableClass="align-middle"
        theadClass="table-light"
      />
      <Button color="primary" outline onClick={addNewRow} className="ms-3 mb-4 wh-btn">
        + Add New
      </Button>
      <div className="d-flex justify-content-between px-3">
        <div className="d-flex align-items-center">
          <Label className="me-2 text-nowrap">Display UPC on Invoice:</Label>
          <Input />
        </div>
        <Button color="primary" onClick={toggleBarcodeModal}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddBarcodes;
