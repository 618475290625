import React, { useMemo, useState, useRef, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhTableContainer from "../../Components/Common/WhTables";
import { actions, generateColumns } from "./helper";
import DeleteModal from "../../Components/Common/DeleteModal";
import SearchSales from "./searchsales";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import { getInvoicesOfCustomerAct } from "../../store/customer/customeractions";
import { deleteInvoiceAct } from "../../store/sales/salesactions";
//Context
import { PermissionsContext } from "../../context/PermissionsContext"
//HOC
import withPermission from "../../hoc/withPermission"

function WarehouseSales({ history }) {
  const [tableData, setTableData] = useState(null);
  const [modalTableData, setModalTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [modalPageCount, setModalPageCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [showSearchModal, setShowSearchModal] = useState(false);
  const { permissions } = useContext(PermissionsContext);
  function toggleSearchModal() {
    setShowSearchModal(!showSearchModal);
  }
  const [filters, setFilters] = useState({
    search: "",
    searchBy: "customerNumber",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [customActiveTab, setcustomActiveTab] = useState("posted");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
      setTableData(null);
    }
  };
  const isPaymentAnywhereModelOpen = useRef(false);
  const dispatch = useDispatch();
  const { getInvoicesOfCustomerResp, deleteInvoiceResp, salesErrorResp, customerErrResp, salesApiLoading, getInvoicesOfCustomerLoading } = useSelector(
    ({ customer, sales }) => ({
      getInvoicesOfCustomerResp: customer.getInvoicesOfCustomerResp,
      deleteInvoiceResp: sales.deleteInvoiceResp,
      salesErrorResp: sales.salesErrorResp,
      customerErrResp: customer.customerErrResp,
      salesApiLoading: sales.salesApiLoading,
      getInvoicesOfCustomerLoading: customer.getInvoicesOfCustomerLoading
    }),
    shallowEqual
  );
  const {
    data: { page },
  } = getInvoicesOfCustomerResp || { data: { page: 1 } };
  const columns = useMemo(() => generateColumns(Number(page)), []);
  const inventoryResp = useRef({
    prevGetInvoicesOfCustomerResp: getInvoicesOfCustomerResp,
    prevDeleteInvoiceResp: deleteInvoiceResp,
    prevSalesErrorResp: salesErrorResp,
    prevCustomerErrResp: customerErrResp,
  });
  useEffect(() => {
    const { prevGetInvoicesOfCustomerResp, prevDeleteInvoiceResp, prevSalesErrorResp, prevCustomerErrResp } =
      inventoryResp.current || {};
    if (getInvoicesOfCustomerResp && prevGetInvoicesOfCustomerResp !== getInvoicesOfCustomerResp && !isPaymentAnywhereModelOpen.current) {
      if (!showSearchModal) {
        setTableData(getInvoicesOfCustomerResp?.data?.invoices || []);
        setPageCount(getInvoicesOfCustomerResp?.data?.total);
      } else {
        setModalTableData(getInvoicesOfCustomerResp?.data?.invoices || []);
        setModalPageCount(getInvoicesOfCustomerResp?.data?.total);
      }
    }
    if (deleteInvoiceResp && prevDeleteInvoiceResp !== deleteInvoiceResp) {
      toast.success("Successfully deleted invoice");
      getInvoicesOfCustomerApiCall();
      toggleDeleteModal();
    }
    if (salesErrorResp && prevSalesErrorResp !== salesErrorResp) {
      const { errors, error, message } = salesErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
    }
    if (customerErrResp && prevCustomerErrResp !== customerErrResp && !isPaymentAnywhereModelOpen.current) {
      const { errors, error, message } = customerErrResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
    }
    inventoryResp.current.prevGetInvoicesOfCustomerResp = getInvoicesOfCustomerResp;
    inventoryResp.current.prevDeleteInvoiceResp = deleteInvoiceResp;
    inventoryResp.current.prevSalesErrorResp = salesErrorResp;
    inventoryResp.current.prevCustomerErrResp = customerErrResp;
  }, [getInvoicesOfCustomerResp, deleteInvoiceResp, salesErrorResp, customerErrResp]);
  const getInvoicesOfCustomerApiCall = useCallback(
    (page, pageSize) => {
      const { search, searchBy } = filters;
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
        orderBy:"invoiceDate",
        sort:"desc"
      };
      if (search) params.invoiceNumber = search;
      if (customActiveTab === "posted") params.posted = true;
      else params.posted = false;
      dispatch(getInvoicesOfCustomerAct(generateApiUrl(`get_invoices_of_customer`), params));
    },
    [filters, customActiveTab]
  );

  useEffect(() => {
    if (history.location?.state?.showCreateInvoiceSuccess) {
      toast.success("Successfully saved the invoice details");
      history.replace();
    } else if (history.location?.state?.showDeleteInvoiceSuccess) {
      toast.success("Successfully deleted invoice");
      history.replace();
    }
  }, []);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getInvoicesOfCustomerApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getInvoicesOfCustomerApiCall]);
  const filtersChngHndlr = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };
  const resetFilters = () => {
    setFilters({
      search: "",
      searchBy: "customerNumber",
    });
  };
  const handlePageChange = (page, pageSize) => {
    getInvoicesOfCustomerApiCall(page, pageSize);
  };
  const handleDeleteSalesOrder = () => {
    const payload = [];
    getInvoicesOfCustomerResp?.data?.invoices
      .filter((item, index) => rowSelection[index])
      .forEach((item) => payload.push(item.invoiceNumber));
    dispatch(deleteInvoiceAct(generateApiUrl("delete_invoice"), { invoiceNumbers: payload }));
  };
  const noOfRowsSelected = Object.keys(rowSelection).length;
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper whbrand-text-color">
        <Container fluid>
          <Row className="px-4 mb-2">
            <Col xs={12}>
              <Row>
                <Col xl="3" md="4" xs="4" className="border-section p-3 py-0">
                  <Label htmlFor="userrole" className="form-label mb-1">
                    Search
                  </Label>
                  <div className="d-flex align-items-center">
                    <div className="form-icon flex-grow-1 me-2">
                      {["customerNumber", "salesman"].indexOf(filters.searchBy) > -1 && (
                        <Input
                          name="search"
                          className="form-control form-control-icon me-2 py-1"
                          type="text"
                          value={filters.search}
                          onChange={filtersChngHndlr}
                        />
                      )}
                      {["startDate", "endDate"].indexOf(filters.searchBy) > -1 && (
                        <Input
                          name="search"
                          className="form-control me-2"
                          type="date"
                          value={filters.search}
                          onChange={filtersChngHndlr}
                        />
                      )}
                      {filters.searchBy === "status" && (
                        <Input
                          name="search"
                          className="form-control me-2"
                          type="select"
                          value={filters.search}
                          onChange={filtersChngHndlr}
                        >
                          <option value={false}>Unpaid</option>
                          <option value={true}>Paid</option>
                        </Input>
                      )}
                      {["customerNumber", "salesman"].indexOf(filters.searchBy) > -1 && (
                        <i className="ri-search-line fs-5"></i>
                      )}
                    </div>
                    {/* <Button color="primary" onClick={toggleSearchModal}>
                      Smart Search
                    </Button> */}
                  </div>
                </Col>
                <Col xl="3" lg="4" xs="4">
                  <div className="d-flex justify-content-evenly border border-section py-2 px-1 h-100">
                    {actions(history, toggleDeleteModal, resetFilters, toggleSearchModal).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`action-icon-container ${
                            item.id === "delete" && (noOfRowsSelected === 0 || customActiveTab === "posted" || !permissions["DELETE_OPEN_INV"])
                              ? "show-cursor-not-allowed opacity-50"
                              : "show-cursor-pointer"
                          }`}
                          onClick={item.id === "delete" && (noOfRowsSelected === 0 || customActiveTab === "posted" || !permissions["DELETE_OPEN_INV"]) ? () => {} : item.clickHandler}
                        >
                          {item.id === "smart_search" ? item.icon : <img className="action-icon" src={item.icon} />}
                          <span className="text-nowrap">{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              {history.location?.state?.redirected && (
                <div className="d-flex mt-3">
                  <div
                    className="d-flex align-items-center text-primary fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={history.goBack}
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    <div className="mb-0 text-decoration-underline">Go Back</div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs={12} className="whbrand-navs">
              <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success mb-2">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === "posted" })}
                    onClick={() => {
                      toggleCustom("posted");
                    }}
                  >
                    Posted
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === "open" })}
                    onClick={() => {
                      toggleCustom("open");
                    }}
                  >
                    Open
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col className="px-0" xs={12}>
              <WhTableContainer
                columns={columns}
                data={tableData || []}
                className="custom-header-css"
                divClass="table-responsive mt-1 whbrand-table table-container-po"
                tableClass="align-middle"
                handlePageChange={handlePageChange}
                pageCount={pageCount || 0}
                customPageSize={30}
                showNoDataComponent={true}
                showRowSelection={true}
                setRowSelection={setRowSelection}
                loading={(!tableData || getInvoicesOfCustomerLoading) && !showSearchModal}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal id="showSearchModal" isOpen={showSearchModal} toggle={toggleSearchModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggleSearchModal}>
          Smart Search-Sales
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <SearchSales
              setFilters={setFilters}
              setShowSearchModal={setShowSearchModal}
              modalTableData={modalTableData}
              modalPageCount={modalPageCount || 0}
              getInvoicesOfCustomerAct={getInvoicesOfCustomerAct}
              generateApiUrl={generateApiUrl}
              dispatch={dispatch}
              customActiveTab={customActiveTab}
              getInvoicesOfCustomerLoading={getInvoicesOfCustomerLoading}
            />
          </ModalBody>
        </Form>
      </Modal>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeleteSalesOrder} />
      <PaymentsAnywhere isPaymentAnywhereModelOpen={isPaymentAnywhereModelOpen}/>
      <ApiLoader loading={salesApiLoading} />
    </React.Fragment>
  );
}
export default withPermission(WarehouseSales, "POS_MENU");
