//Utility
import { getMmddyyyyDateFormatted } from "../../../../utility/dateutility";
import { formatAmount } from "../../../../utility/commonutility"
//Constants
import { currencySymbol } from "../../../../constants";
import { setLocalStorage } from "../../../../utility/storageutility";

export function generateColumns(history, page) {
    return [
      {
        Header: "S.No",
        accessor: "id",
        Cell:({row}) => (((page - 1) * 10 ) + (row.index + 1))
    },
      {
        Header: "PO Number",
        accessor: "purchaseOrderNumber",
        Cell: ({ cell: { value } }) => (
          <div
            className="wh-link-color-green"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/warehousepurchaseorders/${value}`, { redirected: true });
              setLocalStorage("activeTab", JSON.stringify({ warehouseinventory: "purchases" }));
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Supplier Name",
        accessor: "supplier.supplierName",
        Cell: ({ row: { original :{supplier} } }) => (
          <div
            className="wh-link-color-green"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/warehousevendors/${supplier.supplierNumber}`, { redirected: true });
              setLocalStorage("activeTab", JSON.stringify({ warehouseinventory: "purchases" }));
            }}
          >
            {supplier.supplierName}
          </div>
        ),
      },
      {
        Header: "Date Received",
        accessor: "dateRecvd",
        Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "-"
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Case Qty",
        accessor: "caseQty",
        Cell:({cell:{value}}) => value || "-"
      },
      {
        Header: "Cost",
        accessor: "price",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: "Total",
        accessor: "rAmount",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
    ];
}