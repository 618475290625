import { call, takeLatest, delay, put } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import reportsconstants from "./reportsconstants";
import {
  changeGetPaymentsReportLoadingStatusAct,
  changeCustomerBalanceStatementLoadingStatusAct,
  changeGetSalesHistoryReportLoadingStatusAct,
  changePurchasesReportLoadingStatusAct,
  changeSalesReportItemsLoadingStatusAct,
  changeCustomersReportLoadingStatusAct,
  changeInvPricingReportLoadingStatusAct,
  changePoAnalysisReportLoadingStatusAct,
  changeSuppliersReportLoadingStatusAct,
  changeGetReturnSalesReportLoadingStatusAct,
  changeGetDriversReportLoadingStatusAct,
  changeGetMsaFileReportLoadingStatusAct,
  changeGetInventoryDetectionReportLoadingStatusAct,
  changeGetInventoryAdjustmentReportLoadingStatusAct,
  changeGetInventoryDetectionAmazonReportLoadingStatusAct,
  changeGetUploadOrdersExcelReportLoadingStatusAct,
  changeGetZohoInReportLoadingStatusAct,
} from "./reportsactions";

function* getSuppliersReportGen(action) {
  yield put(changeSuppliersReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_SUPPLIERS_REPORT_SUCCESS,
    failAction: reportsconstants.GET_SUPPLIERS_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeSuppliersReportLoadingStatusAct(false));
}

function* getPoAnalysisReportGen(action) {
  yield put(changePoAnalysisReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_PO_ANALYSIS_REPORT_SUCCESS,
    failAction: reportsconstants.GET_PO_ANALYSIS_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changePoAnalysisReportLoadingStatusAct(false));
}

function* getInvPricingReportGen(action) {
  yield put(changeInvPricingReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INV_PRICING_REPORT_SUCCESS,
    failAction: reportsconstants.GET_INV_PRICING_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeInvPricingReportLoadingStatusAct(false));
}

function* getInvPricingReportPrintGen(action) {
  yield put(changeInvPricingReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INV_PRICING_REPORT_PRINT_SUCCESS,
    failAction: reportsconstants.GET_INV_PRICING_REPORT_PRINT_FAIL,
  });
  yield put(changeInvPricingReportLoadingStatusAct(false));
}

function* getInvPricingStatsGen(action) {
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INV_PRICING_STATS_SUCCESS,
    failAction: reportsconstants.GET_INV_PRICING_STATS_FAIL,
  });
}

function* getCustomersReportGen(action) {
  yield put(changeCustomersReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_CUSTOMERS_REPORT_SUCCESS,
    failAction: reportsconstants.GET_CUSTOMERS_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeCustomersReportLoadingStatusAct(false));
}

function* getCustomersStatsGen(action) {
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_CUSTOMERS_STATS_SUCCESS,
    failAction: reportsconstants.GET_CUSTOMERS_STATS_FAIL,
  });
}

function* getInvPricingUpdateColsGen(action) {
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INV_PRICING_UPDATE_COLS_SUCCESS,
    failAction: reportsconstants.GET_INV_PRICING_UPDATE_COLS_FAIL,
  });
}

function* updateInvPricingGen(action) {
  const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.UPDATE_INV_PRICING_SUCCESS,
    failAction: reportsconstants.UPDATE_INV_PRICING_FAIL,
  });
}

function* getInvPriceCodeGen(action) {
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INV_PRICE_CODE_SUCCESS,
    failAction: reportsconstants.GET_INV_PRICE_CODE_FAIL,
  });
}

function* updateInvPriceCodeGen(action) {
  const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.UPDATE_INV_PRICE_CODE_SUCCESS,
    failAction: reportsconstants.UPDATE_INV_PRICE_CODE_FAIL,
  });
}

function* getCustomerRouteReportGen(action) {
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_CUSTOMER_ROUTE_REPORT_SUCCESS,
    failAction: reportsconstants.GET_CUSTOMER_ROUTE_REPORT_FAIL,
  });
}

function* getPaymentsReportsGen(action) {
  yield put(changeGetPaymentsReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_PAYMENTS_REPORT_SUCCESS,
    failAction: reportsconstants.GET_PAYMENTS_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetPaymentsReportLoadingStatusAct(false));
}

function* getSalesHistoryReportGen(action) {
  yield put(changeGetSalesHistoryReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_SALES_HISTORY_REPORT_SUCCESS,
    failAction: reportsconstants.GET_SALES_HISTORY_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetSalesHistoryReportLoadingStatusAct(false));
}

function* getSalesHighestGen(action) {
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_SALES_HIGHEST_SUCCESS,
    failAction: reportsconstants.GET_SALES_HIGHEST_FAIL,
  });
}

function* getCustomerBalanceStatementGen(action) {
  yield put(changeCustomerBalanceStatementLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_CUSTOMER_BALANCE_STATEMENT_SUCCESS,
    failAction: reportsconstants.GET_CUSTOMER_BALANCE_STATEMENT_FAIL,
  });
  yield delay(500);
  yield put(changeCustomerBalanceStatementLoadingStatusAct(false));
}

function* getPurchasesReportGen(action) {
  yield put(changePurchasesReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_PURCHASES_REPORT_SUCCESS,
    failAction: reportsconstants.GET_PURCHASES_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changePurchasesReportLoadingStatusAct(false));
}

function* getSalesReportItemsGen(action) {
  yield put(changeSalesReportItemsLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_SALES_REPORT_ITEMS_SUCCESS,
    failAction: reportsconstants.GET_SALES_REPORT_ITEMS_FAIL,
  });
  yield delay(500);
  yield put(changeSalesReportItemsLoadingStatusAct(false));
}

function* getInvPurchasesReportGen(action) {
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INV_PURCHASES_REPORT_SUCCESS,
    failAction: reportsconstants.GET_INV_PURCHASES_REPORT_FAIL,
  });
}

function* getInvSalesReportGen(action) {
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INV_SALES_REPORT_SUCCESS,
    failAction: reportsconstants.GET_INV_SALES_REPORT_FAIL,
  });
}

function* getReturnSalesReportGen(action) {
  yield put(changeGetReturnSalesReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_RETURN_SALES_REPORT_SUCCESS,
    failAction: reportsconstants.GET_RETURN_SALES_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetReturnSalesReportLoadingStatusAct(false));
}

function* getDriversReportGen(action) {
  yield put(changeGetDriversReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_DRIVERS_REPORT_SUCCESS,
    failAction: reportsconstants.GET_DRIVERS_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetDriversReportLoadingStatusAct(false));
}
function* getMsaFileReportGen(action) {
  yield put(changeGetMsaFileReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_MSA_FILE_REPORT_SUCCESS,
    failAction: reportsconstants.GET_MSA_FILE_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetMsaFileReportLoadingStatusAct(false));
}

function* getInventoryDetectionReportGen(action) {
  yield put(changeGetInventoryDetectionReportLoadingStatusAct(true));
  const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INVENTORY_DETECTION_REPORT_SUCCESS,
    failAction: reportsconstants.GET_INVENTORY_DETECTION_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetInventoryDetectionReportLoadingStatusAct(false));
}

function* getInventoryDetectionAmazonReportGen(action) {
  yield put(changeGetInventoryDetectionAmazonReportLoadingStatusAct(true));
  const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INVENTORY_DETECTION_AMAZON_REPORT_SUCCESS,
    failAction: reportsconstants.GET_INVENTORY_DETECTION_AMAZON_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetInventoryDetectionAmazonReportLoadingStatusAct(false));
}

function* getInventoryAdjustmentReportGen(action) {
  yield put(changeGetInventoryAdjustmentReportLoadingStatusAct(true));
  const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.GET_INVENTORY_ADJUSTMENT_REPORT_SUCCESS,
    failAction: reportsconstants.GET_INVENTORY_ADJUSTMENT_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetInventoryAdjustmentReportLoadingStatusAct(false));
}

function* getUploadOrdersExcelReportGen(action) {
  yield put(changeGetUploadOrdersExcelReportLoadingStatusAct(true));
  const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: reportsconstants.UPLOAD_ORDERS_EXCEL_REPORT_SUCCESS,
    failAction: reportsconstants.UPLOAD_ORDERS_EXCEL_REPORT_FAIL,
  });
  yield delay(500);
  yield put(changeGetUploadOrdersExcelReportLoadingStatusAct(false));
}

export function* reportsSaga() {
  yield takeLatest(reportsconstants.GET_SUPPLIERS_REPORT_REQUEST, getSuppliersReportGen);
  yield takeLatest(reportsconstants.GET_PO_ANALYSIS_REPORT_REQUEST, getPoAnalysisReportGen);
  yield takeLatest(reportsconstants.GET_INV_PRICING_REPORT_REQUEST, getInvPricingReportGen);
  yield takeLatest(reportsconstants.GET_INV_PRICING_REPORT_PRINT_REQUEST, getInvPricingReportPrintGen);
  yield takeLatest(reportsconstants.GET_INV_PRICING_STATS_REQUEST, getInvPricingStatsGen);
  yield takeLatest(reportsconstants.GET_CUSTOMERS_REPORT_REQUEST, getCustomersReportGen);
  yield takeLatest(reportsconstants.GET_CUSTOMERS_STATS_REQUEST, getCustomersStatsGen);
  yield takeLatest(reportsconstants.GET_INV_PRICING_UPDATE_COLS_REQUEST, getInvPricingUpdateColsGen);
  yield takeLatest(reportsconstants.UPDATE_INV_PRICING_REQUEST, updateInvPricingGen);
  yield takeLatest(reportsconstants.GET_INV_PRICE_CODE_REQUEST, getInvPriceCodeGen);
  yield takeLatest(reportsconstants.UPDATE_INV_PRICE_CODE_REQUEST, updateInvPriceCodeGen);
  yield takeLatest(reportsconstants.GET_CUSTOMER_ROUTE_REPORT_REQUEST, getCustomerRouteReportGen);
  yield takeLatest(reportsconstants.GET_PAYMENTS_REPORT_REQUEST, getPaymentsReportsGen);
  yield takeLatest(reportsconstants.GET_SALES_HISTORY_REPORT_REQUEST, getSalesHistoryReportGen);
  yield takeLatest(reportsconstants.GET_SALES_HIGHEST_REQUEST, getSalesHighestGen);
  yield takeLatest(reportsconstants.GET_CUSTOMER_BALANCE_STATEMENT_REQUEST, getCustomerBalanceStatementGen);
  yield takeLatest(reportsconstants.GET_PURCHASES_REPORT_REQUEST, getPurchasesReportGen);
  yield takeLatest(reportsconstants.GET_SALES_REPORT_ITEMS_REQUEST, getSalesReportItemsGen);
  yield takeLatest(reportsconstants.GET_INV_PURCHASES_REPORT_REQUEST, getInvPurchasesReportGen);
  yield takeLatest(reportsconstants.GET_INV_SALES_REPORT_REQUEST, getInvSalesReportGen);
  yield takeLatest(reportsconstants.GET_RETURN_SALES_REPORT_REQUEST, getReturnSalesReportGen);
  yield takeLatest(reportsconstants.GET_DRIVERS_REPORT_REQUEST, getDriversReportGen);
  yield takeLatest(reportsconstants.GET_MSA_FILE_REPORT_REQUEST, getMsaFileReportGen);
  yield takeLatest(reportsconstants.GET_INVENTORY_DETECTION_REPORT_REQUEST, getInventoryDetectionReportGen);
  yield takeLatest(reportsconstants.GET_INVENTORY_DETECTION_AMAZON_REPORT_REQUEST, getInventoryDetectionAmazonReportGen);
  yield takeLatest(reportsconstants.GET_INVENTORY_ADJUSTMENT_REPORT_REQUEST, getInventoryAdjustmentReportGen);
  yield takeLatest(reportsconstants.UPLOAD_ORDERS_EXCEL_REPORT_REQUEST, getUploadOrdersExcelReportGen);
}
