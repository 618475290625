import zohoconstants from "./zohoconstants";

const initialState = {
  getZohoInReportResp: undefined,
  getZohoInReportLoading: undefined,
  getZohoInvResp: undefined,
  getZohoInvLoading: undefined,
  zohoErrorResp: undefined
};

export default function zoho(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {

    case zohoconstants.GET_ZOHO_IN_REPORT_SUCCESS:
      return { ...state, getZohoInReportResp: payload };

    case zohoconstants.CHANGE_GET_ZOHO_IN_REPORT_LOADING_STATUS:
      return { ...state, getZohoInReportLoading: payload };

    case zohoconstants.GET_ZOHO_INVOICES_SUCCESS:
      return { ...state, getZohoInvResp: payload };

    case zohoconstants.CHANGE_GET_ZOHO_INVOICES_LOADING_STATUS:
      return { ...state, getZohoInvLoading: payload };

    case zohoconstants.GET_ZOHO_IN_REPORT_FAIL:
    case zohoconstants.GET_ZOHO_INVOICES_FAIL:
      
      return { ...state, zohoErrorResp: payload };
      
    default:
      return state;
  }
}
