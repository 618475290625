import React, { useMemo, useState, useRef } from "react";
import { Link } from 'react-router-dom';

import axios from 'axios';

import {
    Card, CardBody, Col, Container, Input, Label, Row, Button, Table, Modal, ModalHeader, ModalBody,
    ModalFooter, Form, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { tabsOption } from "./helper";
import classnames from "classnames";
import { InvoiceEditTab, MsaReportTab,InventoryDetectionTab } from "./tabs";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { generateApiUrl } from "../../api/apihelper";
import { useDispatch } from "react-redux";
import { getZohoInReportAct } from "../../store/zoho/zohoactions";
import { getLocalStorage, removeLocalStorage } from "../../utility/storageutility";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function getActiveComponent(activeId) {
    switch (activeId) {
        case "invoice_edit_report":
            return InvoiceEditTab;
        case "msa_report":
            return MsaReportTab;
        case "inventory_detecion":
            return InventoryDetectionTab;
        default:
            return <></>;
    }
}


export default function AdminTools() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const isZohoBtnClicked = getLocalStorage('is-zoho-btn-clicked');
    const [justifyPillsTab, setjustifyPillsTab] = useState(isZohoBtnClicked?"inventory_detecion":"invoice_edit_report");
    const justifyPillsToggle = (tab) => {
        if (justifyPillsTab !== tab) {
            setjustifyPillsTab(tab);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code'); 
        let parameters = {}
        if(code && isZohoBtnClicked){
            parameters.code = code;
            dispatch(
                getZohoInReportAct(
                    generateApiUrl(`get_zoho_in_code_report`,parameters)
                )
            )
            removeLocalStorage('is-zoho-btn-clicked');
            // setTimeout(() => fetchZohoResp(parameters),500)
        }
      }, []);
    //   useEffect(() => {
    //     if (history.location?.state?.showInventoryDetectionTab) {
    //         justifyPillsToggle('inventory_detecion')
    //         history.replace();
    //     } else{
    //         justifyPillsToggle('invoice_edit_report')
    //     }
    //   }, []);

    const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab), [justifyPillsTab]);

    return (
        <React.Fragment>
            <div className="sale-wh-wrapper">
                <Container fluid>
                    <Row>
                        <Col className="px-0" xs={12}>
                            <Nav pills className="nav mb-1 bg-light px-5">
                                {tabsOption.map(item => <NavItem key={item.id}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({ active: justifyPillsTab === item.id })}
                                        onClick={() => {
                                            justifyPillsToggle(item.id);
                                        }}
                                    >
                                        {item.name}
                                    </NavLink>
                                </NavItem>)}
                            </Nav>
                            <TabContent activeTab={justifyPillsTab} className="text-muted">
                                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                                    <TabComponent />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}