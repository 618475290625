import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, emptyLoginDetails, loginSuccess, logoutUserSuccess } from "./actions";
import { getData, postData } from "../../../api/apiactions";
import { handleApiResponse } from "../../../utility/sagautility";
import loginConstants from "./actionTypes";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../utility/storageutility";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      setLocalStorage("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      setLocalStorage("authUser", JSON.stringify(response));
      if (response.status === "success") {
        yield put(loginSuccess(response));
        history.push("/dashboard");
      } else {
        yield put(apiError(response));
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    removeLocalStorage("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* logoutUserUpdated(action) {
  try {
    const token = getLocalStorage("token");
    removeLocalStorage("user");
    removeLocalStorage("token");
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
    yield call(postData, action.apiEndpoint, {}, {headers});
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
      yield put(emptyLoginDetails());
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
      yield put(emptyLoginDetails());
    }
  } catch (error) {
    yield put(emptyLoginDetails());
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      setLocalStorage("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      setLocalStorage("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginUserGen(action) {
  const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: loginConstants.LOGIN_USER_SUCCESS,
    failAction: loginConstants.LOGIN_USER_FAIL
  })
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUserUpdated);
  yield takeLatest(loginConstants.LOGIN_USER_REQUEST, loginUserGen);
}

export default authSaga;
