import usermanagementconstants from "./usermanagementconstants";

export const changeUserManagementApiLoadingStatusAct = (status) => {
    return {
      type: usermanagementconstants.CHANGE_USER_MANAGEMENT_API_LOADING_STATUS,
      payload: status,
    };
};
  

export const createUserRoleAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: usermanagementconstants.CREATE_USER_ROLE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateUserRoleAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: usermanagementconstants.UPDATE_USER_ROLE_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const getUserRolesAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: usermanagementconstants.GET_USER_ROLES_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const getAllPermissionsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: usermanagementconstants.GET_ALL_PERMISSIONS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getPermissionsByRoleAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: usermanagementconstants.GET_PERMISSIONS_BY_ROLE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const createRolePermissionAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: usermanagementconstants.CREATE_ROLE_PERMISSION_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getAllStationAct = (apiEndpoint,params,optionalConfig) => {
  return {
      type:usermanagementconstants.GET_ALL_STATIONS_REQUEST,
      apiEndpoint,
      params,
      optionalConfig
  }
};

export const deleteRolesAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: usermanagementconstants.DELETE_ROLES_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};