import { Input } from "reactstrap";
//Utility
import { getMmddyyyyDateFormatted, getCustomDateFormatted } from "../../../utility/dateutility";
//Constants
import { currencySymbol } from "../../../constants";

export const customerColumnsList = [
  {
    id: "customerNumber",
    name: "Cust. Num",
    cellClassName: "fw-medium",
  },
  {
    id: "customerName",
    name: "Customer Name",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "companyName",
    name: "Company",
  },
];

function handlePayOrderChange(setInvoicesForPayOrder, invoiceNumber) {
    setInvoicesForPayOrder(prev => {
      const updatedData = [...prev];
      let index = updatedData.indexOf(invoiceNumber);
      if(index !== -1) {
        updatedData.splice(index,1);
      } else {
        updatedData.push(invoiceNumber)
      }
      return updatedData;
    })
  
  }

export function generateColumns(invoicesForPayOrder, setInvoicesForPayOrder) {
  return [
    {
      Header: "Invoice#",
      accessor: "invoiceNumber",
      Cell: ({ cell: { value } }) => (
        <div
          className="text-primary"
          style={{ cursor: "pointer" }}
        //   onClick={() => {
        //     history.push(`/warehousesales/${value}`, { redirected: true });
        //     setLocalStorage("activeTab", JSON.stringify({ warehousecustomer: "unpaid_invoices" }));
        //   }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: "Invoice Date",
      accessor: "invoiceDate",
      Cell: ({ cell: { value } }) => (value ? getMmddyyyyDateFormatted(value) : "-"),
    },
    {
      Header: "Days",
      accessor: "days",
    },
    {
      Header: "Invoice Total",
      accessor: "totalInvAmount",
      Cell: ({ cell: { value } }) =>
        typeof value == "number" ? (
          <>
            {currencySymbol}
            {value.toFixed(2)}
          </>
        ) : (
          ""
        ),
      style: { textAlign: "right" },
    },
    {
      Header: "Total Paid",
      accessor: "totalPaid",
      Cell: ({ cell: { value } }) =>
        typeof value == "number" ? (
          <>
            {currencySymbol}
            {value.toFixed(2)}
          </>
        ) : (
          ""
        ),
      style: { textAlign: "right" },
    },
    {
      Header: "Invoice Balance",
      accessor: "amountDue",
      Cell: ({ cell: { value } }) =>
        typeof value == "number" ? (
          <>
            {currencySymbol}
            {value.toFixed(2)}
          </>
        ) : (
          ""
        ),
      style: { textAlign: "right" },
    },
    {
      Header: "Pay Order",
      accessor: "payOrder",
      Cell: ({ row: { original : {invoiceNumber} } }) => (
        <div className="d-flex justify-content-between">
          <div className="form-check form-radio-outline form-radio-primary me-2">
            <Input className="form-check-input" type="radio" checked={invoicesForPayOrder.indexOf(invoiceNumber) !== -1} onClick={() => handlePayOrderChange( setInvoicesForPayOrder, invoiceNumber)} onChange={() => {}} />
          </div>
          <div className="form-check form-radio-outline form-radio-primary me-2">
            <Input className="form-check-input" type="radio" checked={invoicesForPayOrder.length === 0} disabled />
          </div>
        </div>
      ),
    },
  ];
}
