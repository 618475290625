import GeneralSettingsIcon from "../../assets/images/warehouse/header/general-settings.png";
import CustomersIcon from "../../assets/images/warehouse/header/customers.png";
import SystemLevelIcon from "../../assets/images/warehouse/header/system-level.png";
import ProfitLossIcon from "../../assets/images/warehouse/header/profit-loss-statement.png";
import DataAnalysisIcon from "../../assets/images/warehouse/header/data-analysis-report.png";
import AdminToolsIcon from "../../assets/images/warehouse/header/admin-tools.png";
import ExitIcon from "../../assets/images/warehouse/header/exit.png";
import MainmenuIcon from "../../assets/images/warehouse/header/main_menu.png";

export const menuList = [
    {
        id: "general_settings",
        mainLabel: "General Settings",
        subLabel: "General Settings",
        link: "/admin/generalsettings",
        icon: GeneralSettingsIcon
    },
    {
        id: "user_management",
        mainLabel: "User Management",
        subLabel: "User Management",
        link: "/admin/usermanagement",
        icon: CustomersIcon
    },
    // {
    //     id: "system_level",
    //     mainLabel: "System Level",
    //     subLabel: "System Level",
    //     link: "/admin/systemlevel",
    //     icon: SystemLevelIcon
    // },
    // {
    //     id: "profilt_loss_statement",
    //     mainLabel: "Profit/ Loss Statement",
    //     subLabel: "Profit/ Loss Statement",
    //     link: "/admin/profitloss",
    //     icon: ProfitLossIcon
    // },
    // {
    //     id: "data_analysis_report",
    //     mainLabel: "Data Analysis Report",
    //     subLabel: "Data Analysis Report",
    //     link: "/admin/analysisreport",
    //     icon: DataAnalysisIcon
    // }, 
    {
        id: "admin_tools",
        mainLabel: "Admin Tools",
        subLabel: "Admin Tools",
        link: "/admin/admintools",
        icon: AdminToolsIcon
    },{
        id: "main_menu",
        mainLabel: "Main Menu",
        subLabel: "Main Menu",
        link: "/warehousemenu",
        icon: MainmenuIcon
    }, {
        id: "exit",
        mainLabel: "Exit",
        subLabel: "Exit",
        link: "/warehouselogin",
        icon: ExitIcon
    }
]