import { Link } from "react-router-dom";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
//Utility
import { capitalize, formatAmount } from "../../utility/commonutility";
import { getMmddyyyyDateFormatted } from "../../utility/dateutility";

export const actions = (
  history,
  toggleDeleteModal,
  resetFilters,
  toggleSearchModal
) => [
  {
    id: "new",
    label: "New",
    icon: NewIcon,
    clickHandler: () => history.push("/warehousepurchaseorders/new"),
  },
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters,
  },
  {
    id: "delete",
    label: "Delete",
    icon: DeleteIcon,
    clickHandler: toggleDeleteModal,
  },
  // {
  //   id: "smart_search",
  //   label: "Smart-Search",
  //   icon: <i className="ri-search-2-fill text-primary" style={{ fontSize: "20px" }}></i>,
  //   clickHandler: toggleSearchModal,
  // },
];

export function generateColumns() {
  return [
    {
      Header: "Purchase Order Num",
      accessor: "purchaseOrderNumber",
      Cell: ({ cell: { value } }) => (
        <Link to={`/warehousepurchaseorders/${value}`}>{value}</Link>
      ),
    },
    {
      Header: "Supplier Name",
      accessor: "supplier.supplierName",
      Cell: ({
        row: {
          original: { supplierNumber, supplier },
        },
      }) => (
        <Link to={`/warehousevendors/${supplierNumber}`}>
          {capitalize(supplier?.supplierName)}
        </Link>
      ),
    },
    {
      Header: "Invoice Num",
      accessor: "invNumber",
      Cell: ({ cell: { value } }) => value || "-",
    },
    {
      Header: "PO Date",
      accessor: "poDate",
      Cell: ({ cell: { value } }) =>
        value ? getMmddyyyyDateFormatted(value) : "-",
    },
    {
      Header: "Received Date",
      accessor: "receivedDate",
      Cell: ({ cell: { value } }) =>
        value ? getMmddyyyyDateFormatted(value) : "-",
    },
    {
      Header: "Total PO Amount",
      accessor: "totalPoAmount",
      Cell: ({ cell: { value } }) => (value ? `${formatAmount(value)}` : "-"),
    },
    // {
    //   Header: "Payment Term",
    //   accessor: "paymentTerm",
    //   Cell: ({ cell: { value } }) => value || "-",
    // },
    {
      Header: "Amount Paid",
      accessor: "amountPaid",
      Cell: ({ cell: { value } }) => (value ? `${formatAmount(value)}` : "-"),
    },
    {
      Header: "Balance",
      accessor: "balance",
      Cell: ({
        row: {
          original: { totalPoAmount, amountPaid },
        },
      }) => `${formatAmount((amountPaid || 0) - totalPoAmount)}`,
    },
    {
      Header: "Payment Status",
      accessor: "paymentStatus",
      Cell: ({ 
        cell: { value }, 
        row:{
        original: { totalPoAmount, amountPaid },
          } 
        }) => {
          return (
            <span className={`badge badge-soft-${value ? "success" : !amountPaid?'danger':'warning'}`}>
              {value ? "Paid" : !amountPaid?'Unpaid':'Charge'}
            </span>
          )
        },
    },
  ];
}

export function getSearchLabel(searchBy) {
  switch (searchBy) {
    case "supplierName":
      return "Supplier Name";
    case "purchaseOrderNumber":
      return "Purchase Order Number";
    case "poDate":
      return "PO Date";
    case "paymentTerm":
      return "Payment Term";
    case "shipDate":
      return "Ship Date";
    case "receivedStatus":
      return "Received Status";
    case "paidStatus":
      return "Paid Status";
    case "supplierNumber":
      return "Supplier Number";
    default:
      return "";
  }
}
