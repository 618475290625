export const inventoryUrl = {
    get_inventory_list : `/inventory/all`,
    get_inventory_all_master_data : `/inventory/master-data/all`,
    create_inventory_item : `/inventory`,
    get_inventory_item : ({itemNumber}) => `inventory/${itemNumber}`,
    update_inventory_item : ({itemNumber}) => `inventory/${itemNumber}`,
    delete_inventory_item : `/inventory/delete`,
    get_purchase_orders :  ({itemNumber}) => `inventory/${itemNumber}/po`,
    get_suppliers_of_given_item : ({itemNumber}) => `inventory/${itemNumber}/suppliers`,
    get_sales_of_given_item : ({itemNumber}) => `inventory/${itemNumber}/sales`,
    get_max_inventory_num : `/inventory/maxNumber`,
    create_customer_inventory_price : ({itemNumber}) => `/inventory/${itemNumber}/customerPrice`,
    get_adjustment_history_of_item : ({itemNumber}) => `inventory/${itemNumber}/qty-adjustment`,
    create_qty_adjustment : ({itemNumber}) => `inventory/${itemNumber}/qty-adjustment`,
    create_multi_qty_adjustment : `inventory/qty-adjustments`,
    get_special_prices : `/inventory/specialPrices`,
    activate_inventory_item : ({itemNumber}) => `inventory/${itemNumber}/status`,
    
    delete_inventory_column_values : `/inventory/columnValues`,

    // Bundles
    create_bundle : `/inventory/bundle`,
    get_inventory_bundles_list : `inventory/bundles`,
    delete_bundle : `/inventory/bundles`,
    get_bundle_item : ({id}) => `inventory/bundle/${id}`,
    update_bundle_item : ({id}) => `inventory/bundle/${id}`,
    get_max_bundle_no : `/inventory/bundle/maxNumber`,
}