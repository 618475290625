export const tabs = [
  {
    id: "administration",
    name: "Administration",
  },
  {
    id: "sales",
    name: "Sales",
  },
  {
    id: "tools",
    name: "Tools",
  },
];

export function generateColumns() {
  return [
    {
      Header: "Employee Name",
      accessor: "employeeName",
    },
    {
      Header: "Designation",
      accessor: "designation",
    },
  ];
}

export function findActiveRoleName(getUserRolesResp, activeRole) {
  return (getUserRolesResp?.data?.userRoles || []).find(item => item.id == activeRole)?.name;
}

export const perimissionsInitialValues = {
  //Sales Permissions Initial Values
  ADD_INV_INVOICE: false,
  SET_CUST_PRICE: false,
  CHNG_INV_PRICE_AND_DISC: false,
  CHNG_CUST_CODE: false,
  VIEW_ADV_INV: false,
  VIEW_ADV_CUST: false,
  VIEW_OWN_CUST_ONLY: false,
  ALLOW_RETURNS: false,
  ACCEPT_PAYMENTS: false,
  RETURN_PAYMENTS: false,
  SELL_BELOW_COST: false,
  MAKE_CREDITS: false,
  INV_MAX_DISC: "",
  OPEN_POSTED_INVOICE:false,
  OPEN_RECEIVED_PO: false,
  DELETE_OPEN_INV: false,
  DELETE_OPEN_PO:false,
  TRACK_DELETED_INVS: false,
  ADJ_INV_COUNT: false,
  UPDATE_INV_PRICES: false,
  ALL_MENU: false,
  SALES_MENU: false,
  ORDER_MENU: false,
  INVOICE_MENU: false,
  POS_MENU: false,
  CUSTOMER_MENU: false,
  PO_MENU: false,
  VENDORS_MENU: false,
  INVENTORY_MENU: false,

  //Administrations Permissions Initial Values
  SALES_COMM: "",
  PROFIT_COMM: "",
  VIEW_MENU_FUNCS: false,
  VIEW_ALL_MENU_ITEMS: false,
  WORK_OFFLINE: false,
  LOG_TIME_IN_OUT: false,
  IDLE_TIME_IN_MINS: "",

  //Tools Permissions Initial Values
  PRINT_REPORT: false,
  EXPORT_REPORT: false,
  SHOW_REPORT_DATE: false,
  REPORT_DAYS: "",
  EXTD_REPT: false,
  PRINT_FILT_IN_REPT: false,
  COMP_PURC_SALES: false,
  VIEW_AMT_PURC_SALES: false,
};

export function generatePayload(permissions, getAllPermissionsResp) {
  let payload = [];
  for (let i in permissions) {
    const foundPermission = getAllPermissionsResp.data.permissions.find((item) => item.code === i);
    if (foundPermission) {
      const { id } = foundPermission;
      if (permissions[i] && typeof permissions[i] !== "boolean") {
        payload.push({ id, value: permissions[i] });
      } else if (permissions[i] && typeof permissions[i] === "boolean") {
        payload.push({ id });
      }
    } else {
      console.error(`Permission with code '${i}' not found.`);
    }
  }
  return payload;
}


export const mapPermissions = (setPermissions, getPermissionsByRoleResp, getAllPermissionsResp) => {
  const {data : {rolePermissions : {rows}}} = getPermissionsByRoleResp;
  const permissions = {};
  rows.forEach(item => {
    const {code} = getAllPermissionsResp.data.permissions.find(it => it.id === item.permissionId);
    if(code && item.value) permissions[code] = item.value;
    else if(code && !item.value) permissions[code] = true;
  });
  setPermissions({...permissions});
}
