import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../assets/images/warehouse/salespage/excel.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import bundleIcon from "../../assets/images/warehouse/salespage/bundle.png";
import bundlesIcon from "../../assets/images/warehouse/salespage/bundles-icon.png";
import { Link } from "react-router-dom";

export const inventoryItemColumnsList = [
    {
      id: "itemNumber",
      name: "Item Num",
      cellClassName: "fw-medium",
    },
    {
      id: "itemName",
      name: "Item Name",
      cellClassName: "fbadge badge-soft-primary",
    },
    {
      id: "category",
      name: "Category",
      cellClassName: "fw-medium",
    },
  ];

export const actions = (history,toggleDeleteModal,resetFilters,toggleSearchModal) => [
    // {
    //     id: "reset",
    //     label: "Reset",
    //     icon: ResetIcon,
    //     clickHandler:resetFilters
    // }, 
    // {
    //     id: "print",
    //     label: "Print",
    //     icon: PrintIcon,
    //     clickHandler:() =>{}
    // }, {
    //     id: "export",
    //     label: "Export",
    //     icon: ExportIcon,
    //     clickHandler:() =>{}
    // }, 
    {
        id: "new",
        label: "New",
        icon: NewIcon,
        clickHandler:() => history.push("/warehouseinventory/new")
    }, 
    {
        id: "delete",
        label: "Delete",
        icon: DeleteIcon,
        clickHandler:toggleDeleteModal,
    },
    // {
    //     id: "smart_search",
    //     label: "Smart-Search",
    //     icon: <i className="ri-search-2-fill text-primary" style={{fontSize:"20px"}}></i>,
    //     clickHandler:toggleSearchModal,
    // }
    {
        id: "create_bundle",
        label: "Create Bundle",
        icon: bundleIcon,
        clickHandler:() => history.push("/warehouseinventory/bundles-createbundle")

    },
    {
        id: "bundles",
        label: "Bundles",
        icon: bundlesIcon,
        clickHandler:() => history.push("/warehouseinventory/bundles")

    }
]

export function generateColumns(page) {
    return [
        {
            Header: "Item Num",
            accessor: "itemNumber",
            Cell:({cell:{value}}) => <Link to={`/warehouseinventory/${value}`}>{value}</Link>
        },
        {
            Header: "Item Name",
            accessor: "description",
            Cell:({row:{original:{itemNumber, description}}}) => <Link to={`/warehouseinventory/${itemNumber}`}>{description}</Link>
        }, 
        {
            Header: "Category",
            accessor: "category",
            Cell:({cell:{value}}) => value || "-"
        }, 
        {
            Header: "Manufacturer",
            accessor: "manufacturer",
            Cell:({cell:{value}}) => value || "-"
        },
        {
            Header: "UPC Code",
            accessor: "upcCode",
            Cell:({cell:{value}}) => value || "-"
        },
        {
            Header: "Qty in Stock",
            accessor: "quantityInStock",
            Cell:({cell:{value}}) => value || "-"
        },
    ]
}


export function getSearchLabel(searchBy) {
    switch(searchBy) {
      case "description": return "Item Name";
      case "itemNumber": return "Item Number";
      case "category": return "Category";
      case "family": return "Family";
      case "manufacturer": return "Manufacturer";
      case "model": return "Model";
      case "upcCode": return "UPC Code";
      case "createdDate": return "Created Date";
      case "lastUsedDate": return "Last Used Date";
      default :  return "";
    }
}
