import React, { useEffect, useRef, useState, useContext } from 'react';
import { Modal, ModalBody, ModalHeader} from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import BlankLayout from "../Layouts/BlankLayout";
import WarehouseLayout from "../Layouts/WarehouseLayout";
import WarehouseAdminLayout from "../Layouts/WarehouseAdminLayout";
//routes
import { warehousePublicRoutes, authWarehouseBlankRoutes, authWareHouseRoutes, adminWareHouseRoutes } from "./allRoutes";
import { AccessRoute, AuthProtected } from './AuthProtected';
import { saveLoginDetails, logoutUser } from '../store/actions';
//Context
import { PermissionsContext } from "../context/PermissionsContext";
import { generateApiUrl } from "../api/apihelper";
import { ModalFooter } from 'reactstrap';
import { getLocalStorage, setLocalStorage } from '../utility/storageutility';

const inactiveTimeLimit = 10; //minutes
const warningTimerLimit = 9; //minutes

const Index = () => {
    const wareHousePublicRoutesPaths = warehousePublicRoutes.map((r) => r.path);
    const authWarehouseBlankRoutesPath = authWarehouseBlankRoutes.map((r) => r.path);
    const authWarehouseRoutePath = authWareHouseRoutes.map((r) => r.path);
    const adminWarehouseRoutePath = adminWareHouseRoutes.map((r) => r.path);
    const [inactiveTimer, setInactiveTimer] = useState(0);
    const [showTimeoutWarningModal, setShowTimeoutWarningModal] = useState(false);
    const interval = useRef(null);
    const dispatch = useDispatch();
    const { handleLogout } = useContext(PermissionsContext);
    const handleLogoutUser = () => {
        dispatch(logoutUser(generateApiUrl('logout_user')));
        handleLogout();
    }
    const history = useHistory();
    const {Login : { loginDetails }} = useSelector(state => state, shallowEqual);
    useEffect(() => {
      const user = getLocalStorage("user");
      if(!user && !loginDetails?.user) {
        history.push("/");
      }
    }, [loginDetails]);
    useEffect(() => {
      const token = getLocalStorage("token");
      const tokenExpiresAt = getLocalStorage("tokenExpiresAt");
      const refreshToken = getLocalStorage("refreshToken");
      const user = getLocalStorage("user");
      if(user) {
        dispatch(saveLoginDetails({
          token,
          tokenExpiresAt,
          refreshToken,
          user:JSON.parse(user)
        }));
      }
    }, []);
    useEffect(() => {
      let throttleTimeout;
      const throttleDelay = 400;
      let isThrottled = false;

      function handleUserActivity() {
        // setInactiveTimer(inactiveTimeLimit);
        if(!isThrottled) {
          isThrottled=true;
          setLocalStorage('lastActiveTime', Date.now().toString());
          throttleTimeout = setTimeout(() => {
            isThrottled = false;
          }, throttleDelay);
        }
      }
      function handleEnterWhenWarning(e) {
        if(e.key === "Enter") {
          // setInactiveTimer(inactiveTimeLimit);
          setLocalStorage('lastActiveTime', Date.now().toString());
          setShowTimeoutWarningModal(false);
        }
      }
      function checkIdleTime() {
        var currentTime = Date.now();
        var lastActiveTime = parseInt(getLocalStorage('lastActiveTime')) || currentTime;
    
        // Calculate the idle time
        var idleTime = currentTime - lastActiveTime;
    
        // Set your threshold for idle time (e.g., 5 minutes)
        var idleThreshold = inactiveTimeLimit * 60 * 1000; //in milliseconds
        const warningThreshold = warningTimerLimit * 60 * 1000; //in milliseconds
        setInactiveTimer(prev => (prev>0 ? Math.floor((idleThreshold - idleTime) / 1000) : prev));
    
        if (idleTime >= idleThreshold || getLocalStorage("sessionTimedOut")) {
            // User is considered idle, perform your idle actions
            const { pathname } = history.location;
            
            if(((pathname.includes("/warehousesales/")) || (pathname.includes("/warehousepurchaseorders/"))) ? (pathname.includes("/warehousesales/") ? (!!getLocalStorage("createInvoiceApiCalled") || !!getLocalStorage("isInvoiceSaved")) : (!!getLocalStorage("createPoApiCalled")) || !!getLocalStorage("isPoSaved")) : true) {
              if(getLocalStorage("isInvoiceOpen") ? getLocalStorage("isInvoiceSaved") : true ) {
                if(getLocalStorage("isPoOpen") ? getLocalStorage("isPoSaved") : true ) { 
                  clearInterval(interval.current);
                  interval.current = null;
                  setShowTimeoutWarningModal(false);
                  handleLogoutUser();
                }
              }
            } else if(pathname.includes("/warehousesales/") ? !getLocalStorage("createInvoiceApiCalled") : !getLocalStorage("createPoApiCalled"))
            {
              // clearInterval(interval.current);
              // interval.current = null;
              dispatch(saveLoginDetails({
                ...loginDetails,
                sessionExpired:true
              }));
            }
        } else if(idleTime >= warningThreshold) {
          setShowTimeoutWarningModal(true);
          setInactiveTimer(Math.floor((idleThreshold - idleTime) / 1000));
        } else {
          setShowTimeoutWarningModal(false);
        }
      }
      if(loginDetails?.user) {
        if(!interval.current) {
          setLocalStorage('lastActiveTime', Date.now().toString());
          interval.current = setInterval(checkIdleTime, 1000);
        }
        if(!showTimeoutWarningModal) {
          document.addEventListener("mousemove", handleUserActivity);
          document.addEventListener("keyup", handleUserActivity);
          document.removeEventListener("keyup", handleEnterWhenWarning);
        } else {
          document.removeEventListener("mousemove", handleUserActivity);
          document.removeEventListener("keyup", handleUserActivity);
          document.addEventListener("keyup", handleEnterWhenWarning);
        }
      } else {
        clearInterval(interval.current);
        setShowTimeoutWarningModal(false);
        interval.current = null;
      }
      return () => {
        document.removeEventListener("mousemove", handleUserActivity);
        document.removeEventListener("keyup", handleUserActivity);
        document.removeEventListener("keyup", handleEnterWhenWarning);
      }
    }, [loginDetails, showTimeoutWarningModal]);

    // useEffect(() => {
    //   const {pathname} = history.location;
    //   if(inactiveTimer === warningTimerLimit) {
    //     setShowTimeoutWarningModal(true);
    //   }
    //   else if(inactiveTimer === 0 && !pathname.includes("/warehousesales/") && !pathname.includes("/warehousepurchaseorders/")) {
    //     clearInterval(interval.current);
    //     interval.current = null;
    //     setShowTimeoutWarningModal(false);
    //     handleLogoutUser();
    //   } else if(inactiveTimer === 0) {
    //     clearInterval(interval.current);
    //     interval.current = null;
    //     dispatch(saveLoginDetails({
    //       ...loginDetails,
    //       sessionExpired:true
    //     }));
    //   }
    // }, [inactiveTimer]);
    return (
      <React.Fragment>
        <Switch>
          <Route path={wareHousePublicRoutesPaths}>
              <NonAuthLayout>
                <BlankLayout>
                  <Switch>
                    {warehousePublicRoutes.map((route, idx) => (
                      <Route path={route.path} component={route.component} key={idx} exact={true} />
                    ))}
                  </Switch>
                </BlankLayout>
              </NonAuthLayout>
          </Route>

          <Route path={authWarehouseBlankRoutesPath}>
            <AuthProtected>
            <NonAuthLayout>
              <BlankLayout>
                <Switch>
                  {authWarehouseBlankRoutes.map((route, idx) => (
                    <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
                  ))}
                </Switch>
              </BlankLayout>
            </NonAuthLayout>
            </AuthProtected>
          </Route>

          <Route path={authWarehouseRoutePath}>
            <AuthProtected>
            <NonAuthLayout>
              <WarehouseLayout>
                <Switch>
                  {authWareHouseRoutes.map((route, idx) => (
                    <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
                  ))}
                </Switch>
              </WarehouseLayout>
            </NonAuthLayout>
            </AuthProtected>
          </Route>

          <Route path={adminWarehouseRoutePath} exact>
            <AuthProtected>
            <WarehouseAdminLayout>
              <Switch>
                {adminWareHouseRoutes.map((route, idx) => (
                  <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
                ))}
              </Switch>
            </WarehouseAdminLayout>
            </AuthProtected>
          </Route>
        </Switch>
        <Modal isOpen={showTimeoutWarningModal} centered={true}>
          <ModalHeader className="border-bottom py-2">Session Timeout Warning</ModalHeader>
          <ModalBody>
            Your session is about to expire in {inactiveTimer} seconds.
          </ModalBody>
          <ModalFooter>
              <button
                type="button"
                className={`btn w-sm btn-primary`}
                data-bs-dismiss="modal"
                onClick={() => {
                  setLocalStorage('lastActiveTime', Date.now().toString());
                  setShowTimeoutWarningModal(false);
                }}
              >
                Continue Session
              </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
};

export default Index;