import { Link } from "react-router-dom";
//Utility
import { getMmddyyyyDateFormatted } from "../../../../utility/dateutility";
import { formatAmount } from "../../../../utility/commonutility";

function getCheckNumber(paymentType, chequeNo, transactionId) {
  switch (paymentType) {
    case "CHEQUE":
      return chequeNo;
    case "ONLINE":
      return transactionId;
    default:
      return "-";
  }
}

export function generateColumns() {
  return [
    {
      Header: "Invoice Number",
      accessor: "invNumber",
    },
    {
      Header: "PO Number",
      accessor: "purchaseOrderNumber",
      Cell: ({ cell: { value } }) => <Link to={`/warehousepurchaseorders/${value}`}>{value}</Link>,
    },
    {
      Header: "Date",
      accessor: "poDate",
      Cell: ({ cell: { value } }) => (value ? getMmddyyyyDateFormatted(value) : "-"),
    },
    {
      Header: "Payment Type",
      accessor: "paymentType",
      Cell: ({ cell: { value } }) => value || "-",
    },
    {
      Header: "Cheque No.",
      accessor: "checkNumber",
      Cell: ({
        row: {
          original: { paymentType, chequeNo, transactionId },
        },
      }) => getCheckNumber(paymentType, chequeNo, transactionId),
    },
    {
      Header: "Amount",
      accessor: "poAmount",
      Cell: ({ cell: { value } }) => (typeof value == "number" ? <>{formatAmount(value)}</> : ""),
      style: { textAlign: "right" },
    },
    {
      Header: "Balance",
      accessor: "balance",
      Cell: ({
        row: {
          original: { poAmount, amountPaid },
        },
      }) => <>{formatAmount( (amountPaid || 0) - poAmount)}</>,
      style: { textAlign: "right" },
    },
    {
      Header: "Received Status",
      accessor: "received",
      Cell: ({ cell: { value } }) => (
        <span className={`badge badge-soft-${value ? "success" : "warning"}`}>{value ? "Received" : "Open"}</span>
      ),
    },
  ];
}
export const tableData = [
  {
    sno: "1",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "2",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "3",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "4",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "5",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "6",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "7",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "8",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "9",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "10",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
  {
    sno: "11",
    invoiceNumber: "0803201-105",
    date: "10-05-2022",
    amount: "304.55",
    balance: "10.55",
  },
];
