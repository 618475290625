import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../assets/images/warehouse/salespage/excel.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import { Link } from "react-router-dom";
import { capitalize } from "../../utility/commonutility";

export const supplierColumnsList = [
    {
      id: "supplierNumber",
      name: "Supplier. Num",
      cellClassName: "fw-medium",
    },
    {
      id: "companyName",
      name: "Company",
      cellClassName: "fbadge badge-soft-primary",
    },
    {
      id: "supplierName",
      name: "Supplier Name",
    },
  ];

export const actions = (history,toggleDeleteModal,toggleSearchModal) => [
    // {
    //     id: "reset",
    //     label: "Reset",
    //     icon: ResetIcon,
    //     clickHandler:() => {},
    // }, 
    // {
    //     id: "print",
    //     label: "Print",
    //     icon: PrintIcon,
    //     clickHandler:() =>{}
    // }, {
    //     id: "export",
    //     label: "Export",
    //     icon: ExportIcon,
    //     clickHandler:() =>{}
    // }, 
    {
        id: "new",
        label: "New",
        icon: NewIcon,
        clickHandler:() => history.push("/warehousevendors/new")
    },
    {
        id: "delete",
        label: "Delete",
        icon: DeleteIcon,
        clickHandler:toggleDeleteModal,
    },   
    // {
    //     id: "smart_search",
    //     label: "Smart-Search",
    //     icon: <i className="ri-search-2-fill text-primary" style={{fontSize:"20px"}}></i>,
    //     clickHandler:toggleSearchModal,
    // }
]

export function generateColumns() {
    return [
        {
            Header: "Company Name",
            accessor: "supplierName",
            Cell:({row:{original:{supplierNumber,supplierName}}}) => <Link to={`/warehousevendors/${supplierNumber}`}>{capitalize(supplierName)}</Link>
        }, 
        {
            Header: "Supplier. Num",
            accessor: "supplierNumber",
            Cell:({cell:{value}}) => <Link to={`/warehousevendors/${value}`}>{value}</Link>
        },
        {
            Header: "Supplier Name",
            accessor: "customerName",
            Cell:({row:{original : {firstName, lastName}}}) => 
                {
                    const fullName = `${firstName || ""} ${lastName || ""}`.trim();
                    return capitalize(fullName) || "-";
                  },
        },
    ]
}

export function getSearchLabel(searchBy) {
    switch(searchBy) {
      case "supplierName": return "Company Name";
      case "supplierNumber": return "Supplier Number";
      case "city": return "City";
      case "country": return "County";
      default :  return "";
    }
}
