import React from 'react'
import { actions } from './helper';

function InventoryActions({
    history,
    inventoryDetailsValidation,
    // inventoryFinancialValidation,
    justifyPillsToggle,
    setSearch,
    toggleDeleteModal,
    id,
    getInventoryItemApiCall,
    setUpcSearch,
    path,
    handleBundleReset,
}) {
  return (
    <div className="d-flex justify-content-center border border-section py-2 px-1 h-100">
                    {actions(
                      history,
                      inventoryDetailsValidation,
                      // inventoryFinancialValidation,
                      justifyPillsToggle,
                      setSearch,
                      toggleDeleteModal,
                      id,
                      getInventoryItemApiCall,
                      setUpcSearch,
                      path,
                      handleBundleReset,
                    ).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="action-icon-container show-cursor-pointer"
                          onClick={item.clickHandler || (() => {})}
                        >
                          <img className="action-icon" src={item.icon} />
                          <span>{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
  )
}

export default InventoryActions
