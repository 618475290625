//Utility
import { getMmddyyyyDateFormatted, getCustomDateFormatted } from "../../../../utility/dateutility";
import {formatAmount} from "../../../../utility/commonutility"
import { Button } from "reactstrap";
import { setLocalStorage } from "../../../../utility/storageutility";

export function generateColumns(history) {
    return [
        {
            Header: "Date Paid",
            accessor: "datePaid",
            Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "",
        }, 
        {
            Header: "Time",
            accessor: "time",
            Cell:({row:{original:{datePaid}}}) => datePaid ? getCustomDateFormatted(datePaid,"hh:mm A") : "",
        }, 
        {
            Header: "Hold Date",
            accessor: "holdDate",
            Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "",
        }, 
        {
            Header: "SID",
            accessor: "sId"
        }, {
            Header: "Salesman",
            accessor: "salesman.name"
        }, {
            Header: "Payment Mode",
            accessor: "pType"
        }, {
            Header: "Cheque No.",
            accessor: "chequeNo",
            Cell:({cell:{value}}) => value || "-"
        }, {
            Header: "Amount Paid",
            accessor: "amountPaid",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
            style: {textAlign:"right"}
        },{
            Header: "Amount Allocated",
            accessor: "amountAllocated",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
            style: {textAlign:"right"}
        }, {
            Header: "Change",
            accessor: "change",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : `${formatAmount(0)}`,
            style: {textAlign:"right"}
        },
    ]
}

export function generateModalColumns(history) {
    return [
        {
            Header: "Date Paid",
            accessor: "datePaid",
            Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "",
        }, 
        {
            Header: "Time",
            accessor: "time",
            Cell:({row:{original:{datePaid}}}) => datePaid ? getCustomDateFormatted(datePaid,"hh:mm A") : "",
        }, 
        // {
        //     Header: "Hold Date",
        //     accessor: "holdDate",
        //     Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "",
        // }, 
        {
            Header: "SID",
            accessor: "sID"
        }, {
            Header: "Salesman",
            accessor: "salesman.name"
        }, {
            Header: "Payment Mode",
            accessor: "pType"
        }, {
            Header: "Check#",
            accessor: "chequeNo",
            Cell:({cell:{value}}) => value || "-"
        }, {
            Header: "Amount Paid",
            accessor: "amountPaid",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
            style: {textAlign:"right"}
        },{
            Header: "Amount Allocated",
            accessor: "amountAlloc",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
            style: {textAlign:"right"}
        },
        {
            Header: "Transaction",
            accessor: "isReturn",
            Cell : ({cell : {value}}) =>  (
                <span className={`badge badge-soft-${value ? "warning":"success" }`}>
                    {value?'Refund':'Received'}
                </span>),
        },
    ]
}
export function generateInvoiceColumns(history,toggleModal, onClickPaymentHistoryHndlr) {
    return [
      {
        Header: "Invoice#",
        accessor: "invoiceNumber",
        Cell: ({ cell: { value } }) => {
          return (
            <div
              className="wh-link-color-green"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/warehousesales/${value}`, { redirected: true });
                setLocalStorage("activeTab", JSON.stringify({ warehousecustomer: "sales" }));
              }}
            >
              {value}
            </div>
          )
        },
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
        Cell : ({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "-",
      },
      {
        Header: "Total Amount",
        accessor: "totalInvAmount",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: "Total Paid",
        accessor: "totalPaid",
        Cell : ({cell : {value}}) => (typeof value == "number") ?  <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
      {
            Header: "Refund",
            accessor: "change",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : `${formatAmount(0)}`,
            style: {textAlign:"right"}
        },
      {
        Header: "Amount Due",
        accessor: "amountDue",
        Cell : ({cell : {value}}) => (typeof value == "number") ?  <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: <div className="text-center">Payments History</div>,
        accessor: "paymentsHistory",
        Cell:({cell:{value}}) => {
            return (
                <Button className={'py-0 w-50'} onClick={() => onClickPaymentHistoryHndlr(value)}>
                    Details
                </Button>
            )
        } ,
        style: {textAlign:"center"}
      }
    ];
}