import React, { useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function AddNewOptionModal({
  isOpen,
  toggle,
  title,
  inputId,
  addNewOptionHandleChange
}) {
  const [value,setValue] = useState("")
  return (
    <Modal 
        isOpen={isOpen} 
        toggle={() => toggle()}
    >
        <ModalHeader 
            className="p-3" 
            toggle={() => toggle()}
        >
          <span className="modal-title" id="exampleModalLabel">
            {title}
          </span>
        </ModalHeader>
        <ModalBody>
          <Input
            id={inputId}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </ModalBody>
        <ModalFooter className="">
          <div className="hstack gap-2 justify-content-end">
            <Button
              color="primary"
              onClick={() => {
                addNewOptionHandleChange(inputId,value)
                toggle();
              }}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
  )
}

export default AddNewOptionModal;
