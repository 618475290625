export default {
    GET_INVENTORY_LIST_REQUEST:"GET_INVENTORY_LIST_REQUEST",
    GET_INVENTORY_LIST_SUCCESS:"GET_INVENTORY_LIST_SUCCESS",
    GET_INVENTORY_LIST_FAIL:"GET_INVENTORY_LIST_FAIL",

    CHANGE_GET_INVENTORY_LIST_LOADING_STATUS: "CHANGE_GET_INVENTORY_LIST_LOADING_STATUS",

    GET_INVENTORY_ALL_MASTER_DATA_REQUEST:"GET_INVENTORY_ALL_MASTER_DATA_REQUEST",
    GET_INVENTORY_ALL_MASTER_DATA_SUCCESS:"GET_INVENTORY_ALL_MASTER_DATA_SUCCESS",
    GET_INVENTORY_ALL_MASTER_DATA_FAIL:"GET_INVENTORY_ALL_MASTER_DATA_FAIL",

    CREATE_INVENTORY_ITEM_REQUEST:"CREATE_INVENTORY_ITEM_REQUEST",
    CREATE_INVENTORY_ITEM_SUCCESS:"CREATE_INVENTORY_ITEM_SUCCESS",
    CREATE_INVENTORY_ITEM_FAIL:"CREATE_INVENTORY_ITEM_FAIL",

    GET_INVENTORY_ITEM_REQUEST:"GET_INVENTORY_ITEM_REQUEST",
    GET_INVENTORY_ITEM_SUCCESS:"GET_INVENTORY_ITEM_SUCCESS",
    GET_INVENTORY_ITEM_FAIL:"GET_INVENTORY_ITEM_FAIL",

    UPDATE_INVENTORY_ITEM_REQUEST:"UPDATE_INVENTORY_ITEM_REQUEST",
    UPDATE_INVENTORY_ITEM_SUCCESS:"UPDATE_INVENTORY_ITEM_SUCCESS",
    UPDATE_INVENTORY_ITEM_FAIL:"UPDATE_INVENTORY_ITEM_FAIL",

    DELETE_INVENTORY_ITEM_REQUEST:"DELETE_INVENTORY_ITEM_REQUEST",
    DELETE_INVENTORY_ITEM_SUCCESS:"DELETE_INVENTORY_ITEM_SUCCESS",
    DELETE_INVENTORY_ITEM_FAIL:"DELETE_INVENTORY_ITEM_FAIL",

    DELETE_INVENTORY_COLUMN_VALUES_REQUEST:"DELETE_INVENTORY_COLUMN_VALUES_REQUEST",
    DELETE_INVENTORY_COLUMN_VALUES_SUCCESS:"DELETE_INVENTORY_COLUMN_VALUES_SUCCESS",
    DELETE_INVENTORY_COLUMN_VALUES_FAIL:"DELETE_INVENTORY_COLUMN_VALUES_FAIL",

    GET_PURCHASE_ORDERS_REQUEST:"GET_PURCHASE_ORDERS_REQUEST",
    GET_PURCHASE_ORDERS_SUCCESS:"GET_PURCHASE_ORDERS_SUCCESS",
    GET_PURCHASE_ORDERS_FAIL:"GET_PURCHASE_ORDERS_FAIL",

    GET_SUPPLIERS_OF_GIVEN_ITEM_REQUEST:"GET_SUPPLIERS_OF_GIVEN_ITEM_REQUEST",
    GET_SUPPLIERS_OF_GIVEN_ITEM_SUCCESS:"GET_SUPPLIERS_OF_GIVEN_ITEM_SUCCESS",
    GET_SUPPLIERS_OF_GIVEN_ITEM_FAIL:"GET_SUPPLIERS_OF_GIVEN_ITEM_FAIL",

    GET_SALES_OF_GIVEN_ITEM_REQUEST:"GET_SALES_OF_GIVEN_ITEM_REQUEST",
    GET_SALES_OF_GIVEN_ITEM_SUCCESS:"GET_SALES_OF_GIVEN_ITEM_SUCCESS",
    GET_SALES_OF_GIVEN_ITEM_FAIL:"GET_SALES_OF_GIVEN_ITEM_FAIL",

    GET_MAX_INVENTORY_NUM_REQUEST:"GET_MAX_INVENTORY_NUM_REQUEST",
    GET_MAX_INVENTORY_NUM_SUCCESS:"GET_MAX_INVENTORY_NUM_SUCCESS",
    GET_MAX_INVENTORY_NUM_FAIL:"GET_MAX_INVENTORY_NUM_FAIL",

    CREATE_CUSTOMER_INVENTORY_PRICE_REQUEST:"CREATE_CUSTOMER_INVENTORY_PRICE_REQUEST",
    CREATE_CUSTOMER_INVENTORY_PRICE_SUCCESS:"CREATE_CUSTOMER_INVENTORY_PRICE_SUCCESS",
    CREATE_CUSTOMER_INVENTORY_PRICE_FAIL:"CREATE_CUSTOMER_INVENTORY_PRICE_FAIL",

    GET_ADJUSTMENT_HISTORY_OF_ITEM_REQUEST:"GET_ADJUSTMENT_HISTORY_OF_ITEM_REQUEST",
    GET_ADJUSTMENT_HISTORY_OF_ITEM_SUCCESS:"GET_ADJUSTMENT_HISTORY_OF_ITEM_SUCCESS",
    GET_ADJUSTMENT_HISTORY_OF_ITEM_FAIL:"GET_ADJUSTMENT_HISTORY_OF_ITEM_FAIL",

    CREATE_QTY_ADJUSTMENT_REQUEST:"CREATE_QTY_ADJUSTMENT_REQUEST",
    CREATE_QTY_ADJUSTMENT_SUCCESS:"CREATE_QTY_ADJUSTMENT_SUCCESS",
    CREATE_QTY_ADJUSTMENT_FAIL:"CREATE_QTY_ADJUSTMENT_FAIL",

    CREATE_MULTI_QTY_ADJUSTMENT_REQUEST:"CREATE_MULTI_QTY_ADJUSTMENT_REQUEST",
    CREATE_MULTI_QTY_ADJUSTMENT_SUCCESS:"CREATE_MULTI_QTY_ADJUSTMENT_SUCCESS",
    CREATE_MULTI_QTY_ADJUSTMENT_FAIL:"CREATE_MULTI_QTY_ADJUSTMENT_FAIL",

    GET_SPECIAL_PRICES_REQUEST:"GET_SPECIAL_PRICES_REQUEST",
    GET_SPECIAL_PRICES_SUCCESS:"GET_SPECIAL_PRICES_SUCCESS",
    GET_SPECIAL_PRICES_FAIL:"GET_SPECIAL_PRICES_FAIL",

    ACTIVATE_INVENTORY_ITEM_REQUEST:"ACTIVATE_INVENTORY_ITEM_REQUEST",
    ACTIVATE_INVENTORY_ITEM_SUCCESS:"ACTIVATE_INVENTORY_ITEM_SUCCESS",
    ACTIVATE_INVENTORY_ITEM_FAIL:"ACTIVATE_INVENTORY_ITEM_FAIL",

    CHANGE_INVENTORY_API_LOADING_STATUS: "CHANGE_INVENTORY_API_LOADING_STATUS",

    CREATE_BUNDLES_REQUEST:"CREATE_BUNDLES_REQUEST",
    CREATE_BUNDLES_SUCCESS:"CREATE_BUNDLES_SUCCESS",
    CREATE_BUNDLES_FAIL:"CREATE_BUNDLES_FAIL",

    GET_INVENTORY_BUNDLES_LIST_REQUEST:"GET_INVENTORY_BUNDLES_LIST_REQUEST",
    GET_INVENTORY_BUNDLES_LIST_SUCCESS:"GET_INVENTORY_BUNDLES_LIST_SUCCESS",
    GET_INVENTORY_BUNDLES_LIST_FAIL:"GET_INVENTORY_BUNDLES_LIST_FAIL",

    CHANGE_GET_INVENTORY_BUNDLES_LIST_LOADING_STATUS: "CHANGE_GET_INVENTORY_BUNDLES_LIST_LOADING_STATUS",

    GET_MAX_BUNDLE_NO_REQUEST: "GET_MAX_BUNDLE_NO_REQUEST",
    GET_MAX_BUNDLE_NO_SUCCESS: "GET_MAX_BUNDLE_NO_SUCCESS",
    GET_MAX_BUNDLE_NO_FAIL: "GET_MAX_BUNDLE_NO_FAIL",

    GET_BUNDLE_ITEM_REQUEST:"GET_BUNDLE_ITEM_REQUEST",
    GET_BUNDLE_ITEM_SUCCESS:"GET_BUNDLE_ITEM_SUCCESS",
    GET_BUNDLE_ITEM_FAIL:"GET_BUNDLE_ITEM_FAIL",

    UPDATE_BUNDLE_ITEM_REQUEST:"UPDATE_BUNDLE_ITEM_REQUEST",
    UPDATE_BUNDLE_ITEM_SUCCESS:"UPDATE_BUNDLE_ITEM_SUCCESS",
    UPDATE_BUNDLE_ITEM_FAIL:"UPDATE_BUNDLE_ITEM_FAIL",

    DELETE_BUNDLE_ITEM_REQUEST:"DELETE_BUNDLE_ITEM_REQUEST",
    DELETE_BUNDLE_ITEM_SUCCESS:"DELETE_BUNDLE_ITEM_SUCCESS",
    DELETE_BUNDLE_ITEM_FAIL:"DELETE_BUNDLE_ITEM_FAIL",
}