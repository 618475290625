export default {
    GET_ZOHO_IN_REPORT_REQUEST:"GET_ZOHO_IN_REPORT_REQUEST",
    GET_ZOHO_IN_REPORT_SUCCESS:"GET_ZOHO_IN_REPORT_SUCCESS",
    GET_ZOHO_IN_REPORT_FAIL:"GET_ZOHO_IN_REPORT_FAIL",

    CHANGE_GET_ZOHO_IN_REPORT_LOADING_STATUS:"CHANGE_GET_ZOHO_IN_REPORT_LOADING_STATUS",

    GET_ZOHO_INVOICES_REQUEST:"GET_ZOHO_INVOICES_REQUEST",
    GET_ZOHO_INVOICES_SUCCESS:"GET_ZOHO_INVOICES_SUCCESS",
    GET_ZOHO_INVOICES_FAIL:"GET_ZOHO_INVOICES_FAIL",

    CHANGE_GET_ZOHO_INVOICES_LOADING_STATUS:"CHANGE_GET_ZOHO_INVOICES_LOADING_STATUS",
}