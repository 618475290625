import React, { useState,useEffect,useRef, Fragment } from 'react';
import { Table,Form, Row, Col, Button, Input } from "reactstrap";
import ReactPaginate from "react-paginate";
import NoDataComponent from '../../../Components/Common/NoDataComponent';
import TableLoader from '../../../Components/Common/TableLoader';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function WhBundleTable({
  tableData,
  columnsList,
  divClass,
  loading,
  showNoDataComponent,
  noDataComponentClassname,
  showPagination = true,
  pageNumber,
  pageCount,
  onChange,
  customPageSize,
  handlePageChange,
  selectedRows,

}) {
  const [numPages,setNumPages] = useState(0);
  const [pageSize, setPageSize] = useState(customPageSize);
  const [render,setRender] = useState(false);
  // const selectedRows = useRef({
  //   all:false,
  //   partiallySelected:[]
  // })
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
    handlePageChange(0, event.target.value);
  };
  const handlePageClick = (event) => {
    handlePageChange(event.selected, pageSize);
  };
  const handleRowSelection = (selectAll,uniqueId,item) => {
   
    if(selectAll && selectedRows.current){
      if(!selectedRows.current.all){
        selectedRows.current.all= true;
        const newItemNumbers = tableData.map(item => item.itemNumber);
        selectedRows.current.data = [...selectedRows.current.data,...tableData];
        selectedRows.current.newDataLength = newItemNumbers.length;
        selectedRows.current.partiallySelected = [...selectedRows.current.partiallySelected,...newItemNumbers]
      }else{
        selectedRows.current.all= false;
        selectedRows.current.data = selectedRows.current.data.filter(item => !tableData.some(obj => obj.itemNumber === item.itemNumber));
        selectedRows.current.defaultSelected = [...selectedRows.current.defaultSelected].filter(itemNumber => !tableData.map(obj => obj.itemNumber).includes(itemNumber));
        selectedRows.current.partiallySelected = [...selectedRows.current.defaultSelected];
        selectedRows.current.newDataLength = 0;
      }
    }
    if(!selectAll && selectedRows.current){
      if(!selectedRows.current.partiallySelected.includes(uniqueId)){
        selectedRows.current.partiallySelected.push(uniqueId);
        selectedRows.current.data = [...selectedRows.current.data,item]
        selectedRows.current.newDataLength = selectedRows.current.newDataLength + 1;
        if(tableData.length === selectedRows.current.newDataLength){
          selectedRows.current.all = true;
        }
        // selectedRows.current.oldDataLength = [...selectedRows.current.partiallySelected].length;
        // if(!selectedRows.current.all && selectedRows.current.newDataLength === selectedRows.current.partiallySelected){
        //   selectedRows.current.all = true;
        // }
      }else{
        selectedRows.current.partiallySelected = selectedRows.current.partiallySelected.filter(id => id !== uniqueId);
        selectedRows.current.data = selectedRows.current.data.filter(item => item.itemNumber !== uniqueId )
        selectedRows.current.newDataLength = selectedRows.current.newDataLength - 1;
        selectedRows.current.all = false;
      }
    }
    setRender(!render)
  };
// useEffect(()=>{
//   if(selectedRows.current && selectedRows.current.all && tableData.length !== selectedRows.current.partiallySelected.length){
//     selectedRows.current.all = false;
//   }

// },[selectedRows.current.partiallySelected])
  return (
    <Fragment>
      <div className={`${divClass} custom-table mb-2 wh-bundle-table-container`}>
      <Table className="table-sm table-nowrap table-hover mb-0">
        <thead className={`wh-table-head sticky-top`}>
          <tr className={'wh-table-border'}>
          <th scope="col" className='text-center'>
              <input
                  type="checkbox"
                  checked={selectedRows.current && selectedRows.current.all}
                  onChange={() => handleRowSelection(true)}
                  // disabled={true}
                />
          </th>
            {columnsList.map((item) => (
              
              <th key={item.accessor} scope="col"
                className={`wh-table-border ${item.accessor === 'title'?'text-center':''}`}
              >
                {item.Header}
                
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, rowIndex) => (
            <tr
              key={`item-${rowIndex}`}
              className={`wh-table-border 
                
                `}
              // onClick={() => handleRowSelection(rowIndex)}
            >
              <td className='text-center'>
                <input
                  type="checkbox"
                  checked={selectedRows.current && selectedRows.current.all ? true : selectedRows.current.partiallySelected.includes(item.itemNumber)}
                  onChange={() => handleRowSelection(false,item.itemNumber,item)}
                />
              </td>
              {columnsList.map((col, colIndex) => (
                <td key={`${col.accessor}-${colIndex}`}>
                  {col.accessor === 'itemNumber' && (
                  <Link
                      to={`/warehouseinventory/${item[col.accessor]}`}
                      target="_blank"
                    >
                      {item[col.accessor]}
                    </Link>
                )}
                  {col.accessor !== 'itemNumber' && item[col.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
    {showNoDataComponent && tableData.length === 0 && !loading && (
        <NoDataComponent className={`py-5 ${noDataComponentClassname}`} />
      )}

    {loading && <TableLoader />}

    {showPagination!== false && pageCount > 0 && (
        <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
          <Col className="col-md-auto d-none d-md-block pe-2">
            <span>Rows per page:</span>
          </Col>
          <Col className="col-md-auto d-none d-md-block px-0">
            <Form variant="standard" sx={{ m: 1, width: 35 }}>
              <select value={pageSize} onChange={onChangeInSelect} className="form-select">
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
            </Form>
          </Col>

          <Col className="col-md-auto mb-0">
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              color="btncolor"
              onPageChange={handlePageClick}
              forcePage={pageNumber}
              pageCount={numPages|| Math.ceil(pageCount/customPageSize)}
              previousLabel="Previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination mb-0"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </Col>
        </Row>
      )}
    </Fragment>


  );
}

export default WhBundleTable;


