import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import CustomersIcon from "../../assets/images/warehouse/header/customers.png";
//Utility
import { getYyyymmddDateFormatted } from "../../utility/dateutility";

export const tabs = [
  {
    id: "basic_info",
    name: "Basic Info",
  },
  {
    id: "billing_info",
    name: "Billing Info",
  },
  {
    id: "sales",
    name: "Sales",
  },
  {
    id: "payments",
    name: "Payments",
  },
  {
    id: "unpaid_invoices",
    name: "Unpaid Invoices",
  },
  // {
  //   id: "account_history",
  //   name: "Account History",
  // },
];

export const actions = (
  setSearch,
  history,
  toggleDeleteModal,
  basicInfoValidation,
  billingInfoValidation,
  justifyPillsToggle,
  id,
  getCustomerApiCall
) => [
  {
    id: "new",
    label: "New",
    icon: NewIcon,
    clickHandler: () => {
      history.push("/warehousecustomers/new");
      // basicInfoValidation.resetForm();
      // classificationValidation.resetForm();
      // billingInfoValidation.resetForm();
      // justifyPillsToggle("basic_info");
      // setSearch("");
    },
  },
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler : () => {
      if(id === "new") {
        basicInfoValidation.resetForm();
        billingInfoValidation.resetForm();
      } else getCustomerApiCall();
      justifyPillsToggle("basic_info");
    }
  },
  {
    id: "customers",
    label: "Customers",
    icon: CustomersIcon,
    clickHandler: () => history.push("/warehousecustomers"),
  },
  {
    id: "delete",
    label: "Delete",
    icon: DeleteIcon,
    clickHandler: toggleDeleteModal,
  },
 
];

export const generatePayload = (basicInfo, billingInfo) => {
  return { ...basicInfo, ...billingInfo };
};

export const customerColumnsList = [
  {
    id: "customerNumber",
    name: "Cust. Num",
    cellClassName: "fw-medium",
  },
  {
    id: "companyName",
    name: "Company",
  },
  {
    id: "customerName",
    name: "Customer Name",
    cellClassName: "fbadge badge-soft-primary",
  },
];

export const mapCustomerDetails = (apiResp, setBasicInfo, setBillingInfo, initialValidationValues) => {
  const {
    salutation,
    firstName,
    lastName,
    companyName,
    customerType,
    phone,
    cell,
    fax,
    mobileProvider,
    email,
    website,
    gstNumber,
    flatNumber,
    street,
    city,
    state,
    zip,
    landmark,
    //country,
    isCheckAddress,
    taxId,
    stateTaxId,
    vendor,
    cigaretteLicense,
    tobaccoLicense,
    dea,
    invMemo,
    isActive,
    isOutOfState,
  } = apiResp.data.customer;
  const basicInfo = {
    salutation: salutation || "",
    companyName: companyName || "",
    firstName: firstName || "",
    lastName: lastName || "",
    customerType: customerType || "",
    phone: phone || "",
    cell: cell || "",
    fax: fax || "",
    mobileProvider: mobileProvider || "",
    email: email || "",
    website: website || "",
    gstNumber:gstNumber || "",
    flatNumber: flatNumber || "",
    street: street || "",
    city: city || "",
    state: state || "",
    zip: zip || "",
    landmark: landmark || "",
    //country: country || "",
    isCheckAddress,
    taxId: taxId || "",
    stateTaxId: stateTaxId || "",
    vendor: vendor || "",
    cigaretteLicense: cigaretteLicense || "",
    tobaccoLicense: tobaccoLicense || "",
    dea: dea || "",
    invMemo: invMemo || "",
    isActive,
    isOutOfState: typeof isOutOfState == "boolean" ? isOutOfState : false,
  }
  setBasicInfo(basicInfo);
  const {
    shipToRef,
    shippedVia,
    group,
    routeName,
    subGroup,
    routeDeliveryDay,
    zone,
    driverName,
    businessType,
    salesman,
    defaultInvComment,
    // isOutOfState,
    isViewInvPrevBal,
    isEmailList,
    isMailingList,
    isRejectPromotion,
    viewInvRetail,
    barcode,
    invCopy,
    isInvoiceSpecial,
  } = apiResp.data.customer;
  const {
    isTaxExempt,
    getSalePrices,
    // isOutOfState,
    pricingType,
    markupPercent,
    markup,
    discountPercent,
    discount,
    code,
    // dlState,
    paymentTerms,
    creditLimit,
    salesLimit,
    isCreditHold,
    creditCardHold,
    isBillToBill,
    isNoChecks,
    isExcludeOverduePopup,
    check3pCharge,
    cashBackBalance,
    scheduleFrom,
    scheduleTo,
    scheduleMemo,
    popUp,
    popMemo,
  } = apiResp.data.customer;
  const billingInfo = {
    isTaxExempt,
    getSalePrices,
    // isOutOfState: typeof isOutOfState == "boolean" ? isOutOfState : false,
    pricingType: pricingType || "",
    markupPercent: markupPercent || "",
    markup: markup || "",
    discountPercent: discountPercent || "",
    discount: discount || "",
    code: code || "",
    // dlState: dlState || "",
    paymentTerms: paymentTerms || "",
    creditLimit: creditLimit || "",
    salesLimit: salesLimit || "",
    isCreditHold,
    creditCardHold:typeof creditCardHold == "boolean" ? creditCardHold : false,
    isBillToBill,
    isNoChecks,
    isExcludeOverduePopup,
    check3pCharge: check3pCharge || "",
    cashBackBalance: cashBackBalance || "",
    scheduleFrom: scheduleFrom ? getYyyymmddDateFormatted(scheduleFrom) : "",
    scheduleTo: scheduleTo ? getYyyymmddDateFormatted(scheduleTo) : "",
    scheduleMemo: scheduleMemo || "",
    popUp,
    popMemo: popMemo || "",
  }
  setBillingInfo(billingInfo);
  initialValidationValues.current.basicInfo = basicInfo;
  initialValidationValues.current.billingInfo = billingInfo;
};

export const mapDropdownState = (apiResp, setDropdownState) => {
  const {
    data: {
      lists: { businessType, customerType, group, routeName, shipToRef, shippedVia, subGroup, zone },
    },
  } = apiResp;
  setDropdownState((prev) => ({
    ...prev,
    businessType,
    customerType,
    group,
    routeName,
    shipToRef,
    shippedVia,
    subGroup,
    zone,
  }));
};
