import inventoryconstants from "./inventoryconstants";

const initialState = {
  getInventoryListResp: undefined,
  getInventoryListLoading: false,
  getInventoryAllMasterDataResp: undefined,
  createInventoryItemResp: undefined,
  getInventoryItemResp: undefined,
  updateInventoryItemResp: undefined,
  deleteInventoryItemResp: undefined,
  deleteInventoryColumnValuesResp: undefined,
  getPurchaseOrdersResp: undefined,
  getSuppliersOfGivenItemResp: undefined,
  getSalesOfGivenItemResp: undefined,
  getMaxInventoryNumResp: undefined,
  createCustomerInventoryPriceResp: undefined,
  getAdjustmentHistoryOfItemResp: undefined,
  createQtyAdjustmentResp: undefined,
  createMultiQtyAdjustmentResp: undefined,
  getSpecialPricesResp: undefined,
  activateInventoryItemResp: undefined,
  inventoryErrorResp: undefined,
  inventoryApiLoading: false,

  //Bundles
  getMaxBundleNoResp: undefined,
  createBundleItemResp: undefined,
  updateBundleItemResp: undefined,
  getBundleItemResp: undefined,
  getInventoryBundlesListResp: undefined,
  getInventoryBundlesListLoading: false,
  deleteBundleItemResp: undefined,
};

export default function inventory(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case inventoryconstants.GET_INVENTORY_LIST_SUCCESS:
      return { ...state, getInventoryListResp: payload };
    case inventoryconstants.CHANGE_GET_INVENTORY_LIST_LOADING_STATUS:
      return { ...state, getInventoryListLoading: payload };
    case inventoryconstants.GET_INVENTORY_ALL_MASTER_DATA_SUCCESS:
      return { ...state, getInventoryAllMasterDataResp: payload };
    case inventoryconstants.CREATE_INVENTORY_ITEM_SUCCESS:
      return { ...state, createInventoryItemResp: payload };
    case inventoryconstants.GET_INVENTORY_ITEM_SUCCESS:
      return { ...state, getInventoryItemResp: payload };
    case inventoryconstants.UPDATE_INVENTORY_ITEM_SUCCESS:
      return { ...state, updateInventoryItemResp: payload };
    case inventoryconstants.DELETE_INVENTORY_ITEM_SUCCESS:
      return { ...state, deleteInventoryItemResp: payload };
    case inventoryconstants.DELETE_INVENTORY_COLUMN_VALUES_SUCCESS:
      return { ...state, deleteInventoryColumnValuesResp: payload };
    case inventoryconstants.GET_PURCHASE_ORDERS_SUCCESS:
      return { ...state, getPurchaseOrdersResp: payload };
    case inventoryconstants.GET_SUPPLIERS_OF_GIVEN_ITEM_SUCCESS:
      return { ...state, getSuppliersOfGivenItemResp: payload };
    case inventoryconstants.GET_SALES_OF_GIVEN_ITEM_SUCCESS:
      return { ...state, getSalesOfGivenItemResp: payload };
    case inventoryconstants.GET_MAX_INVENTORY_NUM_SUCCESS:
      return { ...state, getMaxInventoryNumResp: payload };
    case inventoryconstants.CREATE_CUSTOMER_INVENTORY_PRICE_SUCCESS:
      return { ...state, createCustomerInventoryPriceResp: payload };
    case inventoryconstants.GET_ADJUSTMENT_HISTORY_OF_ITEM_SUCCESS:
      return { ...state, getAdjustmentHistoryOfItemResp: payload };
    case inventoryconstants.CREATE_QTY_ADJUSTMENT_SUCCESS:
      return { ...state, createQtyAdjustmentResp: payload };
    case inventoryconstants.CREATE_MULTI_QTY_ADJUSTMENT_SUCCESS:
      return { ...state, createMultiQtyAdjustmentResp: payload };
    case inventoryconstants.GET_SPECIAL_PRICES_SUCCESS:
      return { ...state, getSpecialPricesResp: payload };
    case inventoryconstants.ACTIVATE_INVENTORY_ITEM_SUCCESS:
      return { ...state, activateInventoryItemResp: payload };
    case inventoryconstants.CHANGE_INVENTORY_API_LOADING_STATUS:
      return { ...state, inventoryApiLoading: payload };

    //Bundles SUCCESS
    case inventoryconstants.CREATE_BUNDLES_SUCCESS:
      return { ...state, createBundleItemResp: payload };
    case inventoryconstants.GET_INVENTORY_BUNDLES_LIST_SUCCESS:
      return { ...state, getInventoryBundlesListResp: payload };
    case inventoryconstants.CHANGE_GET_INVENTORY_BUNDLES_LIST_LOADING_STATUS:
      return { ...state, getInventoryBundlesListLoading: payload };
    case inventoryconstants.GET_BUNDLE_ITEM_SUCCESS:
      return { ...state, getBundleItemResp: payload };
    case inventoryconstants.UPDATE_BUNDLE_ITEM_SUCCESS:
      return { ...state, updateBundleItemResp: payload };
    case inventoryconstants.DELETE_BUNDLE_ITEM_SUCCESS:
      return { ...state, deleteBundleItemResp: payload };
    case inventoryconstants.GET_MAX_BUNDLE_NO_SUCCESS:
      return { ...state, getMaxBundleNoResp: payload };

    //FAIL
    case inventoryconstants.GET_INVENTORY_LIST_FAIL:
    case inventoryconstants.GET_INVENTORY_ALL_MASTER_DATA_FAIL:
    case inventoryconstants.CREATE_INVENTORY_ITEM_FAIL:
    case inventoryconstants.GET_INVENTORY_ITEM_FAIL:
    case inventoryconstants.UPDATE_INVENTORY_ITEM_FAIL:
    case inventoryconstants.DELETE_INVENTORY_ITEM_FAIL:
    case inventoryconstants.DELETE_INVENTORY_COLUMN_VALUES_FAIL:
    case inventoryconstants.GET_PURCHASE_ORDERS_FAIL:
    case inventoryconstants.GET_SUPPLIERS_OF_GIVEN_ITEM_FAIL:
    case inventoryconstants.GET_SALES_OF_GIVEN_ITEM_FAIL:
    case inventoryconstants.GET_MAX_INVENTORY_NUM_FAIL:
    case inventoryconstants.CREATE_CUSTOMER_INVENTORY_PRICE_FAIL:
    case inventoryconstants.GET_ADJUSTMENT_HISTORY_OF_ITEM_FAIL:
    case inventoryconstants.CREATE_QTY_ADJUSTMENT_FAIL:
    case inventoryconstants.CREATE_MULTI_QTY_ADJUSTMENT_FAIL:
    case inventoryconstants.GET_SPECIAL_PRICES_FAIL:
    case inventoryconstants.ACTIVATE_INVENTORY_ITEM_FAIL:

    //Bundles FAIL
    case inventoryconstants.CREATE_BUNDLES_FAIL:
    case inventoryconstants.DELETE_BUNDLE_ITEM_FAIL:
    case inventoryconstants.GET_BUNDLE_ITEM_FAIL:
    case inventoryconstants.UPDATE_BUNDLE_ITEM_FAIL:
    case inventoryconstants.GET_MAX_BUNDLE_NO_FAIL:

      return { ...state, inventoryErrorResp: payload };
    default:
      return state;
  }
}
