import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Input, Label, Row, Button, Modal, ModalHeader, ModalBody, FormGroup } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset-blue.png";
import SpecialPricesIcon from "../../../../assets/images/warehouse/salespage/special-prices.png";
import DiscountIcon from "../../../../assets/images/warehouse/salespage/discount.png";
import SpecialPrices from "./specialprices";
import { formatAmountSymbol } from "../../../../utility/commonutility";
import { generateStatesData } from "../basicinfohelper";
import { useMemo } from "react";

const paymentTypes = [
  {
    value: "CASH",
    label: "Cash",
  },
  {
    value: "CHEQUE",
    label: "Cheque",
  },
  {
    value: "ONLINE",
    label: "Online",
  },
  {
    value: "CC",
    label: "Credit Card",
  },
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "BANKTRANSFER",
    label: "Bank Transfer",
  },

];
export const BillingInfo = (props) => {
  const {
    id,
    billingInfoValidation: validation,
    states,
    getSpecialPricesResp,
    generateApiUrl,
    dispatch,
    getInventoryListResp,
    createSpecialPriceForCustomerResp,
    deleteSpecialPriceForCustomerResp,
    toast,
    getMasterDataForSupplierResp,
  } = props;
  const [showSpecialPricesModal, setShowSpecialPricesModal] = useState(false);
  const prevCreateSpecialPriceForCustomerResp = useRef(createSpecialPriceForCustomerResp);
  useEffect(() => {
    if (
      createSpecialPriceForCustomerResp &&
      createSpecialPriceForCustomerResp !== prevCreateSpecialPriceForCustomerResp.current
    ) {
      toast.success("Successfully saved customer special prices");
      toggleSpecialPricesModal();
    }
    prevCreateSpecialPriceForCustomerResp.current = createSpecialPriceForCustomerResp;
  }, [createSpecialPriceForCustomerResp]);
  function toggleSpecialPricesModal() {
    setShowSpecialPricesModal(!showSpecialPricesModal);
  }
  const handlePricingChange = (e) => {
    validation.setValues((prev) => ({
      ...prev,
      pricingType: e.target.value,
      markupPercent: "",
      markup: "",
      discountPercent: "",
      discount: "",
      code: "",
    }));
  };
  // const handleBillToBillChange = (e) => {
  //   const { checked } = e.target;
  //   if (checked) {
  //     validation.setValues((prev) => ({
  //       ...prev,
  //       creditCardHold: true,
  //       isBillToBill: true,
  //       isNoChecks: true,
  //     }));
  //   } else validation.handleChange(e);
  // };
  const { pricingType: pricing } = validation.values;
  const statesData = useMemo(() => generateStatesData(), []);
  return (
    <>
      <Container fluid className="px-5 py-1">
        <h4 className="mb-1 wh-text-white">Sales Information</h4>
        <div className="form-check mb-1">
          <Input
            name="isTaxExempt"
            id="isTaxExempt"
            checked={validation.values.isTaxExempt}
            onChange={validation.handleChange}
            className="form-check-input"
            type="checkbox"
          />
          <Label className="form-check-label text-nowrap" htmlFor="isTaxExempt">
            Tax Exempt
          </Label>
        </div>
        <Row className="mb-1">
          <Col xs="9">
            <Row className={`${!pricing ? "mb-1" : "mb-1"}`}>
              <Col xs={3} xxl={2} className="d-flex align-items-center">
                <Label htmlFor="pricing" className="form-label mb-0 text-nowrap">
                  Pricing :
                </Label>
              </Col>
              <Col xs={5} xxl={6}>
                <select
                  name="pricingType"
                  id="pricingType"
                  value={validation.values.pricingType}
                  onChange={handlePricingChange}
                  className="form-select"
                >
                  <option value=""></option>
                  {/* <option value="markup">Markup</option>
                  <option value="discount">Discount</option> */}
                  <option value="code">Code</option>
                </select>
              </Col>
              <Col xs={2} className="d-flex align-items-center">
                <div className="form-check ms-2">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    name="getSalePrices"
                    id="getSalePrices"
                    checked={validation.values.getSalePrices}
                    onChange={validation.handleChange}
                  />
                  <Label className="form-check-label text-nowrap" htmlFor="getSalePrices">
                    Get Sales Discount
                  </Label>
                </div>
              </Col>
            </Row>

            <Row 
            className={`mb-1`}
            >
              {/* {pricing === "markup" && (
                <>
                  <Col xs={3} xxl={2} className="d-flex align-items-center">
                    <Label htmlFor="markupPercent" className="form-label mb-0 text-nowrap">
                      Markup % :
                    </Label>
                  </Col>
                  <Col xs={5} xxl={6}>
                    <Input
                      name="markupPercent"
                      id="markupPercent"
                      value={validation.values.markupPercent}
                      onChange={(e) => validation.setValues((prev) => ({ ...prev, markupPercent: e.target.value }))}
                      className="form-control"
                      placeholder="0.00"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onBlur={() =>
                        validation.setValues((prev) => ({
                          ...prev,
                          markupPercent: Number(prev.markupPercent).toFixed(2),
                        }))
                      }
                    />
                  </Col>
                </>
              )} */}
              {/* {pricing === "discount" && (
                <>
                  <Col xs={3} xxl={2} className="d-flex align-items-center">
                    <Label htmlFor="discountPercent" className="form-label mb-0 text-nowrap">
                      Discount % :
                    </Label>
                  </Col>
                  <Col xs={5} xxl={6}>
                    <Input
                      name="discountPercent"
                      id="discountPercent"
                      value={validation.values.discountPercent}
                      onChange={(e) => validation.setValues((prev) => ({ ...prev, discountPercent: e.target.value }))}
                      className="form-control"
                      placeholder="0.00"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onBlur={() =>
                        validation.setValues((prev) => ({
                          ...prev,
                          discountPercent: Number(prev.discountPercent).toFixed(2),
                        }))
                      }
                    />
                  </Col>
                </>
              )} */}
              {pricing === "code" && (
                <>
                  <Col xs={3} xxl={2} className="d-flex align-items-center">
                    <Label htmlFor="code" className="form-label mb-0 text-nowrap">
                      Code :
                    </Label>
                  </Col>
                  <Col xs={5} xxl={6}>
                    <select
                      className="form-select"
                      name="code"
                      id="code"
                      value={validation.values.code}
                      onChange={validation.handleChange}
                    >
                      <option value=""></option>
                      <option>A</option>
                      <option>B</option>
                      <option>C</option>
                      <option>D</option>
                    </select>
                  </Col>
                </>
              )}
              
              {/* {pricing && (
                <Col xs={2} className="d-flex align-items-center">
                  <div className="form-check ms-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      name="isOutOfState"
                      id="isOutOfState"
                      checked={validation.values.isOutOfState}
                      onChange={validation.handleChange}
                    />
                    <Label className="form-check-label text-nowrap" htmlFor="isOutOfState">
                      Out of State Customer
                    </Label>
                  </div>
                </Col>
              )} */}
            </Row>

            {/* {pricing === "markup" && (
              <Row className="mb-5">
                <Col xs={3} xxl={2} className="d-flex align-items-center">
                  <Label htmlFor="markup" className="form-label mb-0 text-nowrap">
                    Markup :
                  </Label>
                </Col>
                <Col xs={5} xxl={6}>
                  <div className="form-icon">
                    <Input
                      name="markup"
                      id="markup"
                      value={validation.values.markup}
                      onChange={(e) => validation.setValues((prev) => ({ ...prev, markup: e.target.value }))}
                      className="form-control form-control-icon"
                      placeholder="0.00"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onBlur={() =>
                        validation.setValues((prev) => ({ ...prev, markup: Number(prev.markup).toFixed(2) }))
                      }
                    />
                    <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                  </div>
                </Col>
              </Row>
            )} */}

            {/* {pricing === "discount" && (
              <Row className="mb-5">
                <Col xs={3} xxl={2} className="d-flex align-items-center">
                  <Label htmlFor="discount" className="form-label mb-0 text-nowrap">
                    Discount :
                  </Label>
                </Col>
                <Col xs={5} xxl={6}>
                  <div className="form-icon">
                    <Input
                      name="discount"
                      id="discount"
                      value={validation.values.discount}
                      onChange={(e) => validation.setValues((prev) => ({ ...prev, discount: e.target.value }))}
                      className="form-control form-control-icon"
                      placeholder="0.00"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onBlur={() =>
                        validation.setValues((prev) => ({ ...prev, discount: Number(prev.discount).toFixed(2) }))
                      }
                    />
                    <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                  </div>
                </Col>
              </Row>
            )} */}
            <Row className="mb-1">
              <Col xs={3} xxl={2} className="d-flex align-items-center">
                <Label htmlFor="paymentTerms" className="form-label mb-0 text-nowrap">
                  Payment Type :
                </Label>
              </Col>
              <Col xs={5} xxl={6}>
                <select
                  className="form-select"
                  name="paymentTerms"
                  id="paymentTerms"
                  value={validation.values.paymentTerms}
                  onChange={validation.handleChange}
                >
                  <option value="">Select</option>
                  {paymentTypes.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                  
                </select>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={3} xxl={2} className="d-flex align-items-center">
                <Label htmlFor="creditLimit" className="form-label mb-0 text-nowrap">
                  Credit Limit :
                </Label>
              </Col>
              <Col xs={5} xxl={6}>
                <div className="form-icon">
                  <Input
                    name="creditLimit"
                    id="creditLimit"
                    value={validation.values.creditLimit}
                    onChange={validation.handleChange}
                    className="form-control form-control-icon"
                    placeholder="0.00"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onBlur={() =>
                      validation.setValues((prev) => ({ ...prev, creditLimit: prev.creditLimit !== "" ? Number(prev.creditLimit).toFixed(2) : "" }))
                    }
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={3} xxl={2} className="d-flex align-items-center">
                <Label htmlFor="salesLimit" className="form-label mb-0 text-nowrap">
                  Sales Limit :
                </Label>
              </Col>
              <Col xs={5} xxl={6}>
                <div className="form-icon">
                  <Input
                    name="salesLimit"
                    id="salesLimit"
                    value={validation.values.salesLimit}
                    onChange={validation.handleChange}
                    className="form-control form-control-icon"
                    placeholder="0.00"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onBlur={() =>
                      validation.setValues((prev) => ({ ...prev, salesLimit: prev.salesLimit !== "" ? Number(prev.salesLimit).toFixed(2) : "" }))
                    }
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={3} xxl={2} className="d-flex align-items-center">
                <Label htmlFor="creditCardHold" className="form-label mb-0 text-nowrap">
                  Credit Card Hold :
                </Label>
              </Col>
              <Col xs={5} xxl={6}>
                <div className="d-flex justify-content-between">
                  <div className="form-check form-switch form-switch-md form-switch-success">
                    <Input
                      name="creditCardHold"
                      id="creditCardHold"
                      checked={validation.values.creditCardHold}
                      onChange={validation.handleChange}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                    />
                  </div>
                  <div className="form-check form-switch form-switch-md form-switch-success">
                    <Label htmlFor="isBillToBill">Bill to Bill</Label>
                    <Input
                      name="isBillToBill"
                      id="isBillToBill"
                      checked={validation.values.isBillToBill}
                      onChange={validation.handleChange}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={3} xxl={2} className="d-flex align-items-center">
                <Label htmlFor="isNoChecks" className="form-label mb-0 text-nowrap">
                  No Cheques Accepted :
                </Label>
              </Col>
              <Col xs={5} xxl={6}>
              <div className="d-flex justify-content-between">
                <div className="form-check form-switch form-switch-md form-switch-success">
                  <Input
                    name="isNoChecks"
                    id="isNoChecks"
                    checked={validation.values.isNoChecks}
                    onChange={validation.handleChange}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />
                </div>
                <div className="form-check form-switch form-switch-md form-switch-success">
                    <Input
                      name="isCreditHold"
                      id="isCreditHold"
                      checked={validation.values.isCreditHold}
                      onChange={validation.handleChange}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                    />
                    <Label htmlFor="isCreditHold">Credit Hold</Label>
                  </div>

                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={12}>
                <div className="form-check">
                  <Input
                    name="isExcludeOverduePopup"
                    id="isExcludeOverduePopup"
                    checked={validation.values.isExcludeOverduePopup}
                    onChange={validation.handleChange}
                    className="form-check-input"
                    type="checkbox"
                  />
                  <Label className="form-check-label text-nowrap" htmlFor="isExcludeOverduePopup">
                    Exclude Overdue Pop-up Message on New Invoice
                  </Label>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={3} xxl={3} className="d-flex align-items-center pe-2">
                <Label htmlFor="check3pCharge" className="form-label mb-0 text-nowrap">
                  Third Party Cheque Charge :
                </Label>
              </Col>
              <Col xs={5} xxl={5}>
                <Input
                  name="check3pCharge"
                  id="check3pCharge"
                  value={validation.values.check3pCharge}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter Third Party Cheque Charge "
                  type="text"
                />
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={3} xxl={3} className="d-flex align-items-center">
                <Label htmlFor="cashBackBalance" className="form-label mb-0 text-nowrap">
                  Cash Back Balanace :
                </Label>
              </Col>
              <Col xs={5} xxl={5}>
                <Input
                  name="cashBackBalance"
                  id="cashBackBalance"
                  value={validation.values.cashBackBalance}
                  onChange={(e) => validation.setValues((prev) => ({ ...prev, cashBackBalance: e.target.value }))}
                  className="form-control"
                  placeholder="Enter Cash Back Balanace"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="3">
            {id === "new" ? (
              <Button color="primary" className="mb-1 w-100 p-0 wh-btn" outline onClick={validation.handleSubmit}>
                <span className="d-flex justify-content-center align-items-center">
                  {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                  <i className="ri-save-line" style={{ fontSize: "28px" }}></i>
                  <span className="ms-2">Save</span>
                </span>
              </Button>
            ) : (
              <Button color="primary" className="mb-1 w-100 wh-btn" outline onClick={validation.handleSubmit}>
                <span className="d-flex justify-content-center align-items-center">
                  {/* <Spinner size="sm" type="grow" className="flewh-btn#ckeditorx-shrink-0" role="status"> Loading... </Spinner> */}
                  <img src={ResetIcon} style={{ height: "32px", width: "32px" }} />
                  <span className="ms-2">Update Info</span>
                </span>
              </Button>
            )}
            <Button color="primary" className="mb-1 w-100 wh-btn" onClick={toggleSpecialPricesModal} outline>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img src={SpecialPricesIcon} style={{ height: "32px", width: "32px" }} />
                <span className="ms-2">Special Prices</span>
              </span>
            </Button>
            <Button color="primary" className="mb-1 w-100 wh-btn" outline>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img src={DiscountIcon} style={{ height: "32px", width: "32px" }} />
                <span className="ms-2">Additional Item Discount /Charge</span>
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="7">
            <div className="border border-light">
              <div className="p-2 fs-5 fw-bold bg-light border wh-item-bg">Schedule Message</div>
              <div className="p-3 d-flex">
                <div className="d-flex align-items-center mb-1 flex-grow-1">
                  <Label className="w-25 mb-0" htmlFor="scheduleFrom">
                    From :
                  </Label>
                  <Input
                    name="scheduleFrom"
                    id="scheduleFrom"
                    value={validation.values.scheduleFrom}
                    onChange={validation.handleChange}
                    className="form-control"
                    type="date"
                  />
                </div>
                <div className="d-flex align-items-center mb-1 flex-grow-1">
                  <Label className="w-25 mb-0 text-end pe-4" htmlFor="scheduleTo">
                    To :
                  </Label>
                  <Input
                    name="scheduleTo"
                    id="scheduleTo"
                    value={validation.values.scheduleTo}
                    onChange={validation.handleChange}
                    className="form-control"
                    type="date"
                  />
                </div>
              </div>
              <CKEditor
              
                editor={ClassicEditor}
                data={validation.values.scheduleMemo}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const scheduleMemo = editor.getData();
                  validation.setValues((prev) => ({ ...prev, scheduleMemo }));
                
                }}
                config={{
                  container:'#ckeditor',
        
                }}
               
              />
            </div>
          </Col>
          <Col xs="5">
            <div className="border border-dark h-100 d-flex flex-column">
              <div className="p-2 fs-5 fw-bold bg-light border wh-item-bg">
                <div className="form-check">
                  <Input
                    name="popUp"
                    id="popUp"
                    checked={validation.values.popUp}
                    onChange={validation.handleChange}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />
                  <Label className="form-check-label" for="popUp">
                    Pop-Up Message
                  </Label>
                </div>
              </div>
              <textarea
                name="popMemo"
                id="popMemo"
                value={validation.values.popMemo}
                onChange={validation.handleChange}
                className="form-control flex-1"
                style={{ resize: "none" }}
              ></textarea>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal id="showSearchModal" isOpen={showSpecialPricesModal} toggle={toggleSpecialPricesModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggleSpecialPricesModal}>
          Customer Price
        </ModalHeader>
        <ModalBody className="px-0 pt-0">
          <SpecialPrices
            getInventoryListResp={getInventoryListResp}
            getSpecialPricesResp={getSpecialPricesResp}
            dispatch={dispatch}
            generateApiUrl={generateApiUrl}
            id={id}
            toggleSpecialPricesModal={toggleSpecialPricesModal}
            deleteSpecialPriceForCustomerResp={deleteSpecialPriceForCustomerResp}
            toast={toast}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
