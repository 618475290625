import { currencySymbol } from "../constants";

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const blockInvalidCharForPhone = (e, callback) =>
  /^[0-9()\-+ ]*$/.test(e.target.value) && callback();

export const addCommasToAmount = (amount) => {
  const nums = amount.toFixed(2).split(".");
  let formattedAmount = "";
  for (let i = 0; i < nums[0].length; i++) {
    formattedAmount = nums[0][nums[0].length - 1 - i] + formattedAmount;
    if ((i + 1) % 3 === 0 && !(i === nums[0].length - 1))
      formattedAmount = "," + formattedAmount;
  }
  formattedAmount = formattedAmount + "." + (nums[1] || "00");
  return formattedAmount;
};

export const toTitleCase = (str) => {
  return str.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
};

export const formatDollarAmount = (amount) => {
  const amountValue = typeof amount === "number" ? amount : Number(amount);
  if (!isNaN(amountValue)) {
    const formattedAmount = amountValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    if (formattedAmount[0] === "-") return `(${formattedAmount.slice(1)})`;
    return formattedAmount;
  } else return "";
};

export const formatAmount = (amount) => {
  const amountValue = typeof amount === "number" ? amount : Number(amount);
  if (!isNaN(amountValue)) {
    const formattedAmount = amountValue.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
    if (formattedAmount[0] === "-") return `(${formattedAmount.slice(1)})`;
    return formattedAmount;
  } else return "";
};

export const formatAmountSymbol = () => {
  return "₹";
};

export const capitalize = (input) => {
  if (typeof input !== "string" || input.length === 0) {
    return "";
  }
  return input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

