import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import F12CloseModalListener from "../../Components/Common/F12close";

const PriceChangeModal = ({ isOpen, toggle, onCloseClick }) => {
  const [focusedButton, setFocusedButton] = useState("yes"); 

  const yesButtonRef = useRef("");
  const noButtonRef = useRef("");

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isOpen && (e.key === "ArrowRight")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "yes" ? "no" : "yes"));
      }
      if (isOpen && (e.key === "ArrowLeft")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "no" ? "yes" : "no"));
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      const buttonRef = focusedButton === 'yes' ? yesButtonRef : noButtonRef;
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      });
    }
  }, [isOpen, focusedButton, yesButtonRef, noButtonRef]);
  
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered backdrop="static" keyboard={false}>
         <F12CloseModalListener onClose={toggle} />
      <ModalHeader className="border-bottom py-2 text-danger" toggle={onCloseClick}>
       <div className="text-danger">Warning</div> 
      </ModalHeader>
       <ModalBody className="py-0 pb-2">
      <div className="py-4 fs-5">Are you sure you want to change the Cost?</div>
        <div className="d-flex gap-2 justify-content-end mt-2 mb-2">
          <button
           ref={yesButtonRef}
            type="button"
            className= {`btn w-sm  ${focusedButton === "yes" ? "btn-primary border border-danger" : "btn-light"}`}
            id="delete-record"
            onClick={toggle}
            onKeyDown={(e) => {
              if (e.key === "ArrowLeft") {
                e.preventDefault();
                setFocusedButton("no");
              }
            }}
          >
            Yes
          </button>
          <button
          ref={noButtonRef}
            type="button"
            className={`btn w-sm  ${focusedButton === "no" ? "btn-primary border border-danger" : "btn-light"}`}
            data-bs-dismiss="modal"
            onClick={onCloseClick}
            onKeyDown={(e) => {
              if (e.key === "ArrowRight") {
                e.preventDefault();
                setFocusedButton("yes");
              }
            }}
          >
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PriceChangeModal;
