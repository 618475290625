import reportsconstants from "./reportsconstants";

export const getSuppliersReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_SUPPLIERS_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeSuppliersReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_SUPPLIERS_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const getPoAnalysisReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_PO_ANALYSIS_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changePoAnalysisReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_PO_ANALYSIS_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const changeGetInventoryDetectionReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_INVENTORY_DETECTION_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const changeGetInventoryDetectionAmazonReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_INVENTORY_DETECTION_AMAZON_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const changeGetInventoryAdjustmentReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_INVENTORY_ADJUSTMENT_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const getInvPricingReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INV_PRICING_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInvPricingReportPrintAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INV_PRICING_REPORT_PRINT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeInvPricingReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_INV_PRICING_REPORT_LOADING_STATUS,
    payload: status,
  };
};


export const getInvPricingStatsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INV_PRICING_STATS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getCustomersReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_CUSTOMERS_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeCustomersReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_CUSTOMERS_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const getCustomersStatsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_CUSTOMERS_STATS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInvPricingUpdateColsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INV_PRICING_UPDATE_COLS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateInvPricingAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.UPDATE_INV_PRICING_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInvPriceCodeAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INV_PRICE_CODE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateInvPriceCodeAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.UPDATE_INV_PRICE_CODE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getCustomerRouteReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_CUSTOMER_ROUTE_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getPaymentsReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_PAYMENTS_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeGetPaymentsReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_PAYMENTS_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const getSalesHistoryReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_SALES_HISTORY_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeGetSalesHistoryReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_SALES_HISTORY_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const getSalesHighestAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_SALES_HIGHEST_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getCustomerBalanceStatementAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_CUSTOMER_BALANCE_STATEMENT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeCustomerBalanceStatementLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_CUSTOMER_BALANCE_STATEMENT_LOADING_STATUS,
    payload: status,
  };
};

export const getPurchasesReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_PURCHASES_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changePurchasesReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_PURCHASES_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const getSalesReportItemsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_SALES_REPORT_ITEMS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeSalesReportItemsLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_SALES_REPORT_ITEMS_LOADING_STATUS,
    payload: status,
  };
};

export const getInvPurchasesReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INV_PURCHASES_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInvSalesReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INV_SALES_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getReturnSalesReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_RETURN_SALES_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeGetReturnSalesReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_RETURN_SALES_REPORT_LOADING_STATUS,
    payload: status,
  };
};

export const getDriversReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_DRIVERS_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeGetDriversReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_DRIVERS_REPORT_LOADING_STATUS,
    payload: status,
  };
}

export const changeGetMsaFileReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_MSA_FILE_REPORT_LOADING_STATUS,
    payload: status,
  };
}


export const getMsaFileReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_MSA_FILE_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInventoryDetectionReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INVENTORY_DETECTION_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInventoryDetectionAmazonReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INVENTORY_DETECTION_AMAZON_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInventoryAdjustmentReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.GET_INVENTORY_ADJUSTMENT_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const uploadOrdersExcelReportAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: reportsconstants.UPLOAD_ORDERS_EXCEL_REPORT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeGetUploadOrdersExcelReportLoadingStatusAct = (status) => {
  return {
    type: reportsconstants.CHANGE_GET_UPLOAD_ORDERS_EXCEL_REPORT_LOADING_STATUS,
    payload: status,
  };
};
