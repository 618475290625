import { call, takeLatest, put, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import inventoryconstants from "./inventoryconstants";
import { changeInventoryApiLoadingStatusAct, changeGetInventoryListLoadingStatusAct, changeGetInventoryBundlesListLoadingStatusAct } from "./inventoryactions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changeInventoryApiLoadingStatusAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changeInventoryApiLoadingStatusAct(false));
    }
}

function* getInventoryListGen(action) {
    yield put(changeGetInventoryListLoadingStatusAct(true));
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_INVENTORY_LIST_SUCCESS,
        failAction:inventoryconstants.GET_INVENTORY_LIST_FAIL,
    })
    yield delay(500);
    yield put(changeGetInventoryListLoadingStatusAct(false));
}

function* getInventoryAllMasterDataGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_INVENTORY_ALL_MASTER_DATA_SUCCESS,
        failAction:inventoryconstants.GET_INVENTORY_ALL_MASTER_DATA_FAIL,
    })
}

function* createInventoryItemGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.CREATE_INVENTORY_ITEM_SUCCESS,
        failAction:inventoryconstants.CREATE_INVENTORY_ITEM_FAIL,
    })
}

function* getInventoryItemGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_INVENTORY_ITEM_SUCCESS,
        failAction:inventoryconstants.GET_INVENTORY_ITEM_FAIL,
    })
}

function* updateInventoryItemGen(action) {
    const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.UPDATE_INVENTORY_ITEM_SUCCESS,
        failAction:inventoryconstants.UPDATE_INVENTORY_ITEM_FAIL,
    })
}

function* deleteInventoryItemGen(action) {
    const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.DELETE_INVENTORY_ITEM_SUCCESS,
        failAction:inventoryconstants.DELETE_INVENTORY_ITEM_FAIL,
    })
}

function* deleteInventoryColumnValuesGen(action) {
    const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.DELETE_INVENTORY_COLUMN_VALUES_SUCCESS,
        failAction:inventoryconstants.DELETE_INVENTORY_COLUMN_VALUES_FAIL,
    })
}

function* getPurchaseOrdersGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_PURCHASE_ORDERS_SUCCESS,
        failAction:inventoryconstants.GET_PURCHASE_ORDERS_FAIL,
    })
}

function* getSuppliersOfGivenItemGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_SUPPLIERS_OF_GIVEN_ITEM_SUCCESS,
        failAction:inventoryconstants.GET_SUPPLIERS_OF_GIVEN_ITEM_FAIL,
    })
}

function* getSalesOfGivenItemGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_SALES_OF_GIVEN_ITEM_SUCCESS,
        failAction:inventoryconstants.GET_SALES_OF_GIVEN_ITEM_FAIL,
    })
}

function* getMaxInventoryNumGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_MAX_INVENTORY_NUM_SUCCESS,
        failAction:inventoryconstants.GET_MAX_INVENTORY_NUM_FAIL,
    })
}

function* createCustomerInventoryPriceGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.CREATE_CUSTOMER_INVENTORY_PRICE_SUCCESS,
        failAction:inventoryconstants.CREATE_CUSTOMER_INVENTORY_PRICE_FAIL,
    })
}

function* getAdjustmentHistoryOfItemGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_ADJUSTMENT_HISTORY_OF_ITEM_SUCCESS,
        failAction:inventoryconstants.GET_ADJUSTMENT_HISTORY_OF_ITEM_FAIL,
    })
}

function* createQtyAdjustmentGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.CREATE_QTY_ADJUSTMENT_SUCCESS,
        failAction:inventoryconstants.CREATE_QTY_ADJUSTMENT_FAIL,
    })
}

function* createMultiQtyAdjustmentGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.CREATE_MULTI_QTY_ADJUSTMENT_SUCCESS,
        failAction:inventoryconstants.CREATE_MULTI_QTY_ADJUSTMENT_FAIL,
    })
}

function* getSpecialPricesGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_SPECIAL_PRICES_SUCCESS,
        failAction:inventoryconstants.GET_SPECIAL_PRICES_FAIL,
    })
}

function* activateInventoryItemGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.ACTIVATE_INVENTORY_ITEM_SUCCESS,
        failAction:inventoryconstants.ACTIVATE_INVENTORY_ITEM_FAIL,
    })
}

//Bundles

function* getInventoryBundlesListGen(action) {
    yield put(changeGetInventoryBundlesListLoadingStatusAct(true));
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_INVENTORY_BUNDLES_LIST_SUCCESS,
        failAction:inventoryconstants.GET_INVENTORY_BUNDLES_LIST_FAIL,
    })     
    yield delay(500);  
    yield put(changeGetInventoryBundlesListLoadingStatusAct(false));
}

function* createBundlesGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.CREATE_BUNDLES_SUCCESS,
        failAction:inventoryconstants.CREATE_BUNDLES_FAIL,
    })
}


function* getBundleItemGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.GET_BUNDLE_ITEM_SUCCESS,
        failAction:inventoryconstants.GET_BUNDLE_ITEM_FAIL,
    })
}

function* updateBundleItemGen(action) {
    const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.UPDATE_BUNDLE_ITEM_SUCCESS,
        failAction:inventoryconstants.UPDATE_BUNDLE_ITEM_FAIL,
    })
}

function* getMaxBundleNoGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : inventoryconstants.GET_MAX_BUNDLE_NO_SUCCESS,
        failAction :  inventoryconstants.GET_MAX_BUNDLE_NO_FAIL,
    });
}

function* deleteBundleItemGen(action) {
    const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:inventoryconstants.DELETE_BUNDLE_ITEM_SUCCESS,
        failAction:inventoryconstants.DELETE_BUNDLE_ITEM_FAIL,
    })
}
export function* inventorySaga() {
    yield takeLatest(inventoryconstants.GET_INVENTORY_LIST_REQUEST, getInventoryListGen);
    yield takeLatest(inventoryconstants.GET_INVENTORY_ALL_MASTER_DATA_REQUEST, getInventoryAllMasterDataGen);
    yield takeLatest(inventoryconstants.CREATE_INVENTORY_ITEM_REQUEST, withLoading(createInventoryItemGen));
    yield takeLatest(inventoryconstants.GET_INVENTORY_ITEM_REQUEST, getInventoryItemGen);
    yield takeLatest(inventoryconstants.UPDATE_INVENTORY_ITEM_REQUEST, withLoading(updateInventoryItemGen));
    yield takeLatest(inventoryconstants.DELETE_INVENTORY_ITEM_REQUEST, withLoading(deleteInventoryItemGen));
    yield takeLatest(inventoryconstants.DELETE_INVENTORY_COLUMN_VALUES_REQUEST, withLoading(deleteInventoryColumnValuesGen));
    yield takeLatest(inventoryconstants.GET_PURCHASE_ORDERS_REQUEST, getPurchaseOrdersGen);
    yield takeLatest(inventoryconstants.GET_SUPPLIERS_OF_GIVEN_ITEM_REQUEST, getSuppliersOfGivenItemGen);
    yield takeLatest(inventoryconstants.GET_SALES_OF_GIVEN_ITEM_REQUEST, getSalesOfGivenItemGen);
    yield takeLatest(inventoryconstants.GET_MAX_INVENTORY_NUM_REQUEST, getMaxInventoryNumGen);
    yield takeLatest(inventoryconstants.CREATE_CUSTOMER_INVENTORY_PRICE_REQUEST, withLoading(createCustomerInventoryPriceGen));
    yield takeLatest(inventoryconstants.GET_ADJUSTMENT_HISTORY_OF_ITEM_REQUEST, getAdjustmentHistoryOfItemGen);
    yield takeLatest(inventoryconstants.CREATE_QTY_ADJUSTMENT_REQUEST, withLoading(createQtyAdjustmentGen));
    yield takeLatest(inventoryconstants.CREATE_MULTI_QTY_ADJUSTMENT_REQUEST, withLoading(createMultiQtyAdjustmentGen));
    yield takeLatest(inventoryconstants.GET_SPECIAL_PRICES_REQUEST, getSpecialPricesGen);
    yield takeLatest(inventoryconstants.ACTIVATE_INVENTORY_ITEM_REQUEST, withLoading(activateInventoryItemGen));
    
    //Bundles
    yield takeLatest(inventoryconstants.GET_INVENTORY_BUNDLES_LIST_REQUEST, getInventoryBundlesListGen);
    yield takeLatest(inventoryconstants.CREATE_BUNDLES_REQUEST, withLoading(createBundlesGen));
    yield takeLatest(inventoryconstants.GET_BUNDLE_ITEM_REQUEST, withLoading(getBundleItemGen));
    yield takeLatest(inventoryconstants.UPDATE_BUNDLE_ITEM_REQUEST, withLoading(updateBundleItemGen));
    yield takeLatest(inventoryconstants.DELETE_BUNDLE_ITEM_REQUEST, withLoading(deleteBundleItemGen));
    yield takeLatest(inventoryconstants.GET_MAX_BUNDLE_NO_REQUEST, getMaxBundleNoGen);
}
