import React, { useMemo, useState, useEffect, useRef } from "react";
import { Col, Container, Row } from 'reactstrap';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { actions, generateColumns } from "./helper";
import AddRole from "./AddRole";
import WhTableContainer from "../../../../../Components/Common/WhTables";
import ApiLoader from "../../../../../Components/Common/ApiLoader"
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { deleteRolesAct, getUserRolesAct } from "../../../../../store/usermanagement/usermanagementactions";

import Confirmation from "../../../../../Components/Common/Confirmation";

export function Roles() {
  const [tableData, setTableData] = useState([]);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const deleteRoleConfig = useRef({
    deleteMultiple:false,
    idOfRoleToBeDeleted:""
  })
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(prev => !prev);
  const dispatch = useDispatch();
  const toggleAddRoleModal = () => {
    if(showAddRoleModal) setEditModalData(null);
    setShowAddRoleModal(prev => !prev);
    
  }
  const { userManagementApiLoading, getUserRolesResp, createUserRoleResp, updateUserRoleResp, deleteRolesResp, userManagementErrorResp } = useSelector(({ userManagement}) => ({
    userManagementApiLoading: userManagement.userManagementApiLoading,
    getUserRolesResp:userManagement.getUserRolesResp,
    createUserRoleResp: userManagement.createUserRoleResp,
    updateUserRoleResp: userManagement.updateUserRoleResp,
    deleteRolesResp: userManagement.deleteRolesResp,
    userManagementErrorResp: userManagement.userManagementErrorResp
  }),shallowEqual);
  const prevApiResp = useRef({
    prevGetUserRolesResp: getUserRolesResp,
    prevCreateUserRoleResp: createUserRoleResp,
    prevUpdateUserRoleResp: updateUserRoleResp,
    prevDeleteRolesResp: deleteRolesResp,
    prevUserManagementErrorResp: userManagementErrorResp
  });
  useEffect(() => {
    const { prevGetUserRolesResp, prevCreateUserRoleResp, prevUpdateUserRoleResp, prevDeleteRolesResp, prevUserManagementErrorResp } = prevApiResp.current || {};
    if (getUserRolesResp && prevGetUserRolesResp !== getUserRolesResp) {
     setTableData(getUserRolesResp?.data?.userRoles || [])
    }
    else if (createUserRoleResp && prevCreateUserRoleResp !== createUserRoleResp) {
      toast.success("Successfully created a user role")
      toggleAddRoleModal();
      dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
    }
    else if (updateUserRoleResp && prevUpdateUserRoleResp !== updateUserRoleResp) {
      toast.success("Successfully updated a user role")
      toggleAddRoleModal();
      dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
    }
    else if (deleteRolesResp && prevDeleteRolesResp !== deleteRolesResp) {
      toast.success("Successfully deleted role")
      toggleDeleteConfirmation();
      dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
    }
    else if (userManagementErrorResp && prevUserManagementErrorResp !== userManagementErrorResp) {
      const { errors,error, message } = userManagementErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0]) || error || message);
    }
    prevApiResp.current.prevGetUserRolesResp = getUserRolesResp;
    prevApiResp.current.prevCreateUserRoleResp = createUserRoleResp;
    prevApiResp.current.prevUpdateUserRoleResp = updateUserRoleResp;
    prevApiResp.current.prevDeleteRolesResp = deleteRolesResp;
    prevApiResp.current.prevUserManagementErrorResp = userManagementErrorResp;
  }, [ createUserRoleResp, updateUserRoleResp, getUserRolesResp, deleteRolesResp, userManagementErrorResp ]);
  useEffect(() => {
    dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
  },[])

  const handleRoleDelete = () => {
    let payload = [];
    if(deleteRoleConfig.current.deleteMultiple) {
      tableData.filter((item,index) => rowSelection[index]).forEach((item,index) => payload.push(item.id));
    } else {
      payload.push(deleteRoleConfig.current.idOfRoleToBeDeleted)
    }
    dispatch(deleteRolesAct(generateApiUrl("delete_roles"),{ ids: payload }))
  }

  const columns = useMemo(() => generateColumns(toggleAddRoleModal,setEditModalData,toggleDeleteConfirmation, deleteRoleConfig), []);
  return (
    <React.Fragment>
      <div className="sale-wh-wrapper">
        <Container fluid>
          <Row className="px-4">
            <Col xs={12} className="d-flex justify-content-end align-items-end">
              <div className="d-flex justify-content-center border border-section py-2 ps-4 h-100">
                {actions(toggleAddRoleModal,toggleDeleteConfirmation, deleteRoleConfig).map((item) => {
                  return (
                    <div key={item.id} className="action-icon-container me-4 px-2 show-cursor-pointer" onClick={item.clickHandler || (() => {})}>
                      <img className="action-icon" src={item.icon} />
                      <span>{item.label}</span>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="px-0" xs={12}>
              <WhTableContainer
                columns={columns}
                data={tableData}
                customPageSize={7}
                className="custom-header-css"
                divClass="table-responsive mt-3 table-height-66vh"
                tableClass="align-middle table-bordered"
                theadClass="table-light"
                showRowSelection={true}
                setRowSelection={setRowSelection}
                loading={userManagementApiLoading}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {showAddRoleModal && <AddRole isOpen={showAddRoleModal} toggle={toggleAddRoleModal} editModalData={editModalData} dispatch={dispatch} generateApiUrl={generateApiUrl}/>}
      <Confirmation show={showDeleteConfirmation} onCloseClick={toggleDeleteConfirmation} title="Delete Role" confirmationText="Are you sure you want to delete the records?" onConfirmClick={handleRoleDelete}/>
    </React.Fragment>
  );
}
