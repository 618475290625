import { Link } from "react-router-dom";
import ClickedNumber from "../../../Components/Common/InputConvertItems/ClickedNumber";

export const bundleTypes = ["AMAZON","SHOPIFY"];

export const initialTableRowValues = {
  itemNumber: "",
  descroption: "",
  quantity: "",
};
export const inventoryItemsColumnsList = [
  {
    id: "itemNumber",
    name: "Item Number",
    cellClassName: "fw-medium",
  },
  {
    id: "description",
    name: "Item Name",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "quantityInStock",
    name: "Stock Quantity",
    cellClassName: "fw-medium",
  },
];
export function generateColumns(removeItem, fieldChangeHndlr, id,status) {
  return [
    {
      Header: "Item Num",
      accessor: "itemNumber",
      Cell: ({ row }) =>
       (status === "new" || status === "edit")  ? (
          <Link
            to={`/warehouseinventory/${row.original.itemNumber}`}
            target="_blank"
          >
            {row.original.itemNumber}
          </Link>
        ) : (
          row.original.itemNumber
        ),
      style: { width: "30%" },
    },
    {
      Header: "Item Name",
      accessor: "description",
      // Cell:({cell:{value}}) => value || "-",
      Cell: ({ row }) => (id !== "new" ? row.original.description : ""),
      style: { width: "40%" },
    },
    {
      Header: <div style={{ textAlign: "center" }}>Quantity</div>,
      accessor: "quantity",
      Cell: ({ row }) => {
        const innerDivStyle = {
          height: "25px",
          marginTop: ".25em",
        };
        return ( (status === "new" || status === "edit") ? (<ClickedNumber
          value={row.original.quantity}
          itemKey="quantity"
          fieldChangeHndlr={fieldChangeHndlr}
          index={row.index}
          spanClassName={"justify-content-center"}
          containerClassName="quantity-wrapper"
          addObserver={true}
          innerDivStyle={innerDivStyle}
        />):(
          <div className="text-center">{row.original.quantity}</div>
        )
        );
      },
      style: { width: "30%" },
    },
    {
      Header: <div style={{ textAlign: "center" }}>Action</div>,
      accessor: "action",
      Cell: ({ row }) => (
        <i
          className={`ri-delete-bin-line text-danger fs-5 ${
            status === "edit" || status === "new" ? "show-cursor-pointer":"show-cursor-not-allowed opacity-50"  
          }`}
          onClick={status === "edit" || status === "new" ? () => removeItem(row.original.itemNumber):() => {}}
          // onClick={() => removeItem(row.original.itemNumber)}
        ></i>
      ),
      style: { width: "5%", textAlign: "center", cursor: "pointer" },
    },
  ];
}

export const generatePayload = (tableData, title,asin,filters) => {
  if (!Array.isArray(tableData)) {
    console.error("tableData is not an array:", tableData);
    return null;
  }
  const payload = {
    type: filters.typeOfBundle,
    title: title,
  };
  if(asin){
    payload.asin = asin;
  }
  const items = [];
  tableData.forEach((item) => {
    if (item && typeof item === "object" && "itemNumber" in item) {
      items.push({
        lineNumber: item.lineNumber || null,
        itemNumber: item.itemNumber,
        description: item.description,
        quantity: item.quantity,
      });
    }
  });
  payload.items = items;
  return payload;
};

export const mapBundleDetails = (
  apiResp, 
  setTableData, 
  setTitle,
  getAllItemNumbers,
  setBundleSkuNo,
  setStatus,
  setAsin,
  setFilters
) => {
  const {
    bundle: { title, bundleItems,skuNumber, asin,type },
  } = apiResp;
  const tempItems = [];
  const itemNumbers =[];
  bundleItems.forEach((item) => {
    tempItems.push({
      lineNumber: item.lineNumber || null,
      itemNumber: item.itemNumber,
      description: item.inventory.description,
      quantity: item.quantity,
    });
    itemNumbers.push(item.itemNumber)
  });
  setTableData(tempItems);
  getAllItemNumbers(itemNumbers)
  setTitle(title);
  setBundleSkuNo(skuNumber || '');
  setStatus('open');  
  setAsin(asin || '');
  setFilters({
    typeOfBundle:type
  })
};

export const inventoryCreateBundle = {
  bundleValue: "",
  tableData: [],
};
