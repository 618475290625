import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { Container, Row, Col, Label, Button, Input } from "reactstrap";
import { useParams } from "react-router-dom";
import axios from 'axios';

import WhTableContainer from "../../../../Components/Common/WhTables";
import shopifyIcon from "../../../../assets/images/warehouse/salespage/shopify-icon.png";
import updateIcon from "../../../../assets/images/warehouse/salespage/update-icon.png";
import amazonIcon from "../../../../assets/images/warehouse/salespage/amazon-icon.png";
import excelIcon from "../../../../assets/images/warehouse/salespage/Excel-Icon.png";
import shareIcon from "../../../../assets/images/warehouse/salespage/share.png";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getInventoryAdjustmentReportAct, getInventoryDetectionReportAct,getInventoryDetectionAmazonReportAct, uploadOrdersExcelReportAct } from "../../../../store/reports/reportsactions";
import { generateApiUrl } from "../../../../api/apihelper";
import { toast } from "react-toastify";
import ApiLoader from "../../../../Components/Common/ApiLoader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getLocalStorage, getSessionStorage, removeLocalStorage, setLocalStorage, setSessionStorage } from "../../../../utility/storageutility";
import { getZohoInvAct } from "../../../../store/zoho/zohoactions";
import { generateColumns } from "./helper";

const baseURL = process.env.REACT_APP_TEST_API_ENDPOINT;
const redirect_uri = process.env.REACT_APP_ZOHO_REDIRECT_URI;
const client_id = process.env.REACT_APP_ZOHO_CLIENT_ID;

export function InventoryDetectionTab() {
  const dispatch = useDispatch();
  
  const [tableData,setTableData] = useState([])
  const [showZohoBtn,setShowZohoBtn] = useState(false);
  const isDownloadBtnClicked = useRef(false);
  const [isShopifyBtn,setIsShopifyBtn] = useState(false) ;
  const [fileDetails,setFileDetails] = useState({
    fileName:"",
    fileType:"",
    error:"",
  })
  const { getInventoryDetectionReportResp,getInventoryDetectionAmazonReportResp,getInventoryDetectionAmazonReportLoading,getInventoryDetectionReportLoading,getUploadOrdersExcelReportResp, getInventoryAdjustmentReportResp,
    // getInventoryAdjustmentReportLoading,
    getUploadOrdersExcelReportLoading,getZohoInReportResp,getZohoInReportLoading, getZohoInvResp,getZohoInvLoading, reportsErrorResp } = useSelector(
    ({ zoho,reports }) => ({
      getInventoryDetectionReportResp: reports.getInventoryDetectionReportResp,
      getInventoryDetectionReportLoading: reports.getInventoryDetectionReportLoading,
      getInventoryDetectionAmazonReportResp: reports.getInventoryDetectionAmazonReportResp,
      getInventoryDetectionAmazonReportLoading: reports.getInventoryDetectionAmazonReportLoading,
      getInventoryAdjustmentReportResp: reports.getInventoryAdjustmentReportResp,
      // getInventoryAdjustmentReportLoading: reports.getInventoryAdjustmentReportLoading,
      getUploadOrdersExcelReportResp: reports.getUploadOrdersExcelReportResp,
      getUploadOrdersExcelReportLoading:reports.getUploadOrdersExcelReportLoading,
      getZohoInReportResp:zoho.getZohoInReportResp,
      getZohoInReportLoading:zoho.getZohoInReportLoading,
      getZohoInvResp:zoho.getZohoInvResp,
      getZohoInvLoading:zoho.getZohoInvLoading,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetInventoryDetectionReportResp: getInventoryDetectionReportResp,
    prevGetInventoryDetectionAmazonReportResp: getInventoryDetectionAmazonReportResp,
    prevGetInventoryAdjustmentReportResp: getInventoryAdjustmentReportResp,
    // prevGetInventoryAdjustmentReportLoading: getInventoryAdjustmentReportLoading,
    prevGetUploadOrdersExcelReportResp: getUploadOrdersExcelReportResp,
    prevGetUploadOrdersExcelReportLoading:getUploadOrdersExcelReportLoading,
    prevGetZohoInReportResp:getZohoInReportResp,
    prevGetZohoInReportLoading:getZohoInReportLoading,
    prevGetZohoInvResp:getZohoInvResp,
    prevGetZohoInvLoading:getZohoInvLoading,
    prevReportsErrorResp: reportsErrorResp,
  });
  useEffect(() => {
    const { prevGetInventoryDetectionReportResp,prevGetInventoryDetectionAmazonReportResp, prevGetUploadOrdersExcelReportResp,prevGetZohoInReportResp,prevGetZohoInvResp, prevReportsErrorResp } =
      customerResp.current || {};
    if (
      getInventoryDetectionReportResp &&
      prevGetInventoryDetectionReportResp !== getInventoryDetectionReportResp
    ) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(
          new Blob([getInventoryDetectionReportResp])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "inventory_detection_report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    if (
      getInventoryDetectionAmazonReportResp &&
      prevGetInventoryDetectionAmazonReportResp !== getInventoryDetectionAmazonReportResp
    ) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(
          new Blob([getInventoryDetectionAmazonReportResp])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "inventory_detection_amazon_report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
    } 
    else if(getUploadOrdersExcelReportResp &&
      prevGetUploadOrdersExcelReportResp !== getUploadOrdersExcelReportResp){
        const {type, message} = getUploadOrdersExcelReportResp || 'fail';
        if(type === 'success'){
          toast.success(message || 'File uploaded successfully!');
        }

    }
    else if(getZohoInReportResp && prevGetZohoInReportResp !== getZohoInReportResp && !showZohoBtn){
      const type = getZohoInReportResp?.type;
      if(type === "success"){
        const accessToken = getZohoInReportResp?.data?.token?.access_token || '';
        const error = getZohoInReportResp?.data?.token?.error
        if(accessToken) {
          setSessionStorage('zoho-access-token',accessToken);
          setShowZohoBtn(true);
          toast.success('successfully stored zoho access token');
        }
        else if(error){
          setSessionStorage('error',error);
          setShowZohoBtn(false);
          toast.error(`${error}`)
        }
        // else {
        //   setSessionStorage('error',error);
        //   setShowZohoBtn(false);
        // }
      }
    }
    else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }

    if(getZohoInvResp && prevGetZohoInvResp !== getZohoInvResp){
      setTableData(getZohoInvResp?.data?.invoices || []);
      setShowZohoBtn(false);
      toast.success('Successfully got zoho invoices');
    }
    
    customerResp.current.prevGetInventoryDetectionReportResp = getInventoryDetectionReportResp;
    customerResp.current.prevGetInventoryDetectionAmazonReportResp = getInventoryDetectionAmazonReportResp;
    customerResp.current.prevGetUploadOrdersExcelReportResp = getUploadOrdersExcelReportResp;
    customerResp.current.prevGetZohoInReportResp = getZohoInReportResp;
    customerResp.current.prevGetZohoInvResp = getZohoInvResp;
  
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getInventoryDetectionReportResp,getInventoryDetectionAmazonReportResp, getUploadOrdersExcelReportResp,getZohoInReportResp, getZohoInvResp, reportsErrorResp]);

  const getInventoryDetectionReportApiCall = useCallback((download) => {
    let params = {};
    if (download) {
      params.download = true;
      isDownloadBtnClicked.current = true;
      dispatch(
        getInventoryDetectionReportAct(
          generateApiUrl(`get_inventory_detection_report`),
          params,
          { responseType: "blob" }
        )
      );
    } else {
      dispatch(
        getInventoryDetectionReportAct(
          generateApiUrl(`get_inventory_detection_report`),
          params
        )
      );
    }
  }, []);
  const getInventoryDetectionAmzonReportApiCall = useCallback((download) => {
    let params = {};
    if (download) {
      params.download = true;
      isDownloadBtnClicked.current = true;
      dispatch(
        getInventoryDetectionAmazonReportAct(
          generateApiUrl(`get_inventory_detection_amazon_report`),
          params,
          { responseType: "blob" }
        )
      );
    } else {
      dispatch(
        getInventoryDetectionAmazonReportAct(
          generateApiUrl(`get_inventory_detection_report`),
          params
        )
      );
    }
  }, []);

  // const getInventoryAdjustmentReportApiCall = useCallback(() => {
  //   let params = {};
  //   dispatch(
  //     getInventoryAdjustmentReportAct(
  //       generateApiUrl(`get_inventory_adjustment_report`),
  //       params
  //     )
  //   )

  // }, []);
  const getInventoryUploadExcelSheetReportApiCall = useCallback((formData) => {
    dispatch(
      uploadOrdersExcelReportAct(
        generateApiUrl(`upload_orders_excel_report`),
        formData
      )
    )

  }, []);

  const handleInventoryDetectionDownloadClick = () => {
    getInventoryDetectionReportApiCall(true);
  };

  const handleInventoryDetectionAmazonDownloadClick = () => {
    getInventoryDetectionAmzonReportApiCall(true);
  };

  // const handleInventoryAdjustmentUpdateClick = () => {
  //   getInventoryAdjustmentReportApiCall();
    
  // };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileDetails({
      file : file,
      fileName:file.name,
      fileType:file.type
    })
   
  };
 

  const handleInventoryExcelSheetClick = () => {
    if(fileDetails.file){
      if(fileDetails.fileType === 'application/vnd.ms-excel'){
        const formData = new FormData();
        formData.append("amazonCsv", fileDetails.file, "[PROXY]");
        getInventoryUploadExcelSheetReportApiCall(formData);
        if(!isShopifyBtn){
          setIsShopifyBtn(true)
        }
  
      }else{
        setFileDetails({
          error:'Please select a .xls file only'
        })
      }
      
    }else{
      setFileDetails({
        error:'Please select a file'
      })
    }
    
  }

  const handleDownloadExcelSheetClick = () => {
    if(getUploadOrdersExcelReportResp?.type === "success" && isShopifyBtn){
      const token = getLocalStorage('token')
      const fileUrl = (`${baseURL}${generateApiUrl(`get_download_orders_excel_report`)}?token=${token}`);
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = 'Orders.xls';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(()=>{
        toast.success('File downloaded successfully')
      },300)
      setIsShopifyBtn(false);
      
    }
    
  }
  // useEffect(()=>{
  //   const {
  //     prevGetUploadOrdersExcelReportLoading,
  //     // prevGetInventoryAdjustmentReportLoading
  //   } = customerResp.current || {}
  //   // if(!getUploadOrdersExcelReportLoading && prevGetUploadOrdersExcelReportLoading !== getUploadOrdersExcelReportLoading){
  //   //   setTimeout(()=>{
  //   //     toast.success("Successfully uploaded excel sheet");
  //   //   },200)
  //   // }
  //   // if(!getInventoryAdjustmentReportLoading && prevGetInventoryAdjustmentReportLoading !== getInventoryAdjustmentReportLoading){
  //   //   setTimeout(()=>{
  //   //     if(!getInventoryAdjustmentReportLoading){
  //   //       toast.success("Successfully updated inventory adjustment");
  //   //     }
  //   //   },200)
  //   // }
    
  //   customerResp.current.prevGetUploadOrdersExcelReportLoading = getUploadOrdersExcelReportLoading;
  //   customerResp.current.prevGetInventoryA = getInventoryAdjustmentReportLoading;
  // },[getUploadOrdersExcelReportLoading,getInventoryAdjustmentReportLoading])
  const getZohoInReportApiCall = useCallback((isClicked) => {
    let params = {};
    if (isClicked) {
      params.scope = "ZohoInvoice.invoices.CREATE,ZohoInvoice.invoices.READ,ZohoInvoice.invoices.UPDATE,ZohoInvoice.invoices.DELETE";
      params.state = "testing";
      params.response_type = "code";
      params.access_type = "offline";
      params.redirect_uri = redirect_uri;
      params.client_id = client_id;
      const zohoUrl = generateApiUrl(`get_zoho_in_report`,params);
      const a = document.createElement('a');
      a.href = zohoUrl;
      a.target = '_blank';
      document.body.appendChild(a);
      setLocalStorage('is-zoho-btn-clicked',true);
      a.click();
      // setSessionStorage('isZohoInvoicesOpen',true);
      document.body.removeChild(a);
    }
  }, []);
  const handleZohoInClick = () => {
    getZohoInReportApiCall(true);
  };


  // const fetchZohoModules = async () => {
  //   const url = 'https://www.zohoapis.com/crm/v3/org';
  //   const token = getLocalStorage('zoho-auth-token');

  //   // setLoading(true);
  //   // setError(null);

  //   try {
  //     const response = await axios.get(url, {
  //       headers: {
  //         'Authorization': `Zoho-oauthtoken ${token}`
  //       }
  //     });
  //     // setModules(response.data.modules); // Adjust according to actual response structure
  //   } catch (error) {
  //     // setError('Error fetching data');
  //     console.error('Error fetching data:', error);
  //   }

  //   // setLoading(false);
  // };
  const getZohoInvApiCall = useCallback((isClicked) => {
    let params = {};
    if (isClicked) {
      params.token = getSessionStorage('zoho-access-token');
      dispatch(
        getZohoInvAct(
          generateApiUrl(`get_zoho_invoices`,params)
        )
      )
      
    }
  }, []);

  // useEffect(()=>{
  //   return () => removeLocalStorage('is-zoho-btn-clicked');
  // },[])
  const columns = useMemo(() => generateColumns(), []);
  return (
    <>
    <Container fluid className=" pt-3 pb-0">
      <Row>
        <Col xs="12" className="mb-1">
          <div
            className="border ps-4 my-3 mb-1"
            style={{ position: "relative" }}
          >
            <Label
              className="text-dark fs-5" htmlFor="inventoryDeduction"
              style={{ position: "absolute", transform: "translateY(-50%)" }}
            >
              <span className=" px-1 wh-item-bg py-1">Inventory Deduction</span>
            </Label>
            <Row xs={12}>
              <Col xs="12" md="12" className="pe-5 py-2">
                <Row className="my-3 mb-1">
                  <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={handleInventoryDetectionDownloadClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={shopifyIcon}
                          style={{ height: "20px", width: "30px" }}
                        />
                        <span className="ms-2 text-nowrap">Shopify</span>
                      </span>
                    </Button>
                  </Col>
                  <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={handleInventoryDetectionAmazonDownloadClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={amazonIcon}
                          style={{ height: "20px", width: "20px" }}
                        />
                        <span className="ms-2 text-nowrap">Download</span>
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col xs="12" className="mb-1">
          <div
            className="border ps-4 my-3 mb-1"
            style={{ position: "relative" }}
          >
            <Label
              className="text-dark fs-5"
              style={{ position: "absolute", transform: "translateY(-50%)" }}
              htmlFor="inventoryAdjustment"
            >
              <span className=" px-1 wh-item-bg py-1">Inventory Adjustment</span>
            </Label>
            <Row xs={12}>
              <Col xs="12" md="12" className="pe-5 py-2">
                <Row className="my-3 mb-1">
                  <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={handleInventoryAdjustmentUpdateClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={updateIcon}
                          style={{ height: "18px", width: "18px" }}
                        />
                        <span className="ms-2 text-nowrap">Update</span>
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col xs="12" className="mb-1">
          <div
            className="border ps-4 my-3 mb-1"
            style={{ position: "relative" }}
          >
            <Label
              className="text-dark fs-5"
              style={{ position: "absolute", transform: "translateY(-50%)" }}
              htmlFor="inventoryAdjustment"
            >
              <span className=" px-1 wh-item-bg py-1">Excel sheet</span>
            </Label>
            <Row xs={12}>
              <Col xs="12" md="12" className="pe-5 py-2">
                <Row className="my-3 mb-1">
                  <Col xs="5" md="4">
                    <Col >
                      <Input className="form-control py-1 mx-2" type="file" id="formFile" onChange={handleFileChange} />
                      {fileDetails.error && <div className="py-1 mx-2 text-danger"> {fileDetails.error}</div>}
                    </Col>
                   <Col xs="5" md='6'>
                   <Button
                      color="primary"
                      className="w-100 mb-2 mt-2 wh-btn mx-2"
                      outline
                      onClick={handleInventoryExcelSheetClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                        src={excelIcon}
                          
                          style={{ height: "18px", width: "18px" }}
                        />
                        <span className="ms-2 text-nowrap">Upload</span>
                      </span>
                    </Button>
                    <div className="show-cursor-not-allowed">
                      <Button
                        color="primary"
                        className={`w-100 mb-2 mt-2 wh-btn mx-2 ${isShopifyBtn ? 'show-cursor':'show-cursor-not-allowed opacity-100'}`}
                        outline
                        onClick={handleDownloadExcelSheetClick}
                        disabled={!isShopifyBtn}
                      >
                        <span className="d-flex justify-content-center align-items-center">
                          <img
                          src={excelIcon}
                            
                            style={{ height: "18px", width: "18px" }}
                          />
                          <span className="ms-2 text-nowrap">Download</span>
                        </span>
                      </Button>
                    </div>
                   </Col> 
                
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mb-1">
          <div
            className="border ps-4 my-3 mb-1"
            style={{ position: "relative" }}
          >
            <Label
              className="text-dark fs-5"
              style={{ position: "absolute", transform: "translateY(-50%)" }}
              htmlFor="inventoryAdjustment"
            >
              <span className=" px-1 wh-item-bg py-1">Zoho</span>
            </Label>
            <Row xs={12}>
              <Col xs="12" md="12" className="pe-5 py-2">
                <Row className="my-3 mb-1">
                  <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={handleZohoInClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={shareIcon}
                          style={{ height: "18px", width: "18px" }}
                        />
                        <span className="ms-2 text-nowrap">Zoho</span>
                      </span>
                    </Button>
                  </Col>
                 {showZohoBtn && <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={() => getZohoInvApiCall(true)}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={updateIcon}
                          style={{ height: "18px", width: "18px" }}
                        />
                        <span className="ms-2 text-nowrap">GET Zoho</span>
                      </span>
                    </Button>
                  </Col>}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {tableData.length > 0 && (
        <Row className="m-0">
        <Col className="px-0" xs={12}>
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive mt-3 whbrand-table table-container "
            tableClass="align-middle"
            theadClass="table-light"
            // handlePageChange={handlePageChange}
            // pageCount={pageCount || 0}
            // customPageSize={30}
            // showNoDataComponent={true}
            // showRowSelection={true}
            // setRowSelection={setRowSelection}
            // loading={(getInventoryListLoading || !tableData) && !showSearchModal}
          />
        </Col>
    </Row>
      )}
    </Container>

    {/* <ApiLoader loading={getInventoryAdjustmentReportLoading}/> */}
    <ApiLoader loading={getInventoryDetectionAmazonReportLoading}/>
    <ApiLoader loading={getInventoryDetectionReportLoading}/>
    <ApiLoader loading={getUploadOrdersExcelReportLoading}/>
    <ApiLoader loading={getZohoInvLoading}/>
    
    
    </>
  );
}
