import * as Yup from "yup";
export const billingInfoInitialValues = {
  isTaxExempt: false,
  getSalePrices:false,
  // isOutOfState:false,
  pricingType:"",
  markupPercent: "",
  markup: "",
  discountPercent: "",
  discount: "",
  code: "",
  // dlState:"",
  paymentTerms: "",
  creditLimit: "",
  salesLimit: "",
  isCreditHold: false,
  creditCardHold: false,
  isBillToBill: false,
  isNoChecks: false,
  isExcludeOverduePopup: false,
  check3pCharge: "",
  cashBackBalance: "",
  scheduleFrom: "",
  scheduleTo: "",
  scheduleMemo:"",
  popUp:false,
  popMemo:"",
};

export const billingInfoValidationSchema = Yup.object({
    // shipToRef: Yup.string().required("Company Name is required"),
    // shippedVia: Yup.string().required("Customer Name is required"),
})

export const generateStatesData = () => [
  { id: 1, code: 'AP', name: 'Andhra Pradesh' },
  { id: 2, code: 'AR', name: 'Arunachal Pradesh' },
  { id: 3, code: 'AS', name: 'Assam' },
  { id: 4, code: 'BR', name: 'Bihar' },
  { id: 5, code: 'CG', name: 'Chhattisgarh' },
  { id: 6, code: 'GA', name: 'Goa' },
  { id: 7, code: 'GJ', name: 'Gujarat' },
  { id: 8, code: 'HR', name: 'Haryana' },
  { id: 9, code: 'HP', name: 'Himachal Pradesh' },
  { id: 10, code: 'JH', name: 'Jharkhand' },
  { id: 11, code: 'KA', name: 'Karnataka' },
  { id: 12, code: 'KL', name: 'Kerala' },
  { id: 13, code: 'MP', name: 'Madhya Pradesh' },
  { id: 14, code: 'MH', name: 'Maharashtra' },
  { id: 15, code: 'MN', name: 'Manipur' },
  { id: 16, code: 'ML', name: 'Meghalaya' },
  { id: 17, code: 'MZ', name: 'Mizoram' },
  { id: 18, code: 'NL', name: 'Nagaland' },
  { id: 19, code: 'OD', name: 'Odisha' },
  { id: 20, code: 'PB', name: 'Punjab' },
  { id: 21, code: 'RJ', name: 'Rajasthan' },
  { id: 22, code: 'SK', name: 'Sikkim' },
  { id: 23, code: 'TN', name: 'Tamil Nadu' },
  { id: 24, code: 'TS', name: 'Telangana' },
  { id: 25, code: 'TR', name: 'Tripura' },
  { id: 26, code: 'UP', name: 'Uttar Pradesh' },
  { id: 27, code: 'UK', name: 'Uttarakhand' },
  { id: 28, code: 'WB', name: 'West Bengal' }
];
