import React, { useMemo, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import AddNewEmployee from "./AddNewEmployee";
import  EmployeesList  from "./EmployeesList";
import Confirmation from "../../../../../Components/Common/Confirmation";
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { getEmployeesAct, deleteEmployeesAct } from "../../../../../store/employee/employeeactions";

function getActiveComponent(activeId) {
  switch(activeId){
      case "employees_list":
          return EmployeesList;
      case "add_new_employee":
          return AddNewEmployee;
      default:
          return null;
  }
}


export function Employees() {
  const [tableData, setTableData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [activeId, setActiveId] = useState("employees_list");
  const [editEmployeeData, setEditEmployeeData] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const deleteEmployeeConfig = useRef({
    deleteMultiple:false,
    idOfEmployeeToBeDeleted:""
  })
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(prev => !prev);
  const ActiveComponent = useMemo(() => getActiveComponent(activeId), [activeId]);
  const dispatch = useDispatch();
  const { employeeApiLoading, getEmployeesResp, createEmployeeResp, updateEmployeeResp, deleteEmployeesResp, employeeErrorResp } = useSelector(({ employee}) => ({
    employeeApiLoading: employee.employeeApiLoading,
    getEmployeesResp:employee.getEmployeesResp,
    createEmployeeResp: employee.createEmployeeResp,
    updateEmployeeResp: employee.updateEmployeeResp,
    deleteEmployeesResp: employee.deleteEmployeesResp,
    employeeErrorResp: employee.employeeErrorResp
  }),shallowEqual);
  const prevApiResp = useRef({
    prevGetEmployeesResp: getEmployeesResp,
    prevCreateEmployeeResp: createEmployeeResp,
    prevUpdateEmployeeResp: updateEmployeeResp,
    prevDeleteEmployeesResp: deleteEmployeesResp,
    prevEmployeeErrorResp: employeeErrorResp
  });
  useEffect(() => {
    const { prevGetEmployeesResp, prevCreateEmployeeResp, prevUpdateEmployeeResp, prevDeleteEmployeesResp, prevEmployeeErrorResp } = prevApiResp.current || {};
    if (getEmployeesResp && prevGetEmployeesResp !== getEmployeesResp) {
     setTableData(getEmployeesResp?.data?.employees || [])
    }
    else if (createEmployeeResp && prevCreateEmployeeResp !== createEmployeeResp) {
      toast.success("Successfully created an employee")
      dispatch(getEmployeesAct(generateApiUrl("get_employees")));
      setActiveId("employees_list");
    }
    else if (updateEmployeeResp && prevUpdateEmployeeResp !== updateEmployeeResp) {
      toast.success("Successfully updated an employee");
      dispatch(getEmployeesAct(generateApiUrl("get_employees")));
    }
    else if (deleteEmployeesResp && prevDeleteEmployeesResp !== deleteEmployeesResp) {
      toast.success("Successfully deleted employee")
      toggleDeleteConfirmation();
      dispatch(getEmployeesAct(generateApiUrl("get_employees")));
    }
    else if (employeeErrorResp && prevEmployeeErrorResp !== employeeErrorResp) {
      const { errors,error, message } = employeeErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0]?.msg) || error || message);
    }
    prevApiResp.current.prevGetEmployeesResp = getEmployeesResp;
    prevApiResp.current.prevCreateEmployeeResp = createEmployeeResp;
    prevApiResp.current.prevUpdateEmployeeResp = updateEmployeeResp;
    prevApiResp.current.prevDeleteEmployeesResp = deleteEmployeesResp;
    prevApiResp.current.prevEmployeeErrorResp = employeeErrorResp;
  }, [ createEmployeeResp, updateEmployeeResp, getEmployeesResp, deleteEmployeesResp, employeeErrorResp ]);
  useEffect(() => {
    dispatch(getEmployeesAct(generateApiUrl("get_employees")));
  },[]);
  useEffect(() => {
    if(activeId === "employees_list" && editEmployeeData) setEditEmployeeData(null);
  }, [activeId]);
  const handleEmployeeDelete = () => {
    let payload = [];
    if(deleteEmployeeConfig.current.deleteMultiple) {
      tableData.filter((item,index) => rowSelection[index]).forEach((item,index) => payload.push(item.id));
    } else {
      payload.push(deleteEmployeeConfig.current.idOfEmployeeToBeDeleted)
    }
    dispatch(deleteEmployeesAct(generateApiUrl("delete_employees"),{ ids: payload }))
  }
  return (
  <>
    <ToastContainer />
    <ActiveComponent setActiveId={setActiveId} tableData={tableData} setEditEmployeeData={setEditEmployeeData} editEmployeeData={editEmployeeData} setRowSelection={setRowSelection} toggleDeleteConfirmation={toggleDeleteConfirmation} deleteEmployeeConfig={deleteEmployeeConfig} employeeApiLoading={employeeApiLoading}/>
    <Confirmation show={showDeleteConfirmation} onCloseClick={toggleDeleteConfirmation} title="Delete Employee" confirmationText="Are you sure you want to delete the records?" onConfirmClick={handleEmployeeDelete}/>
  </>);
}