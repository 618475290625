import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Col, Container, Label, Row, Modal, ModalHeader, ModalBody, Form } from "reactstrap";
import { actions, generateColumns, customerColumnsList } from "./helper";
import WhTableContainer from "../../Components/Common/WhTables";
import AddNewItem from "./addnewitem";
import PrintItem from "./printitem";
import SearchCustomer from "./searchcustomer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../Components/Common/DeleteModal";
import SelectItem from "../../Components/Common/SelectItem";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import { getAllCustomersAct, deleteCustomerAct } from "../../store/customer/customeractions";
//HOC
import withPermission from "../../hoc/withPermission";
import F12CloseModalListener from "../../Components/Common/F12close";
import Draggable from "react-draggable";

function WarehouseCustomers({ history }) {
  const [tableData, setTableData] = useState(null);
  const [modalTableData, setModalTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [modalPageCount, setModalPageCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [modal, setModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchListOptions, setSearchListOptions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const dispatch = useDispatch();

  const isPaymentAnywhereModelOpen = useRef(false);
  const dropdownBtnClicked = useRef(false);
  const currentCutomerListPage = useRef(1);
  const callbackOnCustomerListApiResp = useRef(() => {});

  const { getAllCustomersResp, deleteCustomerResp, customerErrResp, customerApiLoading, getAllCustomersLoading } =
    useSelector(
      ({ customer }) => ({
        getAllCustomersResp: customer.getAllCustomersResp,
        deleteCustomerResp: customer.deleteCustomerResp,
        customerErrResp: customer.customerErrResp,
        customerApiLoading: customer.customerApiLoading,
        getAllCustomersLoading: customer.getAllCustomersLoading,
      }),
      shallowEqual
    );
  const {
    data: { page },
  } = getAllCustomersResp || { data: { page: 1 } };
  const columns = useMemo(() => generateColumns(Number(page)), [page]);
  function toggle() {
    setModal(!modal);
  }
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  function toggleSearchModal() {
    setShowSearchModal(!showSearchModal);
  }
  const customerResp = useRef({
    prevGetAllCustomersResp: getAllCustomersResp,
    prevDeleteCustomerResp: deleteCustomerResp,
    prevCustomerErrResp: customerErrResp,
  });
  useEffect(() => {
    if (history.location?.state?.showCreateCustomerSuccess) {
      toast.success("Successfully saved the customer info");
      history.replace();
    } else if (history.location?.state?.showDeleteCustomerSuccess) {
      toast.success("Successfully deleted customer");
      history.replace();
    }
  }, []);
  useEffect(() => {
    const { prevGetAllCustomersResp, prevDeleteCustomerResp, prevCustomerErrResp } = customerResp.current || {};
    if (getAllCustomersResp && prevGetAllCustomersResp !== getAllCustomersResp && !isPaymentAnywhereModelOpen.current) {
      const list = [];
      (getAllCustomersResp?.data?.customers || []).forEach(({ customerNumber, firstName, lastName, companyName }) => {
        list.push({
          customerNumber,
          customerName: `${firstName || ""} ${lastName || ""}`,
          companyName,
        });
      });
      if (!dropdownBtnClicked.current) {
        if (!showSearchModal) {
          setTableData(getAllCustomersResp?.data?.customers || []);
          setPageCount((getAllCustomersResp?.data?.total));
        } else {
          setModalTableData(getAllCustomersResp?.data?.customers || []);
          setModalPageCount(getAllCustomersResp?.data?.total);
        }
        setSearchListOptions(list);
      } else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getAllCustomersResp?.data?.customers?.length > 0) callbackOnCustomerListApiResp.current();
        currentCutomerListPage.current = Number(getAllCustomersResp?.data?.page) + 1;
      }
    }
    if (deleteCustomerResp && prevDeleteCustomerResp !== deleteCustomerResp) {
      toast.success("Successfully deleted the customers");
      getAllCustomersApiCall();
      toggleDeleteModal();
    }
    if (customerErrResp && prevCustomerErrResp !== customerErrResp && !isPaymentAnywhereModelOpen.current) {
      const { errors, error, message } = customerErrResp || {};
      toast.error((Array.isArray(errors) && errors[0]) || error || message);
    }
    customerResp.current.prevGetAllCustomersResp = getAllCustomersResp;
    customerResp.current.prevDeleteCustomerResp = deleteCustomerResp;
    customerResp.current.prevCustomerErrResp = customerErrResp;
  }, [getAllCustomersResp, deleteCustomerResp, customerErrResp]);
  const getAllCustomersApiCall = useCallback(
    (page, pageSize, showAllSuppliers, currentCutomerListPage) => {
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
        orderBy: "company",
        sort: "asc",
      };
      if (showAllSuppliers) {
        params = {
          page: currentCutomerListPage || 1,
          pageSize: pageSize || 50,
          orderBy: "company",
          sort: "asc",
        };
        dropdownBtnClicked.current = true;
      } else if (search) {
        params.search = search;
        dropdownBtnClicked.current = false;
      } else dropdownBtnClicked.current = false;
      dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
    },
    [search]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllCustomersApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllCustomersApiCall]);

  const handlePageChange = (page, pageSize) => {
    getAllCustomersApiCall(page, pageSize);
  };
  const handleDeleteCustomer = () => {
    const idsArray = getAllCustomersResp?.data?.customers
      .filter((item, index) => rowSelection[index])
      .map((item) => item.customerNumber);
    const payload = { ids: idsArray };
    dispatch(deleteCustomerAct(generateApiUrl("delete_customer"), payload));
  };
  function selectItemCallbck(itemDetails) {
    const { customerNumber } = itemDetails;
    history.push(`/warehousecustomers/${customerNumber}`);
  }
  const noOfRowsSelected = Object.keys(rowSelection).length;
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper">
        <Container fluid>
          <Row className="px-4">
            <Col xs={12}>
              <Row>
                <Col xl="3" md="4" xs="4" className="border-section p-3 py-0">
                  <Label htmlFor="userrole" className="form-label mb-1">
                    Search Customer
                  </Label>
                  <SelectItem
                    searchValue={search}
                    setSearchValue={setSearch}
                    listOptions={searchListOptions}
                    setSearchListOptions={setSearchListOptions}
                    columnsList={customerColumnsList}
                    placeholder="Select Customer"
                    selectedItemHandler={selectItemCallbck}
                    fieldKey="companyName"
                    dataIdKey="customerNumber"
                    showDropdownBtn={true}
                    dropdownBtnClickHndlr={(setShowOptions) => {
                      getAllCustomersApiCall(null, null, true);
                      setShowOptions(true);
                    }}
                    callbackAfterBottomReached={(fn) => {
                      getAllCustomersApiCall(null, null, true, currentCutomerListPage.current);
                      callbackOnCustomerListApiResp.current = fn;
                    }}
                    inputClassname='py-0 whbrand-bg whbrand-text-color'
                    btnClassname={'py-0 whbrand-text-color border-light'}
                    // boldText={boldText}
                  />
                </Col>
                <Col xl="5" lg="6" xs="8">
                  <div className="d-flex justify-content-center border border-section py-2 px-1 h-100 pe-3">
                    {actions(togglePrintModal, history, toggleDeleteModal, toggleSearchModal).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`action-icon-container d-flex align-items-center ${
                            item.id === "delete" && noOfRowsSelected === 0
                              ? "show-cursor-not-allowed opacity-50"
                              : "show-cursor-pointer"
                          }`}
                          onClick={item.id === "delete" && noOfRowsSelected === 0 ? () => {} : item.clickHandler}
                        >
                          {item.id === "smart_search" ? item.icon : <img className="action-icon" src={item.icon} />}
                          <span className="text-center text-nowrap">{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="px-0" xs={12}>
              <WhTableContainer
                columns={columns}
                data={tableData || []}
                className="custom-header-css"
                // divClass="table-responsive mt-3 whbrand-table whbrand-table-scroll-h table-height-72vh"
                divClass="table-responsive mt-3 whbrand-table table-container"
                tableClass="align-middle"
                theadClass="table-light"
                handlePageChange={handlePageChange}
                pageCount={pageCount || 0}
                customPageSize={30}
                showNoDataComponent={true}
                showRowSelection={true}
                setRowSelection={setRowSelection}
                loading={(getAllCustomersLoading || !tableData) && !showSearchModal}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="lg">
      <F12CloseModalListener onClose={toggle} />
        <ModalHeader className="py-2 px-3" toggle={toggle}>
          Customers Info
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <AddNewItem />
          </ModalBody>
        </Form>
      </Modal>
      <Draggable cancel=".cancledrag">
      <Modal id="showPrintModal" isOpen={showPrintModal} toggle={togglePrintModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
          Print Invoice Dialog
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0 cancledrag">
            <PrintItem />
          </ModalBody>
        </Form>
      </Modal>
      </Draggable>
      <Modal id="showSearchModal" isOpen={showSearchModal} toggle={toggleSearchModal} size="lg">
      <F12CloseModalListener onClose={toggleSearchModal} />
        <ModalHeader className="py-2 px-3" toggle={toggleSearchModal}>
          Smart Search-Customer
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <SearchCustomer
              modalTableData={modalTableData}
              getAllCustomersAct={getAllCustomersAct}
              dispatch={dispatch}
              generateApiUrl={generateApiUrl}
              modalPageCount={modalPageCount || 0}
              getAllCustomersLoading={getAllCustomersLoading}
            />
          </ModalBody>
        </Form>
      </Modal>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeleteCustomer} />
      <PaymentsAnywhere isPaymentAnywhereModelOpen={isPaymentAnywhereModelOpen} />
      <ApiLoader loading={customerApiLoading} />
    </React.Fragment>
  );
}
export default withPermission(WarehouseCustomers, "CUSTOMER_MENU");
