import React, { useState } from 'react';
import { Badge, Input, Table } from 'reactstrap';
//helper
import { formatAmount, formatAmountSymbol } from "../../../utility/commonutility";
import { getPrevBalance } from "./helper.js";
import { useMemo } from 'react';
import { useEffect } from 'react';

const InvoiceCalculationsSidebar = ({ id, status, fBalance, prevBalance, totalAmount,isOutOfState, discountValue, setDiscountValue, discount, setDiscount, handleDiscountChange, tax, setTax, handleTaxChange, freight, setFreight, handleFreightChange, permissions, other, setOther,tdStyle = '',
setSgstPercentageParent,setCgstPercentageParent, sgstPercentage, cgstPercentage,
 setIgstPercentageParent, igstPercentage, setBalanceDueValue, setInvTotalValue,setAdjustmentValue,setSubTotalValue,
 postedBalanceDue,totalPaidOnInvoice,pBalance,prevTotalInvAmt,setInvDiscount,invDiscount
}) => {
    const percentages = [3, 5, 7, 9, 10, 12, 18];
    
    // const [sgstPercentageState, setSgstPercentageState] = useState(sgstPercentage);
    // const [cgstPercentageState, setCgstPercentageState] = useState(cgstPercentage);
    // const [igstPercentageState, setIgstPercentageState] = useState(igstPercentage);
  
    const handleSgstChange = (e) => {
   const value = e.target.value;
  //  setSgstPercentageState(value);
   setSgstPercentageParent(value); 
  };
  const handleCgstChange = (e) => {
    const value = e.target.value;
    // setCgstPercentageState(value); 
        setCgstPercentageParent(value); 
    };

  const handleIgstChange = (e) => {
    const value = e.target.value;
    // setIgstPercentageState(value); 
        setIgstPercentageParent(value); 
    };
    const calculateSgstAmount = () => {
        return ((totalAmount - Number(discountValue)) * sgstPercentage) / 100;
    };

    const calculateCgstAmount = () => {
        return ((totalAmount - Number(discountValue)) * cgstPercentage) / 100;
    };

    const calculateIgstAmount = () => {
        return ((totalAmount - Number(discountValue)) * igstPercentage) / 100;
    };
    
    const sgstAmount = useMemo(()=>{
      return calculateSgstAmount();
    },[sgstPercentage,totalAmount,discountValue]);

    const cgstAmount = useMemo(()=>{
      return calculateCgstAmount();
    },[cgstPercentage,totalAmount,discountValue]);

    const igstAmount = useMemo(()=>{
      return calculateIgstAmount();
    },[igstPercentage,totalAmount,discountValue]);

    const subTotal = useMemo(()=> totalAmount + sgstAmount + cgstAmount + igstAmount,[totalAmount,sgstAmount,cgstAmount,igstAmount]);

    const invTotalAmtTemp = useMemo(()=> subTotal + Number(freight) - Number(discountValue),[subTotal,freight, discountValue]);
    const adjustment = useMemo(() => {
      const adjustValue = (Math.round(invTotalAmtTemp) - Number(invTotalAmtTemp.toFixed(2))).toFixed(2);

      return adjustValue;
    }, [invTotalAmtTemp]);
    const invoiceTotal = useMemo(()=> Math.round(invTotalAmtTemp),[invTotalAmtTemp]);
    const amountDue = (id === 'new')?invoiceTotal:((status === "posted")?Number(postedBalanceDue):(invoiceTotal - Number(prevTotalInvAmt) + (Number(postedBalanceDue) || 0)));
    useEffect(()=>{
      setBalanceDueValue(amountDue);
    },[amountDue])
    
    useEffect(()=>{
      setInvTotalValue(invoiceTotal);
    },[invoiceTotal])

    useEffect(()=>{
      setAdjustmentValue(adjustment);
    },[adjustment])

    useEffect(()=>{
      setSubTotalValue(subTotal);
    },[subTotal])
    return ( 
    <div className="h-100 position-relative overflow-hidden">
        {id !== "new" && (
        <div className="d-flex justify-content-end mb-2">
            <Badge color="primary" className="badge badge-primary fs-5">
            {status === "posted" ? "Posted" : "Open"}
            </Badge>
        </div>
        )}
        <div>
        <div className="table-responsive">
            <Table className="sales-table table-borderless mb-0 whbrand-text-color">
            <tbody>
                {/* <tr>
                <td className="fw-medium" style={{...tdStyle}}>Prev. Balance</td>
                <td colSpan={2} className="text-end " style={{...tdStyle}}>
                    {formatAmount(getPrevBalance({ fBalance, prevBalance, id, status }).toFixed(2))}
                </td>
                </tr> */}
                <tr>
                <td className="fw-medium" style={{...tdStyle}}>Sub Total</td>
                <td colSpan={2} className="text-end " style={{...tdStyle}}>
                    {formatAmount(totalAmount.toFixed(2))}
                </td>
                </tr>
                <tr>
                <td className="" style={{...tdStyle}}>Discount </td>
                <td className="" style={{...tdStyle}}>
                    <div className={`form-icon pe-0`}>
                    <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="discountValue"
                        className="border-0 py-0 pe-0 whbrand-text-color whbrand-bg"
                        style={{ height: "20px",paddingLeft:'2.3rem' }}
                        value={discountValue}
                        onChange={handleDiscountChange}
                        onBlur={() => setDiscountValue((prev) => Number(prev).toFixed(2))}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === '.' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                        disabled={status === "posted"}
                    />
                    <i style={{ fontStyle: "normal", left: "8px" }}>{formatAmountSymbol()}</i>
                    </div>
                </td>
                <td className="text-end " style={{...tdStyle}}>
                     <div className={`pe-0 d-flex flex-row align-item-center`}>
                    <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="invDiscount"
                        className="border-0 py-0 pe-0 whbrand-text-color whbrand-bg"
                        style={{ height: "20px",borderTopRightRadius:'0px',borderBottomRightRadius:"0px"}}
                        value={invDiscount}
                        onChange={handleDiscountChange}
                        onBlur={() => setInvDiscount((prev) => Number(prev).toFixed(2))}
                        disabled={status === "posted"}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === '.' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                    />
                    <i style={{ fontStyle: "normal",height: "20px"}} className='wh-input-bg'>%</i>
                    </div>
                </td>
                </tr>
                <tr>
                <td className="" style={{...tdStyle}}>Freight</td>
                <td className="text-end whbrand-border" colSpan={'2'} style={{padding:'0.2rem'}}>
                                <div className={`form-icon pe-0`}>
                                  <Input
                                   
                                    name="freight"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="border-0 py-0"
                                    style={{ height: "20px",fontWeight:600,paddingLeft:'2.3rem' }}
                                    placeholder="0.00"
                                    value={freight}
                                    onChange={handleFreightChange}
                                    onBlur={() => setFreight((prev) => Number(prev).toFixed(2))}
                                    disabled={status === "posted"}
                                    onKeyDown={(e) => {
                                        if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                          e.preventDefault();
                                        }
                                      }}
                                  />
                                  <i 
                                  style={{ fontStyle: "normal",left:'8px'  }}
                                  >{formatAmountSymbol()}</i>
                                </div>
                              </td>
                
                </tr>
                <tr>
                <td className="" style={{...tdStyle}}>SGST / CGST</td>
                <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>
                                <div className={`form-icon pe-0`}>
                                  <Input
                                    id="sgst"
                                    name="sgst"
                                    type="select"
                                    onWheel={(e) => e.target.blur()}
                                    className="border-0 py-0"
                                    style={{ height: "20px", fontWeight: 600 }}
                                    placeholder="0.00"
                                    value={sgstPercentage}
                                    onChange={handleSgstChange}
                                    disabled={status === "posted"}
                                  >
                                   <option value="0">0 %</option>
                                       {percentages.map((percentage, index) => (
                                         <option key={index} value={percentage}>{percentage}%</option>
                                       ))}
                                     </Input>
                                </div>
                </td>
                <td className="text-end whbrand-border" style={{padding:'0.2rem'}}>
                  <div className={`form-icon pe-0`}>
                  <Input
                         id="cgst"
                         name="cgst"
                         type="select"
                         onWheel={(e) => e.target.blur()}
                         className="border-0 py-0"
                         style={{ height: "20px", fontWeight: 600}}
                         placeholder="0.00"
                         value={cgstPercentage}
                         onChange={handleCgstChange}
                         disabled={status === "posted"}
                       >
                         <option value="0">0 %</option>
                         {percentages.map((percentage, index) => (
                           <option key={index} value={percentage}>{percentage}%</option>
                         ))}
                       </Input>
                  </div>
                </td>
                </tr>
                {isOutOfState && (
                  <tr>
                  <td className="" style={{...tdStyle}}>IGST</td>
                  <td className="text-end" colSpan={2} style={{padding:'0.2rem'}}>
                      <div className={`form-icon pe-0`}>
                        <Input
                          id="igst"
                          name="igst"
                          type="select"
                          onWheel={(e) => e.target.blur()}
                          className="border-0 py-0 text-start ps-3"
                          style={{ height: "20px", fontWeight: 600 }}
                          placeholder="0.00"
                          value={igstPercentage}
                          onChange={handleIgstChange}
                          disabled={status === "posted"}
                        >
                         <option className="text-start" value="0">0 %</option>
                         {percentages.map((percentage, index) => (
                           <option key={index} value={percentage}>{percentage}%</option>
                         ))}
                           </Input>
                           {/* <i style={{ fontStyle: "normal", left: "20px" }}>{formatAmountSymbol()}</i> */}
                      </div>
                  </td>
                
                </tr>
                )}
                <tr>
                <td className="fw-medium" style={{...tdStyle}}>Adjustment</td>
                <td colSpan={2} className="text-end " style={{...tdStyle}}>
                    {/* {formatAmount(
                    (totalAmount + Number(tax) + Number(freight) + Number(other) - Number(discountValue)).toFixed(2)
                    )} */}
                    {formatAmount(adjustment)}
                </td>
                </tr>
                <tr>
                <td className="fw-medium" style={{...tdStyle}}>Inv Total</td>
                <td colSpan={2} className="text-end " style={{...tdStyle}}>
                    {/* {formatAmount(
                    (totalAmount + Number(tax) + Number(freight) + Number(other) - Number(discountValue)).toFixed(2)
                    )} */}
                    {formatAmount(invoiceTotal.toFixed(2))}
                </td>
                </tr>

                <tr>
                <td className="fw-medium" style={{...tdStyle}}>Amount Due</td>
                <td colSpan={2} className="text-end " style={{...tdStyle}}>
                     {formatAmount(amountDue.toFixed(2))}
                </td>
                </tr>
            </tbody>
            </Table>
        </div>
        </div>
    </div> );
}
 
export default InvoiceCalculationsSidebar;