import React, { useRef, useEffect } from "react";
import { Col, Container, Input, Label, Row, Button, Form, FormFeedback } from "reactstrap";
import ResetIcon from "../../../assets/images/warehouse/salespage/reset-blue.png";
//Actions
import { getZipcodeAct } from "../../../store/masterdata/masterdataactions";
//Utility
import { blockInvalidCharForPhone, toTitleCase } from "../../../utility/commonutility"
import { generateStatesData } from "./basicinfohelper";
import { useMemo } from "react";

export const BasicInfo = (props) => {
  const {
    id,
    basicInfoValidation: validation,
    dropdownState: { customerType },
    states,
    mobileProviders,
    dispatch,
    generateApiUrl,
    getZipcodeResp,
  } = props;
  const apiResp = useRef({
    prevGetZipcodeResp: getZipcodeResp,
  });
  useEffect(() => {
    const { prevGetZipcodeResp } = apiResp.current || {};
    if (getZipcodeResp && prevGetZipcodeResp !== getZipcodeResp) {
      const {
        data: {
          zipcode: { cityName, countryName, stateName },
        },
      } = getZipcodeResp;
      const state = states.find((item) => item.name.toLowerCase() === stateName.toLowerCase());
      validation.setValues((prev) => ({ ...prev, city: cityName, country: countryName, state: state?.code || "" }));
    }
    apiResp.current.prevGetZipcodeResp = getZipcodeResp;
  }, [getZipcodeResp]);
  const getZipcodeApiCall = () => {
    dispatch(getZipcodeAct(generateApiUrl(`get_zipcode`, { zipcode: validation.values.zip })));
  };
  const statesData = useMemo(() => generateStatesData(), []);
  return (
    <Container fluid className="px-5 py-3 whbrand-text-color">
      <Form>
        <Row>
          <Col md="8" lg="9">
            <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="companyName" className="form-label mb-0 text-nowrap ">
                  Company Name :
                </Label>
              </Col>
              <Col xs={10}>
                <Input
                  name="companyName"
                  id="companyName"
                  className="form-control"
                  placeholder="Enter Company Name"
                  type="text"
                  value={validation.values.companyName}
                  onChange={validation.handleChange}
                  invalid={validation.touched.companyName && validation.errors.companyName ? true : false}
                />
                <FormFeedback>
                  {validation.touched.companyName && validation.errors.companyName ? validation.errors.companyName : ""}
                </FormFeedback>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="customerName" className="form-label mb-0 text-nowrap ">
                  Customer Name :
                </Label>
              </Col>
              <Col xs={2} className="">
                <Input
                  type="select"
                  name="salutation"
                  id="salutation"
                  value={validation.values.salutation}
                  onChange={validation.handleChange}
                  className="form-select"
                  invalid={validation.touched.salutation && validation.errors.salutation ? true : false}
                  style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                >
                  <option value=""></option>
                  <option>Ms</option>
                  <option>Mr</option>
                  <option>Mrs</option>
                  <option>Dr</option>
                  <option>Sir</option>
                </Input>
                <FormFeedback>
                  {" "}
                  {validation.touched.salutation && validation.errors.salutation ? validation.errors.salutation : ""}
                </FormFeedback>
              </Col>
              <Col xs={8} className="d-flex">
                <div className="flex-grow-1 me-2">
                  <Input
                    name="firstName"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter First Name"
                    type="text"
                    value={validation.values.firstName}
                    
                    onChange={validation.handleChange}
                    invalid={validation.touched.firstName && validation.errors.firstName ? true : false}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    maxLength="20"
                  />
                  <FormFeedback>
                    {" "}
                    {validation.touched.firstName && validation.errors.firstName ? validation.errors.firstName : ""}
                  </FormFeedback>
                </div>
                <div className="flex-grow-1 ms-2">
                  <Input
                    name="lastName"
                    id="lastName"
                    className="form-control"
                    placeholder="Enter Last Name"
                    type="text"
                    value={validation.values.lastName}
                    onChange={validation.handleChange}
                    invalid={validation.touched.lastName && validation.errors.lastName ? true : false}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    maxLength="20"
                  />
                  <FormFeedback>
                    {" "}
                    {validation.touched.lastName && validation.errors.lastName ? validation.errors.lastName : ""}
                  </FormFeedback>
                </div>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="customerType" className="form-label mb-0 text-nowrap ">
                  Customer Type :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  type="select"
                  className="form-select"
                  name="customerType"
                  id="customerType"
                  value={validation.values.customerType}
                  onChange={validation.handleChange}
                  invalid={validation.touched.customerType && validation.errors.customerType ? true : false}
                >
                  <option value=""></option>
                  {customerType.map((item) => (
                    <option key={item}>{item}</option>
                  ))}
                </Input>
                <FormFeedback>
                  {" "}
                  {validation.touched.customerType && validation.errors.customerType
                    ? validation.errors.customerType
                    : ""}
                </FormFeedback>
              </Col>
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="phone" className="form-label mb-0 text-nowrap ps-5">
                  Phone No. :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="phone"
                  id="phone"
                  value={validation.values.phone}
                  onChange={(e) => blockInvalidCharForPhone(e , () => validation.setValues((prev) => ({ ...prev, phone: e.target.value })))}
                  className="form-control"
                  placeholder="Enter Phone No"
                  type="text"
                  invalid={validation.touched.phone && validation.errors.phone ? true : false}
                  maxLength={15}
                />
                <FormFeedback>
                  {" "}
                  {validation.touched.phone && validation.errors.phone ? validation.errors.phone : ""}
                </FormFeedback>
              </Col>
            </Row>
            {/* <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="fax" className="form-label mb-0 text-nowrap ps-5">
                  Fax :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="fax"
                  id="fax"
                  value={validation.values.fax}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter Fax"
                  type="text"
                />
              </Col>
            </Row> */}
            <Row className="mb-1">
              
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="email" className="form-label mb-0 text-nowrap">
                  Email Address :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="email"
                  id="email"
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  invalid={validation.touched.email && validation.errors.email ? true : false}
                />
                <FormFeedback>
                  {" "}
                  {validation.touched.email && validation.errors.email ? validation.errors.email : ""}
                </FormFeedback>
              </Col>
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="phone" className="form-label mb-0 text-nowrap ps-5">
                  {validation.values.isOutOfState?'IGST':'GSTIN'} :
                </Label>
              </Col>
              <Col xs={4}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter GST number"
                    id="gstNumber"
                    name="gstNumber"
                    value={validation.values.gstNumber}
                    onChange={validation.handleChange}
                    invalid={validation.touched.gstNumber && validation.errors.gstNumber ? true : false}
                  />
                  <FormFeedback>
                    {validation.touched.gstNumber && validation.errors.gstNumber ? validation.errors.gstNumber : ""}
                  </FormFeedback>
              </Col>
              
            </Row>
            <Row className="mb-2 mt-2">
              <Col xs={4} className="d-flex align-items-center mb-2">
                <Label htmlFor="flatNumber" className="form-label mb-0 text-nowrap">
                Flat, House no., Building, Company, Apartment :
                </Label>
              </Col>
              <Col xs={8} className={'mb-2'}>
                <div className="d-flex">
                  <div className="flex-grow-1 me-2">
                    <Input
                        type="text"
                        className="form-control"
                        id="flatNumber"
                        name="flatNumber"
                        placeholder="Enter Flat, House no., Building, Company, Apartment"
                        value={validation.values.flatNumber}
                        onChange={validation.handleChange}
                        invalid={validation.touched.flatNumber && validation.errors.flatNumber ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.flatNumber && validation.errors.flatNumber ? validation.errors.flatNumber : ""}
                      </FormFeedback>
                  </div>
                  <i className="ri-map-pin-line fs-5 mt-0" style={{color:"#ffcc16"}}></i>
                </div>
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                Area, Street, Sector, Village :
                </Label>
              </Col>
              <Col xs={8}>
                <div className="d-flex">
                  <div className="flex-grow-1 me-2">
                    <Input
                      name="street"
                      id="street"
                      value={validation.values.street}
                      onChange={validation.handleChange}
                      className="form-control"
                      placeholder="Enter Area, Street, Sector, Village"
                      type="text"
                      invalid={validation.touched.street && validation.errors.street ? true : false}
                    />
                    <FormFeedback>
                      {validation.touched.street && validation.errors.street ? validation.errors.street : ""}
                    </FormFeedback>
                  </div>
                  <i className="ri-map-pin-line fs-5 mt-0" style={{color:"#ffcc16"}}></i>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="zip" className="form-label mb-0 text-nowrap">
                  Pin code :
                </Label>
              </Col>
              <Col xs={4} className="d-flex align-items-start">
                <div className="flex-grow-1 me-2">
                  <Input
                    name="zip"
                    id="zip"
                    value={validation.values.zip}
                    onChange={validation.handleChange}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        getZipcodeApiCall();
                      } else if( e.key === "ArrowUp" || e.key === "ArrowDown"){
                        e.preventDefault();
                      }
                    }}
                    className="form-control"
                    placeholder="Enter zip"
                    type="number"
                    invalid={validation.touched.zip && validation.errors.zip ? true : false}
                  />
                  <FormFeedback>
                    {" "}
                    {validation.touched.zip && validation.errors.zip ? validation.errors.zip : ""}
                  </FormFeedback>
                </div>
                {/* <Button color="primary" onClick={getZipcodeApiCall}>
                  <i className="ri-check-line"></i>
                </Button> */}
              </Col>
              <Col xs={2} className="d-flex align-items-center">
              <Label for="landmark" className="form-label mb-0 text-nowrap ps-5">
                      Landmark :
                      </Label>
              </Col>
              <Col xs={4}>
                    <Input
                        type="text"
                        className="form-control"
                        id="landmark"
                        name="landmark"
                        value={validation.values.landmark}
                        onChange={validation.handleChange}
                        invalid={validation.touched.landmark && validation.errors.landmark ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.landmark && validation.errors.street ? validation.errors.landmark : ""}
                      </FormFeedback>
              </Col>
              
            </Row>

            <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="city" className="form-label mb-0 text-nowrap">
                  Town/City :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="city"
                  id="city"
                  value={validation.values.city}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter city"
                  type="text"
                  invalid={validation.touched.city && validation.errors.city ? true : false}
                  maxLength="20"
                />
                <FormFeedback>
                  {" "}
                  {validation.touched.city && validation.errors.city ? validation.errors.city : ""}
                </FormFeedback>
              </Col>
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="state" className="form-label mb-0 text-nowrap ps-5 required">
                  State :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  type="select"
                  className="form-select"
                  name="state"
                  id="state"
                  value={validation.values.state}
                  onChange={validation.handleChange}
                  invalid={validation.touched.state && validation.errors.state ? true : false}
                >
          
                  <option value="">Select</option>
                        {statesData.map((item) => (
                          <option key={item.id} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                </Input>
                <FormFeedback>
                  {" "}
                  {validation.touched.state && validation.errors.state ? validation.errors.state : ""}
                </FormFeedback>
              </Col>
              {/* <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="country" className="form-label mb-0 text-nowrap ps-5 ">
                  Country :
                </Label>
              </Col> */}
              {/* <Col xs={4}>
                <Input
                  name="country"
                  id="country"
                  value={validation.values.country}
                  onChange={e => {
                    const {value} = e.target;
                    // var letters = /^[A-Za-z]+$/;
                    var letters = /^[A-Za-z\s.'-]+$/;
                    if(value.match(letters) || value==="") validation.handleChange(e);
                  }}
                  className="form-control"
                  placeholder="Enter country"
                  type="text"
                  invalid={validation.touched.country && validation.errors.country ? true : false}
                  maxLength="20"
                />
                <FormFeedback>
                  {" "}
                  {validation.touched.country && validation.errors.country ? validation.errors.country : ""}
                </FormFeedback>
              </Col> */}
            </Row>

            {/* <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="isCheckAddress" className="form-label mb-0 text-nowrap">
                  Check Address :
                </Label>
              </Col>
              <Col xs={4}>
                <div className="form-check form-switch form-switch-lg form-switch-success">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    name="isCheckAddress"
                    id="isCheckAddress"
                    checked={validation.values.isCheckAddress}
                    onChange={validation.handleChange}
                  />
                </div>
              </Col>
            </Row> */}

            <Row className="mb-1">
              {/* <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="taxId" className="form-label mb-0 text-nowrap ">
                  Tax Id(FEIN) :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="taxId"
                  id="taxId"
                  value={validation.values.taxId}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter TaxId"
                  type="text"
                  invalid={validation.touched.taxId && validation.errors.taxId ? true : false}
                />
                <FormFeedback>
                  {" "}
                  {validation.touched.taxId && validation.errors.taxId ? validation.errors.taxId : ""}
                </FormFeedback>
              </Col> */}
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="stateTaxId" className="form-label mb-0 text-nowrap">
                  State Id :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="stateTaxId"
                  id="stateTaxId"
                  value={validation.values.stateTaxId}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter State"
                  type="text"
                  invalid={validation.touched.stateTaxId && validation.errors.stateTaxId ? true : false}
                />
                <FormFeedback>
                  {validation.touched.stateTaxId && validation.errors.stateTaxId ? validation.errors.stateTaxId : ""}
                </FormFeedback>
              </Col>
            </Row>

            {/* <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="vendor" className="form-label mb-0 text-nowrap ps-5">
                  Vendor :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="vendor"
                  id="vendor"
                  value={validation.values.vendor}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter Vendor"
                  type="text"
                />
              </Col>
            </Row> */}

            {/* <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="dea" className="form-label mb-0 text-nowrap ps-5">
                  DEA :
                </Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="dea"
                  id="dea"
                  value={validation.values.dea}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter DEA"
                  type="text"
                />
              </Col>
            </Row> */}

            <Row className="mb-1">
              <Col xs={2} className="d-flex align-items-center">
                <Label htmlFor="invMemo" className="form-label mb-0 text-nowrap">
                  Memo:
                </Label>
              </Col>
              <Col xs={10}>
                <textarea
                  name="invMemo"
                  id="invMemo"
                  value={validation.values.invMemo}
                  onChange={validation.handleChange}
                  className="form-control"
                  rows="3"
                  style={{ resize: "none" }}
                />
              </Col>
            </Row>
          </Col>
          <Col md="4" lg="3">
            <div className="d-flex align-items-center mb-1">
              <Label htmlFor="isActive" className="form-label mb-0 text-nowrap fs-5 fw-bold">
                Customer Status:
              </Label>
              <div className="form-check form-switch form-switch-lg form-switch-success px-5 ms-2 mb-0 d-flex align-items-center">
                <Input
                  name="isActive"
                  id="isActive"
                  checked={validation.values.isActive}
                  onChange={validation.handleChange}
                  className="form-check-input mb-1 me-2"
                  type="checkbox"
                  role="switch"
                />
                <span className={`fs-5 fw-bold px-2 py-1 rounded text-light ${validation.values.isActive ? "bg-success" : "bg-danger"}`}>
                      {validation.values.isActive ? "Active" : "Inactive"}
                </span>
              </div>
            </div>
            {id === "new" ? (
              <Button color="primary" className="mb-2 w-100 p-0 wh-btn" outline onClick={validation.handleSubmit}>
                <span className="d-flex justify-content-center align-items-center">
                  <span className="me-2" style={{ fontSize: "20px" }}>
                    Next
                  </span>
                  <i className="ri-arrow-right-line" style={{ fontSize: "32px" }}></i>
                </span>
              </Button>
            ) : (
              <Button color="primary" className="mb-2 w-100 wh-btn" outline onClick={validation.handleSubmit}>
                <span className="d-flex justify-content-center align-items-center">
                  <img src={ResetIcon} style={{ height: "32px", width: "32px" }} />
                  <span className="ms-2">Update Info</span>
                </span>
              </Button>
            )}
            <Col xs={2} className="d-flex align-items-center">
                  <div className="form-check ms-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      name="isOutOfState"
                      id="isOutOfState"
                      checked={validation.values.isOutOfState}
                      onChange={validation.handleChange}
                    />
                    <Label className="form-check-label text-nowrap" htmlFor="isOutOfState">
                      Out of State Customer
                    </Label>
                  </div>
                </Col>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
