import React, { useMemo, useEffect, useState, useRef } from "react";
import {
  Col,
  Container,
  // Input,
  // Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  // Form,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";
import Draggable from "react-draggable";

import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns, generateInvoiceColumns, generateModalColumns } from "./helper";
//Actions
import { getInvoicesOfCustomerAct, getPaymentsOfCustomerAct } from "../../../../store/customer/customeractions";
import F12CloseModalListener from "../../../../Components/Common/F12close";

export const PaymentsTab = ({ history, dispatch, generateApiUrl, id, useSelector, shallowEqual }) => {
  const [tableData, setTableData] = useState([

  ]);

  const [modalTableData,setModaltableData] = useState([
  ]); 
  const [showModal,setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(prev => !prev)
  }
  // const { getPaymentsOfCustomerResp } = useSelector(
  //   ({ customer }) => ({
  //     getPaymentsOfCustomerResp: customer.getPaymentsOfCustomerResp,
  //   }),
  //   shallowEqual
  // );
  // const apiResp = useRef({
  //   prevGetPaymentsOfCustomerResp: getPaymentsOfCustomerResp,
  // });
  // useEffect(() => {
  //   const { prevGetPaymentsOfCustomerResp } = apiResp.current;
  //   if (getPaymentsOfCustomerResp && prevGetPaymentsOfCustomerResp !== getPaymentsOfCustomerResp) {
  //     setTableData(getPaymentsOfCustomerResp.data.payments);
  //   }
  //   apiResp.current.prevGetPaymentsOfCustomerResp = getPaymentsOfCustomerResp;
  // }, [getPaymentsOfCustomerResp]);
  const { getInvoicesOfCustomerResp } = useSelector(
    ({ customer }) => ({
      getInvoicesOfCustomerResp: customer.getInvoicesOfCustomerResp,
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetInvoicesOfCustomerResp: getInvoicesOfCustomerResp,
  });
  useEffect(() => {
    const { prevGetInvoicesOfCustomerResp } = apiResp.current;
    if (getInvoicesOfCustomerResp && prevGetInvoicesOfCustomerResp !== getInvoicesOfCustomerResp) {
      setTableData(getInvoicesOfCustomerResp.data.invoices);
    }
    apiResp.current.prevGetInvoicesOfCustomerResp = getInvoicesOfCustomerResp;
  }, [getInvoicesOfCustomerResp]);

  useEffect(() => {
    if (id && id !== "new")
      dispatch(
        getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
          customerNumber: id,
          posted:true,
          page: 1,
          pageSize: 10,
          orderBy:"invoiceDate",
          sort:"desc"
        })
      );
  }, []);
  const handlePageChange = (page, pageSize) => {
    dispatch(
      getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
        customerNumber: id,
        page: page + 1,
        pageSize,
        posted:true,
        orderBy:"invoiceDate",
        sort:"desc"
      })
    );
  };

  const modalColumns = useMemo(() => generateModalColumns(history), []);
  const onClickPaymentHistoryHndlr = (payments) => {
    setModaltableData(payments);
    toggleModal();
  } 
  const columns = useMemo(() => generateInvoiceColumns(history,toggleModal,onClickPaymentHistoryHndlr), []);
  return (
    <React.Fragment>
      <Container fluid className="px-4">
        <Row className="m-0">
          {/* <Col xs="12" className="d-flex justify-content-end">
              <div>
                <select className="form-select">
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                </select>
              </div>
            </Col> */}
          <Col className="px-0" xs={12}>
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive mt-3 whbrand-table"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={Math.ceil((getInvoicesOfCustomerResp?.data?.total || 0) / 10)}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
      </Container>
      <Draggable cancel=".cancledrag">
      <Modal
        id="showPaymentHistory"
        isOpen={showModal}
        toggle={toggleModal}
        centered
        size="lg"
      >
        <F12CloseModalListener onClose={toggleModal} />
        <ModalHeader className="py-2 px-3 border-bottom" toggle={toggleModal}>
          Payments History
        </ModalHeader>
        <ModalBody className="px-0 pt-0 cancledrag">
            <WhTableContainer
              columns={modalColumns}
              data={modalTableData}
              // customPageSize={7}
              className="custom-header-css"
              divClass="table-responsive"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              // handlePageChange={handlePageChange}
              // pageCount={Math.ceil((getAdjustmentHistoryOfItemResp?.data?.total || 0) / 10)}
              // customPageSize={30}
              showNoDataComponent={true}
            />
        </ModalBody>
      </Modal>
      </Draggable>
    </React.Fragment>
  );
};
