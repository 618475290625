import React from "react";
import { Button } from "reactstrap";

const InvoiceStatusInfo = ({ id, status, getMaxInvoiceNoResp, paid, totalAmount, tax, freight, discountValue, totalPaidOnInvoice, toggleOpenInvoiceConfirm, permissions, invTotal }) => {
  return (
    <>
      <div
        className="fs-4 fw-bold d-flex flex-column align-items-center justify-content-center px-2 py-2 wh-item-bg"
        
      >
        <span className="text-nowrap">Invoice Number</span>
        <span>{id !== "new" ? id : getMaxInvoiceNoResp?.data?.invoiceNumber || ""}</span>
      </div>
      <div className="d-flex flex-row justify-content-center">
      {status === "posted" && (
        <>
          {id !== "new" && paid && invTotal > 0 && (
            <span className="badge badge-outline-success text-light bg-success fs-4 flex-grow-0 mt-4">Paid</span>
          )}
          {id !== "new" && paid && invTotal <= 0 && (
            <span className="badge badge-outline-success text-light bg-success fs-4 flex-grow-0 mt-4">Credit</span>
          )}
          {id !== "new" &&
            !paid &&
            totalPaidOnInvoice > 0 &&
            invTotal > 0 && (
              <span className="badge badge-outline-warning text-light bg-warning fs-4 flex-grow-0 mt-4">Charge</span>
            )}
          {id !== "new" &&
            !paid &&
            totalPaidOnInvoice === 0 &&
            invTotal > 0 && (
              <span className="badge badge-outline-danger text-light bg-danger fs-4 flex-grow-0 mt-4">Unpaid</span>
            )}
        </>
      )}
      <div className={`flex-grow-1 d-flex align-items-start pt-4 ms-2`}>
        {id !== "new" && status === "posted" && (
          <div
            className={`btn btn-outline-primary flex-grow-1 d-flex align-items-center me-2 ${!permissions["OPEN_POSTED_INVOICE"] ? "show-cursor-not-allowed" : "show-cursor-pointer"} wh-btn`}
            onClick={permissions["OPEN_POSTED_INVOICE"] ? toggleOpenInvoiceConfirm : () => {}}
            disabled={!permissions["OPEN_POSTED_INVOICE"]}
          >
            Open
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default InvoiceStatusInfo;
