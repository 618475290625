import React, { useEffect, useState, useRef, Fragment } from "react";
import { Button, Row, Col, Container, Label, Input, FormFeedback, Form } from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import SaveIcon from "../../../../../assets/images/warehouse/salespage/save.png";
import DummyAvatar from "../../../../../assets/images/warehouse/salespage/dummy-avatar.jpg";
import { addEmployeeInitialValues, getAddEmployeeValidationSchema } from "./helper";
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { getUserRolesAct } from "../../../../../store/usermanagement/usermanagementactions";
import { createEmployeeAct, updateEmployeeAct } from "../../../../../store/employee/employeeactions";
import { getDepartmentsAct } from "../../../../../store/department/departmentactions";
import { getDesignationsAct } from "../../../../../store/designation/designationactions";
import { getZipcodeAct, getStatesListAct } from "../../../../../store/masterdata/masterdataactions";
//Utility
import { blockInvalidCharForPhone } from "../../../../../utility/commonutility";
import { getYyyymmddDateFormatted } from "../../../../../utility/dateutility";
import SearchableDropdown from "../../../../../Components/Common/SearchableDropdown";
import AddNewOptionModal from "./AddNewOptionModal";
import ApiLoader from "../../../../../Components/Common/ApiLoader";

function getInitialValues(editEmployeeData) {
  if (editEmployeeData) {
    const {
      name,
      department,
      designation,
      homePhone,
      email,
      birthDate,
      gender,
      hireDate,
      salary,
      user,
      password,
      street,
      city,
      zip,
      suit,
      state,
      county,
      active
    } = editEmployeeData;
    return {
      image: "",
      name: name || "",
      department: department || "",
      designation: designation || "",
      roleId: user?.role?.id || "",
      homePhone: homePhone || "",
      email: email || "",
      birthDate: birthDate ? getYyyymmddDateFormatted(birthDate) : "",
      gender: gender || "",
      hireDate: hireDate ? getYyyymmddDateFormatted(hireDate) : "",
      salary: salary || "",
      username: user?.username || "",
      password: password || user?.password || "",
      street: street || "",
      city: city || "",
      zip: zip || "",
      suit: suit || "",
      state: state || "",
      county: county || "",
      active,
    };
  } else return addEmployeeInitialValues;
}

const AddNewEmployee = ({ setActiveId, editEmployeeData }) => {
  const [previewUrl, setPreviewUrl] = useState(editEmployeeData?.image || null);
  const [dropdown,setDropdown] = useState({
    departments:[],
    designations: [],
    roles:[],
  })
 const [roleValue,setRoleValue] = useState("");
 const [departmentValue, setDepartmentValue] = useState("");
 const [designationValue, setDesignationValue] = useState("");
 const [showDesignationModal,setShowDesignationModal] = useState(false);
 const [showDepartmentModal, setShowDepartmentModal] = useState(false);
 const [modalConfig, setModalConfig] = useState({
    isDesignationModalOpen: false,
    isDepartmentModalOpen: false,
 });
 const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  // toggle 
  const togglePassword = () => setShowPassword(prev => !prev);
  //modals
  const toggleDesignationModal = () => {
    setShowDesignationModal(prev => !prev);
  }
  const toggleDepartmentModal = () => {
    setShowDepartmentModal(prev => !prev);
  }

  const toggleAddNewOptionModal = (type) => {
    const modalKey = `is${type.charAt(0).toUpperCase() + type.slice(1)}ModalOpen`;
  
  if (modalKey in modalConfig) {
    setModalConfig((prev) => ({
      ...prev,
      [modalKey]: !prev[modalKey],
    }));
  } else {
    toast.error(`Unhandled Type: ${type}`);
  }

    // switch (type) {
    //   case "designation":
    //     setShowDesignationModal(prev => !prev);
    //     break;
    //   case "department":
    //     setShowDepartmentModal(prev => !prev);
    //     break;
  
    //   default:
    //     toast.error(`Unhandled Type: ${type}`);
    // }
  }
  useEffect(()=>{
    if(editEmployeeData){
      const {
        department,
        designation,
        user
      } = editEmployeeData;
      setDepartmentValue(department?.name || "");
      setDesignationValue(designation?.name || "");
      setRoleValue(user?.role?.name || "")
    }
  },[editEmployeeData])
  const addEmployeeValidation = useFormik({
    initialValues: getInitialValues(editEmployeeData),
    validationSchema: getAddEmployeeValidationSchema(!!editEmployeeData),
    onSubmit: (values) => {
      const formData = new FormData();
      for (let i in values) {
        if(values[i] || typeof values[i] === "boolean") formData.append(i, values[i]);
      }
      if(!editEmployeeData) dispatch(createEmployeeAct(generateApiUrl("create_employee"), formData));
      else dispatch(updateEmployeeAct(generateApiUrl("update_employee", {id:editEmployeeData.id}), formData));
    },
  });
  const {
    employeeApiLoading,
    getUserRolesResp,
    getDepartmentsResp,
    getDesignationsResp,
    getZipcodeResp,
    getStatesListResp,
    createEmployeeResp,
    updateEmployeeResp,
    employeeErrorResp,
    masterDataErrorResp,
  } = useSelector(
    ({ employee, userManagement, department, designation, masterData }) => ({
      employeeApiLoading: employee.employeeApiLoading,
      getUserRolesResp: userManagement.getUserRolesResp,
      getDepartmentsResp: department.getDepartmentsResp,
      getDesignationsResp: designation.getDesignationsResp,
      getZipcodeResp: masterData.getZipcodeResp,
      getStatesListResp: masterData.getStatesListResp,
      createEmployeeResp: employee.createEmployeeResp,
      updateEmployeeResp: employee.updateEmployeeResp,
      employeeErrorResp: employee.employeeErrorResp,
      masterDataErrorResp: masterData.masterDataErrorResp,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
    dispatch(getDepartmentsAct(generateApiUrl("get_departments")));
    dispatch(getDesignationsAct(generateApiUrl("get_designations")));
    dispatch(getStatesListAct(generateApiUrl(`get_states_list`)));
  }, []);
  const apiResp = useRef({
    prevGetZipcodeResp: getZipcodeResp,
    prevMasterDataErrorResp: masterDataErrorResp,
    prevGetUserRolesResp: getUserRolesResp,
    prevGetDepartmentsResp: getDepartmentsResp,
    prevGetDesignationsResp: getDesignationsResp
  });
  useEffect(() => {
    const {
      prevGetZipcodeResp,
      prevGetUserRolesResp,
      prevMasterDataErrorResp,
      prevGetDepartmentsResp,
      prevGetDesignationsResp
    } = apiResp.current || {};
  
    // Handling getZipcodeResp
    if (getZipcodeResp && prevGetZipcodeResp !== getZipcodeResp) {
      const {
        data: {
          zipcode: { cityName, countryName, stateName },
        },
      } = getZipcodeResp;
      const state = getStatesListResp?.data?.states.find((item) => item.name.toLowerCase() === stateName.toLowerCase());
      addEmployeeValidation.setValues((prev) => ({
        ...prev,
        city: cityName,
        county: countryName,
        state: state?.code || "",
      }));
    }
  
    // Handling getUserRolesResp
    if (getUserRolesResp && prevGetUserRolesResp !== getUserRolesResp) {
      setDropdown((prev) => ({
        ...prev,
        roles: getUserRolesResp?.data?.userRoles || []
      }));
    }
  
    // Handling masterDataErrorResp
    if (masterDataErrorResp && prevMasterDataErrorResp !== masterDataErrorResp) {
      const { errors, error, message } = masterDataErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
  
    // Handling getDepartmentsResp
    if (getDepartmentsResp && prevGetDepartmentsResp !== getDepartmentsResp) {
      setDropdown((prev) => ({
        ...prev,
        departments: getDepartmentsResp?.data?.departments || []
      }));
    }
  
    // Handling getDesignationsResp
    if (getDesignationsResp && prevGetDesignationsResp !== getDesignationsResp) {
      setDropdown((prev) => ({
        ...prev,
        designations: getDesignationsResp?.data?.designations || []
      }));
    }
  
    // Storing current API responses for future comparison
    apiResp.current.prevGetZipcodeResp = getZipcodeResp;
    apiResp.current.prevGetUserRolesResp = getUserRolesResp;
    apiResp.current.prevMasterDataErrorResp = masterDataErrorResp;
    apiResp.current.prevGetDepartmentsResp = getDepartmentsResp;
    apiResp.current.prevGetDesignationsResp = getDesignationsResp;
  }, [
    getZipcodeResp,
    getUserRolesResp,
    masterDataErrorResp,
    getDepartmentsResp,
    getDesignationsResp
  ]);
  
  const getZipcodeApiCall = () => {
    dispatch(getZipcodeAct(generateApiUrl(`get_zipcode`, { zipcode: addEmployeeValidation.values.zip })));
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    addEmployeeValidation.setValues((prev) => ({ ...prev, image: file }));

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
    }
  };
  const addNewOptionHandleChange = (id, value) => {
    switch (id) {
      case "designation":
        // Assuming you have a state to manage dropdown options like this:
        setDropdown((prev) => ({
          ...prev,
          designations: [...prev.designations, { id:value,name:value }] // Add the new value to the designations dropdown
        }));
        break;
      case "department":
        // Assuming you have a state to manage dropdown options like this:
        setDropdown((prev) => ({
          ...prev,
          departments: [...prev.departments, { id:value,name:value }] // Add the new value to the designations dropdown
        }));
        break;
  
      default:
        toast.error(`Unhandled ID: ${id}`);
    }
  };
  
  const handleDropdownChange = (type,id, value) => {
    switch (type) {
      case "roleId":
        addEmployeeValidation.setFieldValue("roleId", (id || ""));
        setRoleValue(value || "");
        break;
  
      case "department":
        addEmployeeValidation.setFieldValue("department", (value || ""));
        break;
  
      case "designation":
        addEmployeeValidation.setFieldValue("designation", ( value || ""));
        setDesignationValue(value || "");
        break;
  
      default:
        console.warn(`Unhandled type: ${type}`);
    }
  };
  return (
    <Fragment>
      <Container fluid className="px-5 mb-5">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4>{editEmployeeData ? "Edit Employee" : "Add Employee"}</h4>
        <Button
          color="primary"
          className="fs-5 d-flex align-items-center wh-btn"
          onClick={() => setActiveId("employees_list")}
          outline
        >
          {" "}
          <i className="ri-arrow-left-line"></i> Back
        </Button>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <Label htmlFor="active" className="form-label mb-0 text-nowrap fs-5 fw-bold">
            Status:
          </Label>
          <div className="form-check form-switch form-switch-lg form-switch-success px-5 ms-2">
            {addEmployeeValidation.values.active}
            <Input className="form-check-input" type="checkbox" role="switch" id="active" name="active" checked={addEmployeeValidation.values.active} onChange={addEmployeeValidation.handleChange} />
            <span className="fs-5 fw-bold text-success">Active</span>
          </div>
        </div>
        <Button
          color="primary"
          className="fs-5 d-flex align-items-center wh-btn"
          outline
          onClick={addEmployeeValidation.handleSubmit}
        >
          {" "}
          <img src={SaveIcon} width="32" height="32" alt="save" /> Save
        </Button>
      </div>
      <Row>
        <Col xs="6">
          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Employee Name :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Employee Name"
                type="text"
                name="name"
                value={addEmployeeValidation.values.name}
                onChange={addEmployeeValidation.handleChange}
                invalid={addEmployeeValidation.touched.name && addEmployeeValidation.errors.name}
              />
              <FormFeedback>{addEmployeeValidation.touched.name && addEmployeeValidation.errors.name}</FormFeedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Username :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Username"
                type="text"
                name="username"
                value={addEmployeeValidation.values.username}
                onChange={addEmployeeValidation.handleChange}
                invalid={addEmployeeValidation.touched.username && addEmployeeValidation.errors.username}
              />
              <FormFeedback>
                {addEmployeeValidation.touched.username && addEmployeeValidation.errors.username}
              </FormFeedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Department :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              
                <div className="d-flex flex-row">
                <SearchableDropdown
                  options={dropdown.departments}
                  label="name"
                  id="id"
                  inputName="department"
                  placeholder={"Select department"}
                  selectedVal={addEmployeeValidation.values.department}
                  divClassName="flex-1"
                  handleChange={(department,val) => handleDropdownChange("department",department,val)}
                />
                <i
                  className={`ri-pencil-line fs-5 ms-2 wh-link-color-green`}
                  onClick={() => toggleAddNewOptionModal('department')}
                ></i>
                </div>
                  {
                    addEmployeeValidation.touched.department && 
                    addEmployeeValidation.errors.department &&
                    <span className="text-danger">{departmentValue?`${console.log(departmentValue)} is doesn't exist`:addEmployeeValidation.errors.department}</span> 
                  }
               
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Designation :</Label>
            </Col>
            <Col xl={9} xxl={10}>
            <div className="d-flex flex-row">
            <SearchableDropdown
              options={dropdown.designations}
              label="name"
              id="id"
              inputName="designation"
              placeholder={"Select designation"}
              divClassName="flex-1"
              selectedVal={addEmployeeValidation.values.designation}
              handleChange={(designation,val) => handleDropdownChange("designation",designation,val)}
            />
            <i
              className={`ri-pencil-line fs-5 ms-2 wh-link-color-green`}
              onClick={() => toggleAddNewOptionModal('designation')}
            ></i>
            </div>
            {
              addEmployeeValidation.touched.designation && addEmployeeValidation.errors.designation &&
              <span className="text-danger">{addEmployeeValidation.errors.designation}</span> 
            }
      
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Role :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <SearchableDropdown
                options={dropdown.roles}
                label="name"
                id="id"
                inputName="roleId"
                placeholder="Select Role"
                selectedVal={roleValue}
                handleChange={(roleId,val) => handleDropdownChange("roleId",roleId,val)}
              />
              {
                addEmployeeValidation.touched.roleId && addEmployeeValidation.errors.roleId &&
                <span className="text-danger">{addEmployeeValidation.errors.roleId}</span> 
              }
              <FormFeedback>{addEmployeeValidation.touched.roleId && addEmployeeValidation.errors.roleId}</FormFeedback>
            </Col>
          </Row>
        </Col>
        <Col xs="6" className="ps-5">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-start">
              <Label className="fs-5 mb-1">Upload Photo</Label>
              <div className="border p-1 mb-1">
                <img src={previewUrl || DummyAvatar} alt="user" width="90" height="90" />
              </div>
              <Label>Supported Formats: JPEG/PNG/BMP</Label>
            </div>
            <Input className="form-control w-50" accept="image/*" type="file" onChange={handleFileInputChange} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Contact :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Contact"
                type="text"
                name="homePhone"
                value={addEmployeeValidation.values.homePhone}
                onChange={(e) => blockInvalidCharForPhone(e, () => addEmployeeValidation.handleChange(e))}
                minLength="15"
                maxLength="15"
                invalid={addEmployeeValidation.touched.homePhone && addEmployeeValidation.errors.homePhone}
              />
              <FormFeedback>{addEmployeeValidation.touched.homePhone && addEmployeeValidation.errors.homePhone}</FormFeedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Email Id :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Email Id"
                type="text"
                name="email"
                value={addEmployeeValidation.values.email}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>

          
            <Row className="mb-3">
              <Col xl={3} xxl={2} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Password :</Label>
              </Col>
              <Col xl={9} xxl={10}>
                <Input
                  className="form-control"
                  placeholder="Enter Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={addEmployeeValidation.values.password}
                  onChange={addEmployeeValidation.handleChange}
                  invalid={addEmployeeValidation.touched.password && addEmployeeValidation.errors.password}
                />
                <FormFeedback>
                  {addEmployeeValidation.touched.password && addEmployeeValidation.errors.password}
                </FormFeedback>
                <button
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none password-addon wh-link-color-green"
                  type="button"
                  id="password-addon"
                  onClick={togglePassword}
                  style={{padding: '0.2rem 1rem',}}
                >
                  {showPassword ? <i className="ri-eye-off-fill align-middle"></i> : <i className="ri-eye-fill align-middle"></i>}
                </button>
              </Col>
            </Row>
          

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Date of Birth :</Label>
            </Col>
            <Col xl={4} xxl={5}>
              <Input
                type="date"
                className="form-control"
                name="birthDate"
                value={addEmployeeValidation.values.birthDate}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
           
            <Col xl={5} xxl={5}>
             <div className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap me-2">Gender :</Label>
              <select
                className="form-select"
                name="gender"
                value={addEmployeeValidation.values.gender}
                onChange={addEmployeeValidation.handleChange}
              >
                <option value="">Select Gender</option>
                <option>Male</option>
                <option>Female</option>
              </select>
             </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Joining Date :</Label>
            </Col>
            <Col xl={4} xxl={5}>
              <Input
                type="date"
                className="form-control"
                name="hireDate"
                value={addEmployeeValidation.values.hireDate}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          
            <Col xl={5} xxl={5}>
             <div className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap me-2">Salary :</Label>
              <Input
                className="form-control"
                placeholder="Enter Salary"
                type="text"
                name="salary"
                value={addEmployeeValidation.values.salary}
                onChange={addEmployeeValidation.handleChange}
              />
             </div>
            </Col>
          </Row>
        </Col>
        <Col xs="6 ps-5">
          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Street :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Street"
                type="text"
                name="street"
                value={addEmployeeValidation.values.street}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Suit :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Suit"
                type="text"
                name="suit"
                value={addEmployeeValidation.values.suit}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">City :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter City"
                type="text"
                name="city"
                value={addEmployeeValidation.values.city}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">State :</Label>
            </Col>
            <Col xs={4}>
              <select
                className="form-control form-select"
                type="text"
                name="state"
                value={addEmployeeValidation.values.state}
                onChange={addEmployeeValidation.handleChange}
              >
                <option value="">Select State</option>
                {(getStatesListResp?.data?.states || []).map((item) => (
                  <option value={item.code} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col xl={3} xxl={2} className="d-flex align-items-center ps-5">
              <Label className="form-label mb-0 text-nowrap">Zip :</Label>
            </Col>
            <Col xs={4} className="d-flex align-items-start">
              <div className="flex-grow-1 me-2">
                <Input
                  name="zip"
                  value={addEmployeeValidation.values.zip}
                  onChange={addEmployeeValidation.handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") getZipcodeApiCall();
                  }}
                  className="form-control"
                  placeholder="Enter zip"
                  type="text"
                  invalid={addEmployeeValidation.touched.zip && addEmployeeValidation.errors.zip ? true : false}
                />
                <FormFeedback>
                  {addEmployeeValidation.touched.zip && addEmployeeValidation.errors.zip
                    ? addEmployeeValidation.errors.zip
                    : ""}
                </FormFeedback>
              </div>
              <Button color="primary" onClick={getZipcodeApiCall}>
                <i className="ri-check-line"></i>
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">County :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter County"
                type="text"
                name="county"
                value={addEmployeeValidation.values.county}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    {/* modals */}

    <AddNewOptionModal 
      isOpen={modalConfig.isDesignationModalOpen}
      toggle={() => toggleAddNewOptionModal('designation')}
      title="Add Designation"
      inputId={"designation"}
      addNewOptionHandleChange={addNewOptionHandleChange}
    />
    <AddNewOptionModal 
      isOpen={modalConfig.isDepartmentModalOpen}
      toggle={() => toggleAddNewOptionModal('department')}
      title="Add Department"
      inputId={"department"}
      addNewOptionHandleChange={addNewOptionHandleChange}
    />

    <ApiLoader loading={employeeApiLoading}/>
    </Fragment>
    
  );
};

export default AddNewEmployee;
