import { call, takeLatest, put, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import usermanagementconstants from "./usermanagementconstants";
import { changeUserManagementApiLoadingStatusAct } from "./usermanagementactions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changeUserManagementApiLoadingStatusAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changeUserManagementApiLoadingStatusAct(false));
    }
}

function* createUserRoleGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:usermanagementconstants.CREATE_USER_ROLE_SUCCESS,
        failAction:usermanagementconstants.CREATE_USER_ROLE_FAIL,
    });
}

function* updateUserRoleGen(action) {
    const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:usermanagementconstants.UPDATE_USER_ROLE_SUCCESS,
        failAction:usermanagementconstants.UPDATE_USER_ROLE_FAIL,
    });
}

function* getUserRolesGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: usermanagementconstants.GET_USER_ROLES_SUCCESS,
      failAction: usermanagementconstants.GET_USER_ROLES_FAIL
    })
}

function* getAllPermissionsGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: usermanagementconstants.GET_ALL_PERMISSIONS_SUCCESS,
      failAction: usermanagementconstants.GET_ALL_PERMISSIONS_FAIL
    })
}

function* getPermissionsByRoleGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: usermanagementconstants.GET_PERMISSIONS_BY_ROLE_SUCCESS,
      failAction: usermanagementconstants.GET_PERMISSIONS_BY_ROLE_FAIL
    })
}

function* createRolePermissionGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: usermanagementconstants.CREATE_ROLE_PERMISSION_SUCCESS,
      failAction: usermanagementconstants.CREATE_ROLE_PERMISSION_FAIL
    })
}

function* getAllStationsGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.params, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: usermanagementconstants.GET_ALL_STATIONS_SUCCESS,
      failAction: usermanagementconstants.GET_ALL_STATIONS_FAIL,
    });
}

function* deleteRolesGen(action) {
  const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
  yield call(handleApiResponse, response, {
    successAction: usermanagementconstants.DELETE_ROLES_SUCCESS,
    failAction: usermanagementconstants.DELETE_ROLES_FAIL
  })
}

export function* userManagementSaga() {
    yield takeLatest(usermanagementconstants.CREATE_USER_ROLE_REQUEST, withLoading(createUserRoleGen));
    yield takeLatest(usermanagementconstants.UPDATE_USER_ROLE_REQUEST, withLoading(updateUserRoleGen));
    yield takeLatest(usermanagementconstants.GET_USER_ROLES_REQUEST, withLoading(getUserRolesGen));
    yield takeLatest(usermanagementconstants.GET_ALL_PERMISSIONS_REQUEST, getAllPermissionsGen);
    yield takeLatest(usermanagementconstants.GET_PERMISSIONS_BY_ROLE_REQUEST, withLoading(getPermissionsByRoleGen));
    yield takeLatest(usermanagementconstants.CREATE_ROLE_PERMISSION_REQUEST, withLoading(createRolePermissionGen));
    yield takeLatest(usermanagementconstants.GET_ALL_STATIONS_REQUEST, withLoading(getAllStationsGen));
    yield takeLatest(usermanagementconstants.DELETE_ROLES_REQUEST, withLoading(deleteRolesGen));
}
