import * as Yup from "yup";
import moment from "moment";
import { Badge,Input } from "reactstrap";
import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../assets/images/warehouse/salespage/printer.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import PaymentsIcon from "../../assets/images/warehouse/salespage/payments.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import PurchaseOrderIcon from "../../assets/images/warehouse/header/purchase_order.png";
import ClickedSelectItem from "../../Components/Common/InputConvertItems/ClickedSelectItem";
import ClickedInput from "../../Components/Common/InputConvertItems/ClickedInput";
import ClickedNumber from "../../Components/Common/InputConvertItems/ClickedNumber";
import ClickedInputDate from "../../Components/Common/InputConvertItems/ClickedInputDate";
//Utility
import { getYyyymmddDateFormatted, getStartDateFilterValue } from "../../utility/dateutility";
import { formatAmountSymbol,blockInvalidChar } from "../../utility/commonutility";

//Constants
import { currencySymbol } from "../../constants";
import { isModalOpenSelector } from ".";
import { isModalPriceSelector } from ".";


export const initialTableRowValues = {
  supplierItemNumber: "",
  stockItemNumber: "",
  description: "",
  retail: "",
  cost: "",
  price: "",
  tax: "",
  quantity: "",
  caseQty: "",
  discount: "",
};
export const actions = (history, initializePO, id, received, getPurchaseOrderDetailsApiCall, toggleDeleteModal, downloadPoHandler, togglePrintModal, handlePaymentDetailsOpenModal,) => {
  const actionsList = [
    {
      id: "reset",
      label: "Reset",
      icon: ResetIcon,
      clickHandler: () => {
        if (id && id !== "new") getPurchaseOrderDetailsApiCall();
        else initializePO();
      },
    },
    {
      id: "po",
      label: "PO",
      icon: PurchaseOrderIcon,
      clickHandler: () => history.push("/warehousepurchaseorders"),
    },
    {
      id: "print",
      label: "Print",
      icon: PrintIcon,
      // clickHandler:downloadPoHandler
      clickHandler:togglePrintModal
    },
    {
      id: "delete",
      label: "Delete",
      icon: DeleteIcon,
      clickHandler: toggleDeleteModal,
    },
    {
      id: "new",
      label: "New",
      icon: NewIcon,
      clickHandler: () => {
        history.push("/warehousepurchaseorders/new");
      },
    },
    
  ];

  if((id === 'new') || !received ){
    actionsList.push({
      id: "payments",
      label: "Payments",
      icon: PaymentsIcon,
      clickHandler: handlePaymentDetailsOpenModal,
    },)
  }

  return actionsList;
}
export function generateColumns(
  supplierItemsColumnsList,
  getItemsOfSupplierApiCall,
  getInventoryListApiCall,
  callbackAfterGetItemsOfSupplierApiCall,
  callbackAfterGetInventoryListApiCall,
  removeItem,
  selectedItemHandler,
  selectedItemHandlerForSupplierItem,
  fieldChangeHndlr,
  viewItemsMode,
  openSuppliersModal,
  isReturnInvoice,
  received,
  totalItems,
  showSmartSearchModal,
  poDetailsValidation
) {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      Cell: ({ row }) => row.index + 1,
      style: { width: "3%" },
    },
    // {
    //   Header: "Supplier Item #",
    //   accessor: "supplierItemNumber",
    //   Cell: ({ row }) =>
    //     received ? (
    //       row.original.supplierItemNumber
    //     ) : (
    //       <ClickedInput
    //         inputType="number"
    //         value={row.original.supplierItemNumber}
    //         itemKey="supplierItemNumber"
    //         fieldChangeHndlr={fieldChangeHndlr}
    //         index={row.index}
    //         callback={getItemsOfSupplierApiCall}
    //         reduxDetails={"supplier.getItemsOfSupplierResp"}
    //         searchBy={"supplierItemNumber"}
    //         selectedItemHandler={selectedItemHandlerForSupplierItem}
    //         afterSelectedItemhandlerCallback={() => setTimeout(() => focusPriceNumberInput(row.index),200)}
    //         apiResponseKey={"itemList"}
    //         allowEdit
    //         addObserver
    //         containerClass="select-supplier-item-number-wrapper"
    //         innerInputClass='py-0 mt-1'
    //         onKeyDown={e => {
    //           if(e.key === "Enter") {
    //             setTimeout(() => {
    //               if(row.original.stockItemNumber) focusPriceNumberInput(row.index);
    //              else{
    //               if(!isModalOpenSelector()){
    //               focusSelectItemNumberInput(row.index);
    //               }
    //              }
    //             }, 200)
    //           }
    //         }}
    //       />
    //     ),
    //   style: { width: "9%" },
    // },
    {
      Header: "Stock Item #",
      accessor: "stockItemNumber",
      Cell: ({ row }) =>
        received ? (
          <span onDoubleClick={() => openSuppliersModal(row.original.stockItemNumber, row.original.description)}>{row.original.stockItemNumber}</span>
        ) : (
          <ClickedSelectItem
            columnsList={supplierItemsColumnsList}
            placeholder="Search by Item Number"
            callback={viewItemsMode === "show_all_items" ? getInventoryListApiCall : getItemsOfSupplierApiCall}
            reduxDetails={
              viewItemsMode === "show_all_items" ? "inventory.getInventoryListResp" : "supplier.getItemsOfSupplierResp"
            }
            searchBy={"itemNumber"}
            selectedItemHandler={selectedItemHandler}
            dataIdKey="itemNumber"
            value={row.original.stockItemNumber}
            index={row.index}
            callbackAfterCallback={
              viewItemsMode === "show_all_items"
                ? callbackAfterGetInventoryListApiCall
                : callbackAfterGetItemsOfSupplierApiCall
            }
            doubleClickHandler={() => window.open(`/warehouseinventory/${row.original.stockItemNumber}`, "_blank")}
            addItemByEnter={!showSmartSearchModal}
            addItemByEnterKey="itemNumber"
            containerClassName="select-item-number-wrapper"
            isLast={totalItems === row.index + 1}
            addObserver={true}
            inputClassName="p-0 mt-1"
          />
        ),
      style: { width: "8%" },
    },
    {
      Header: (
        <div className="d-flex align-items-center">
          Description {isReturnInvoice && <Badge color="info ms-2">Return</Badge>}
        </div>
      ),
      accessor: "description",
      Cell: ({ row }) =>
        received ? (
          row.original.description
        ) : (
          <ClickedSelectItem
            columnsList={supplierItemsColumnsList}
            placeholder="Search by Item Name"
            callback={viewItemsMode === "show_all_items" ? getInventoryListApiCall : getItemsOfSupplierApiCall}
            reduxDetails={
              viewItemsMode === "show_all_items" ? "inventory.getInventoryListResp" : "supplier.getItemsOfSupplierResp"
            }
            searchBy={"description"}
            selectedItemHandler={selectedItemHandler}
            dataIdKey="itemNumber"
            value={row.original.description}
            index={row.index}
            containerClassName="select-description-wrapper"
            callbackAfterCallback={
              viewItemsMode === "show_all_items"
                ? callbackAfterGetInventoryListApiCall
                : callbackAfterGetItemsOfSupplierApiCall
            }
            doubleClickHandler={() => openSuppliersModal(row.original.stockItemNumber, row.original.description)}
            inputClassName="p-0 mt-1"
          />
        ),
        
      style: { width: "38%", },
    },
    {
      Header: "Manufactured Date",
      accessor: "manufacturingDate",
      Cell: ({row}) =>  received ? (
        row.original.manufacturingDate ? `${moment(row.original.manufacturingDate).format('DD-MM-YYYY')}`:''
      ) : row.original.stockItemNumber ? <ClickedInputDate
            inputType="date"
            value={row.original.manufacturingDate}
            itemKey="manufacturingDate"
            fieldChangeHndlr={fieldChangeHndlr}
            index={row.index}
            allowEdit={true}
            containerClass="manufacturingDate-wrapper full-width"
            addObserver 
            onKeyDown={e => {
              if(e.key === "Enter") {
                setTimeout(() => {
                  focusExpirationDateInput(row.index);
                }, 0)
              }
            }}
            inputStyle={{fontWeight:600}}
          /> : "",
        
      style: { width: "10%" },
    },
    {
      Header: "Expiry Date",
      accessor: "expirationDate",
      Cell: ({row}) =>  received ? (
        row.original.expirationDate ? `${moment(row.original.expirationDate).format('DD-MM-YYYY')}`: ''
      ) : row.original.stockItemNumber ? <ClickedInputDate
            inputType="date"
            value={row.original.expirationDate}
            itemKey="expirationDate"
            fieldChangeHndlr={fieldChangeHndlr}
            index={row.index}
            allowEdit={true}
            containerClass="expirationDate-wrapper full-width"
            addObserver 
            onKeyDown={e => {
              if(e.key === "Enter") {
                setTimeout(() => {
                  focusPriceNumberInput(row.index);
                }, 0)
              }
            }}
            inputStyle={{fontWeight:600}}
          /> : "",
      style: { width: "7%" },
    },
    {
      Header: <div style={{textAlign:'center'}}>Cost</div>,
      accessor: "price",
      Cell: ({ row }) => {
        const inputStyle = {
          width : '140px'
        }
        
        return  received ? (
          row.original.price ? `${currencySymbol}${row.original.price}` : ""
        ) : row.original.stockItemNumber ? (
          <ClickedInput
            inputType="number"
            placeholder="Enter Cost"
            value={row.original.price}
            itemKey="price"
            fieldChangeHndlr={fieldChangeHndlr}
            index={row.index}
            icon={<i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>}
            prefix={formatAmountSymbol()}
            allowEdit={true}
            containerClass="price-wrapper full-width"
            innerInputClass='py-0 mt-1'
            addObserver
            inputStyle={inputStyle}
            onKeyDown={e => {
              if(e.key === "Enter") {
                setTimeout(() => {
                  if(!isModalPriceSelector()){
                    focusQuantityNumberInput(row.index);
                  }
                }, 0)
              }
            }}
            spanClassName={"justify-content-center"}
          />
        ) : (
          ""
        )
      },
      style: { width: "8%",textAlign:'center' },
    },
    // {
    //   Header: "Cost",
    //   accessor: "cost",
    //   Cell: ({
    //     row: {
    //       original: { price, tax, stockItemNumber },
    //     },
    //   }) => (stockItemNumber ? `${formatAmountSymbol()}${(Number(price) + Number(tax)).toFixed(2)}` : ""),
    // },
    {
      Header: "Retail",
      accessor: "retail",
      Cell: ({ row }) => {
        const inputStyle = {
          width : '140px',
        }
        return received ? (
          row.original.retail ? `${currencySymbol}${(+row.original.retail).toFixed(2)}` : ""
        ) : row.original.stockItemNumber ? (
          <ClickedInput
            inputType="number"
            placeholder="Enter Retail"
            value={(+row.original.retail).toFixed(2)}
            itemKey="retail"
            fieldChangeHndlr={fieldChangeHndlr}
            index={row.index}
            icon={<i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>}
            prefix={formatAmountSymbol()}
            allowEdit={true}
            containerClass="full-width"
            innerInputClass='py-0 mt-1'
            inputStyle={inputStyle}
            addObserver
            spanClassName={"justify-content-center"}
          />
        ) : (
          ""
        )
      },
      style: { width: "4%" }
    },
      // Cell: ({ cell: { value } }) =>
      //   value ? (
      //     <>
      //       {currencySymbol}
      //       {value.toFixed(2)}
      //     </>
      //   ) : (
      //     ""
      //   ),
  
    {
      Header: <div style={{textAlign:'center'}}>Qty</div>,
      accessor: "quantity",
      Cell: ({ row }) =>{
        const innerDivStyle = {
          height:'25px',
          marginTop:'0.25em',
        }
        return received ? (
          row.original.quantity
        ) :
          (row.original.stockItemNumber ? (
            <ClickedNumber
              value={row.original.quantity}
              itemKey="quantity"
              fieldChangeHndlr={fieldChangeHndlr}
              index={row.index}
              containerClassName="quantity-wrapper"
              addObserver={true}
              onKeyDown={e => {
                if(e.key === "Enter") {        
                  setTimeout(() => focusSelectItemNumberInput(row.index + 1), 200);
                }
              }}
              innerDivStyle={innerDivStyle}
              spanClassName={"justify-content-center"}
            />
          ) : (
            ""
          ))
      },
      style: { width: "3%" },
    },
    // {
    //   Header:  <div style={{textAlign:'center'}}>Case Qty</div>,
    //   accessor: "caseQty",
    //   Cell: ({ row }) =>{
    //     const innerDivStyle = {
    //       height:'25px',
    //       marginTop:'.25em'
    //     }
    //     return received ? (
    //       row.original.caseQty
    //     ) :
    //       (row.original.stockItemNumber ? (
    //         <ClickedNumber
    //           value={row.original.caseQty}
    //           itemKey="caseQty"
    //           fieldChangeHndlr={fieldChangeHndlr}
    //           index={row.index}
    //           min="0"
    //           innerDivStyle={innerDivStyle}
    //           spanClassName={"justify-content-center"}
    //         />
    //       ) : (
    //         ""
    //       ))
    //   }
      
    //  ,
    //   style: { width: "7%", },
    // },
    {
      Header:  <div style={{textAlign:'right'}}>Discount</div>,
      accessor: "discount",
      Cell: ({ row }) => {
        const inputStyle = {
          width: '150px',
        }
        return  received ? (
          `${row.original.discount || 0}%`
         ) :
           (row.original.stockItemNumber ? (
             <ClickedInput
               inputType="number"
               value={row.original.discount}
               placeholder="Enter Discount %"
               itemKey="discount"
               fieldChangeHndlr={fieldChangeHndlr}
               index={row.index}
               icon={<i style={{ fontStyle: "normal" }}>%</i>}
               containerClass="full-width"
               iconRightAlign={true}
               suffix={"%"}
               spanPlaceholder="0%"
               inputMin={0}
               inputMax={100}
               allowEdit={true}
               innerInputClass='py-0 mt-1'
               inputStyle={inputStyle}
               spanClassName={"justify-content-end"}
             />
           ) : (
             ""
           ))
      },
      style: { width: "5%" },
    },
    {
      Header: <div style={{textAlign:'center'}}>Amount</div>,
      accessor: "amount",
      Cell: ({
        row: {
          original: { price, quantity, discount, stockItemNumber },
        },
      }) => {
        let priceVal = price || 0;
        let disPerVal = discount || 0;
        let total = priceVal * Number(quantity);
        let disVal = (total * disPerVal) / 100;
        return <span className="d-flex justify-content-center">{stockItemNumber ? `${formatAmountSymbol()}${(total - disVal).toFixed(2)}` : ""}</span>;
      },
      style: { width: "3%" },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        row.original.stockItemNumber ? 
        <i
          className={`ri-delete-bin-line text-danger fs-5 ${received ? "show-cursor-not-allowed opacity-50" : "show-cursor-pointer"}`}
          onClick={received ? () => {} : () => removeItem(row.index)}
        ></i> : ""
      ),
      style: { width: "3%",textAlign:'center' },
    },
  ];
}

export const supplierColumnsList = [
  {
    id: "supplierNumber",
    name: "Supplier. Num",
    cellClassName: "fw-medium",
  },
  {
    id: "companyName",
    name: "Company",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "supplierName",
    name: "Supplier Name",
  },
];

export const supplierItemsColumnsList = [
  {
    id: "itemNumber",
    name: "Item Number",
    cellClassName: "fw-medium",
  },
  {
    id: "description",
    name: "Item Name",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "cost",
    name: "Cost",
    cellClassName: "fw-medium",
  },
];

export const generatePayload = (
  paymentDetailsValues,
  poDetailsValues,
  tableData,
  supplierNumber,
  totalAmount,
  poTotal,
  received,
  updateCostRetail
) => {
  const payload = {
    supplierNumber,
    ...paymentDetailsValues,
    ...poDetailsValues,
    receivedDate: poDetailsValues.receivedDate ? getStartDateFilterValue(moment(poDetailsValues.receivedDate).format("YYYY-MM-DD")) : null,
    subTotal:Number(totalAmount).toFixed(2),
    poAmount: Number(poTotal.toFixed(2)),
    poDate: poDetailsValues.poDate ? getStartDateFilterValue(moment(poDetailsValues.poDate).format("YYYY-MM-DD")) : null,
    received,
    updateCostRetail
  };
  const items = [];
  let tCount = 0;
  tableData.filter(item => item.stockItemNumber).forEach((item) => {
    const {
      supplierItemNumber,
      stockItemNumber,
      manufacturingDate,
      expirationDate,
      retail,
      cost,
      quantity,
      caseQty,
      discount,
      tax,
      lineNumber,
      category,
      uCount,
      price,
    } = item;
    let costVal = (Number(price) + Number(tax)) || 0;
    let disPerVal = discount || 0;
    let total = costVal * Number(quantity);
    let disVal = (total * disPerVal) / 100;
    items.push({
      lineNumber,
      itemNumber: supplierItemNumber,
      stockItemNumber,
      manufacturingDate,
      expirationDate,
      retail:Number(retail).toFixed(2),
      cost: (Number(price)).toFixed(2),
      pTax: Number(tax).toFixed(2),
      quantity,
      caseQty,
      discount:Number(discount).toFixed(2),
      amount: (total - disVal).toFixed(2),
      category,
      singleUnitMsa:uCount,
      dateRecvd:getStartDateFilterValue(poDetailsValues.receivedDate),
      dateExpec: getStartDateFilterValue(moment().format("YYYY-MM-DD"))
    });
    tCount += Number(quantity);
  });
  payload.items = items;
  payload.tCount = tCount;
  return payload;
};

export const mapPoDetails = (
  setTableData,
  setPoDetails,
  setPaymentDetails,
  setSupplierDetails,
  setSearch,
  setReceived,
  apiResp
) => {
  const {
    data: {
      purchaseOrder: {
        invNumber,
        poDate,
        received,
        receivedDate,
        paymentTerm,
        purchaseOrderLineItems,
        supplierName,
        supplierNumber,
        amountPaid,
        paymentType,
        chequeNo,
        upiNo,
        accountNo,
        chequeDate,
        inTheNameOf,
        bankName,
        transactionId,
        transactionDate,
        freight,
        sgst,
        cgst,
        igst,
        other,
        tax
      },
    },
  } = apiResp;
  setPoDetails((prev) => ({
    ...prev,
    invNumber: invNumber,
    poDate: poDate ? (poDate.slice(0,10)) : "",
    receivedDate: receivedDate ? (receivedDate.slice(0,10)) : "",
    paymentTerm,
    freight,
    sgst,
    cgst,
    igst,
    other,
    tax
  }));
  setPaymentDetails((prev) => ({
    ...prev,
    amountPaid: amountPaid || "",
    paymentType: paymentType || "",
    chequeNo: chequeNo || "",
    upiNo: upiNo || "",
    accountNo: accountNo || "",
    chequeDate: chequeDate ? getYyyymmddDateFormatted(chequeDate.slice(0,8)) : "",
    inTheNameOf: inTheNameOf || "",
    bankName: bankName || "",
    transactionId: transactionId || "",
    transactionDate: transactionDate ? getYyyymmddDateFormatted(transactionDate.slice(0,8)) : "",
  }));
  setSupplierDetails((prev) => ({
    ...prev,
    supplierNumber,
    companyName: supplierName,
  }));
  setReceived(received);
  setSearch(supplierName);
  const tableData = [];
  purchaseOrderLineItems.forEach((item) => {
    const {
      lineNumber,
      itemNumber,
      stockItemNumber,
      description,
      manufacturingDate,
      expirationDate,
      caseQty,
      cost,
      quantity,
      discount,
      retail,
      category,
      singleUnitMsa,
      pTax,
      inventoryTax
    } = item;
    tableData.push({
      lineNumber,
      supplierItemNumber: itemNumber,
      stockItemNumber,
      description,
      manufacturingDate,
      expirationDate,
      retail: retail || "",
      cost,
      price: (cost - pTax).toFixed(2),
      tax: pTax,
      profit: ((retail - cost) / retail) * 100,
      category,
      uCount:singleUnitMsa,
      quantity,
      caseQty,
      discount: discount || "",
      taxAmount: inventoryTax?.taxAmount,
      taxPercentage: inventoryTax?.taxPercentage,
    });
  });
  if(!received) tableData.push(initialTableRowValues)
  setTableData(tableData);
};

export const paymentDetailsValidationSchema = Yup.object({
  amountPaid: Yup.string().required("Amount paid is required"),
  paymentType: Yup.string().required("Please select payment type"),
  chequeNo: Yup.string().test("required", "Cheque No is required", (value, ctx) =>
    ctx.parent.paymentType === "CHEQUE" ? value : true
  ),
  upiNo: Yup.string().test("required", "Account No is required", (value, ctx) =>
    ctx.parent.paymentType === "UPI" ? value : true
  ),
  accountNo: Yup.string().test("required", "UPI No is required", (value, ctx) =>
    ctx.parent.paymentType === "BANKTRANSFER" ? value : true
  ),
  chequeDate: Yup.string().test("required", "Cheque Date is required", (value, ctx) =>
    ctx.parent.paymentType === "CHEQUE" ? value : true
  ),
  inTheNameOf: Yup.string().test("required", "In the name of is required", (value, ctx) =>
    ctx.parent.paymentType === "CHEQUE" ? value : true
  ),
  bankName: Yup.string().test("required", "Bank Name is required", (value, ctx) =>
    ctx.parent.paymentType === "CHEQUE" ? value : true
  ),
  transactionId: Yup.string().test("required", "Transaction No is required", (value, ctx) =>
    ((ctx.parent.paymentType === "ONLINE") || (ctx.parent.paymentType === "UPI") || (ctx.parent.paymentType === "BANKTRANSFER")) ? value : true
  ),
  transactionDate: Yup.string().test("required", "Transaction Date is required", (value, ctx) =>
    ((ctx.parent.paymentType === "ONLINE") || (ctx.parent.paymentType === "UPI") || (ctx.parent.paymentType === "BANKTRANSFER")) ? value : true
  ),
});

export const focusSelectSupplierItemNumberInput = (index) => {
  const items = document.getElementsByClassName("select-supplier-item-number-wrapper");
  if (items.length && items[index]) {
    items[index].classList.add("active");
  }
};
export const focusLastSelectSupplierItemNumberInput = () => {
  const items = document.getElementsByClassName("select-supplier-item-number-wrapper");
  if (items.length ) {
    items[items.length - 1].classList.add("active");
  }
};
export const focusLastSelectItemNumberInput = () => {
  const items = document.getElementsByClassName("select-item-number-wrapper");
  if (items.length ) {
    items[items.length - 1].classList.add("active");
  }
};

export const focusSelectItemNumberInput = (index) => {
  const items = document.getElementsByClassName("select-item-number-wrapper");
  if (items.length && items[index]) {
    items[index].classList.add("active");
  }
};

export const focusManufacturingDateInput = (index) => {
  const items = document.getElementsByClassName("manufacturingDate-wrapper");
  if (items.length && items[index]) {
    items[index].classList.add("active");
  }
};

export const focusExpirationDateInput = (index) => {
  const items = document.getElementsByClassName("expirationDate-wrapper");
  if (items.length && items[index]) {
    items[index].classList.add("active");
  }
};

export const focusPriceNumberInput = (index) => {
  const items = document.getElementsByClassName("price-wrapper");
  if (items.length && items[index]) {
    items[index].classList.add("active");
  }
};

export const focusQuantityNumberInput = (index) => {
  const items = document.getElementsByClassName("quantity-wrapper");
  if (items.length && items[index]) {
    items[index].classList.add("active");
  }
};