import React from 'react'
import { Label } from 'reactstrap';
import SelectItem from '../../Common/SelectItem';

function SearchInventoryBox({
    search,
    setSearch,
    searchListOptions,
    setSearchListOptions,
    columnsList,
    id,
    inventoryItemColumnsList,
    selectItemCallbck,
    getInventoryListApiCall,
    currentInventoryListPage,
    callbackOnInventoryListApiResp,
    getInventoryBundlesListApiCall
}) {
  return (
    <div>
         <Label htmlFor="userrole" className="form-label mb-1">
           Search Inventory Item
         </Label>
         <SelectItem
           searchValue={search}
           setSearchValue={setSearch}
           listOptions={searchListOptions}
           setSearchListOptions={setSearchListOptions}
           columnsList={columnsList}
           placeholder="Select Supplier"
           selectedItemHandler={selectItemCallbck}
           fieldKey="itemName"
           dataIdKey="itemNumber"
           showDropdownBtn={true}
           dropdownBtnClickHndlr={(setShowOptions) => {
             if(id === 'bundles'){
               
               getInventoryBundlesListApiCall(true)
             }else{ 
               
               getInventoryListApiCall(true)
             }
             setShowOptions(true);
           }}
           callbackAfterBottomReached={(fn) => {
             if(id === 'bundles'){
               getInventoryBundlesListApiCall(true, currentInventoryListPage.current)
             }else{
               getInventoryListApiCall(true, currentInventoryListPage.current);
             }
             
             callbackOnInventoryListApiResp.current = fn;
           }}
         />
    </div>
  )
}

export default SearchInventoryBox;
