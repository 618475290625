export function generateColumns(page) {
    return [
        {
            Header: "Customer Name",
            accessor: "customer_name",
            Cell:({cell:{value}}) => value?value:'-'
        },
        {
            Header: "Invoice Number",
            accessor: "invoice_number",
            Cell:({cell:{value}}) => value?value:'-'
        }, 
        
    ]
}