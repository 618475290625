import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import F12CloseModalListener from "../../Components/Common/F12close";

const InactiveItemModal = ({
  show,
  onCloseClick,
  title = "",
  confirmationText = "",
  centered = true,
}) => {
  const [isButtonFocused, setIsButtonFocused] = useState(false);
  const okButtonRef = useRef(null);

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      onCloseClick();
    }
  };

  const handleButtonFocus = () => {
    setIsButtonFocused(true);
  };

  const handleButtonBlur = () => {
    setIsButtonFocused(false);
  };
  useEffect(() => {
    if (show && okButtonRef.current) {
      setTimeout(() => {
        okButtonRef.current.focus();
      }, 1000);
    }
  }, [show]);
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={centered}
      onKeyUp={handleEnterKey}
    >
      <F12CloseModalListener onClose={onCloseClick} />
      {title && <ModalBody className="border-bottom py-2">{title}</ModalBody>}
      <ModalBody className="py-0 pb-2">
        <div className="mt-2">
          <div className="pt-2 fs-15">
            <p className="text-muted mb-0">{confirmationText}</p>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2 mb-2">
          <button
            ref={okButtonRef}
            autoFocus
            id="okref"
            type="button"
            tabIndex={0}
            className={`btn w-sm ${
              isButtonFocused ? "btn-primary border border-danger" : "btn-light"
            }`}
            onClick={onCloseClick}
            onFocus={handleButtonFocus}
            onBlur={handleButtonBlur}
          >
            OK
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

InactiveItemModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  title: PropTypes.string,
  confirmationText: PropTypes.string,
};

export default InactiveItemModal;
