
export function getLocalStorage(itemKey) {
    const itemData = localStorage.getItem(itemKey);
    return JSON.parse(itemData);
}

export function setLocalStorage(itemKey, payload) {
    localStorage.setItem(itemKey, JSON.stringify(payload))
}
export function removeLocalStorage(itemKey) {
    localStorage.removeItem(itemKey);
}

export function getSessionStorage(itemKey) {
    const itemData = sessionStorage.getItem(itemKey)
    return JSON.parse(itemData);
}

export function setSessionStorage(itemKey, payload) {
    sessionStorage.setItem(itemKey, JSON.stringify(payload))
}
export function clearLocalStorage() {
    localStorage.clear();
}

export default { setLocalStorage, removeLocalStorage, getLocalStorage, setSessionStorage, getSessionStorage, clearLocalStorage }