import React, {useRef,useState} from 'react'
import { Container,Row,Col,Input,Modal,ModalHeader,ModalBody,Form, Button } from 'reactstrap';
import { Link, withRouter,useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { shallowEqual } from 'react-redux';
import { generateApiUrl } from '../../../api/apihelper';
// import { getInventoryBundlesListAct } from '../../../store/inventory/inventoryactions';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import TableLoader from '../../../Components/Common/TableLoader';
import { generateBundlesColumns } from './helper';
import NoDataComponent from '../../../Components/Common/NoDataComponent';
import { getInventoryBundlesListAct } from '../../../store/inventory/inventoryactions';

function Bundles({
  setIsBlocked,
  parentRef,
  setRowSelectedIds,
  search,
  setSearchValue,
  setSearchListOptions,
  setColumnsList,
  dropdownBtnClicked,
  toggleDeleteModal,
  isRouteChangeApiCalled
}) {
    const [bundlesList,setBundlesList] = useState([
      {
          "id": 261,
          "title": "Pack of 3 - Small - Hip Hugger - Pink",
          "skuNumber": "200624-1",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 49,
          "bundleItems": [
              {
                  "itemNumber": "24495",
                  "quantity": 1,
                  "inventory": {
                      "description": "Forest Small",
                      "cost": 10,
                      "retail": 20
                  }
              },
              {
                  "itemNumber": "24498",
                  "quantity": 1,
                  "inventory": {
                      "description": "Lilac Small",
                      "cost": 10,
                      "retail": 20
                  }
              },
              {
                  "itemNumber": "24486",
                  "quantity": 1,
                  "inventory": {
                      "description": "Nk Test 1 Small",
                      "cost": 10,
                      "retail": 20
                  }
              }
          ]
      },
      {
          "id": 262,
          "title": "Pack of 3 - Small - Lavender",
          "skuNumber": "200624-2",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 41,
          "bundleItems": [
              {
                  "itemNumber": "24497",
                  "quantity": 3,
                  "inventory": {
                      "description": "Forest Large",
                      "cost": 10,
                      "retail": 20
                  }
              }
          ]
      },
      {
          "id": 274,
          "title": "Pack of 3 NK-LGP",
          "skuNumber": "270624-1",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 50,
          "bundleItems": [
              {
                  "itemNumber": "24506",
                  "quantity": 1,
                  "inventory": {
                      "description": "Newkind Green",
                      "cost": 800,
                      "retail": 1000
                  }
              },
              {
                  "itemNumber": "24505",
                  "quantity": 1,
                  "inventory": {
                      "description": "Newkind Lavendar",
                      "cost": 800,
                      "retail": 1000
                  }
              },
              {
                  "itemNumber": "24507",
                  "quantity": 5,
                  "inventory": {
                      "description": "Newkind Pink",
                      "cost": 800,
                      "retail": 1000
                  }
              }
          ]
      },
      {
          "id": 280,
          "title": "DELETE",
          "skuNumber": "080724-1",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 40,
          "bundleItems": [
              {
                  "itemNumber": "24513",
                  "quantity": 3,
                  "inventory": {
                      "description": "F Large",
                      "cost": 10,
                      "retail": 20
                  }
              },
              {
                  "itemNumber": "24506",
                  "quantity": 4,
                  "inventory": {
                      "description": "Newkind Green",
                      "cost": 800,
                      "retail": 1000
                  }
              },
              {
                  "itemNumber": "24512",
                  "quantity": 2,
                  "inventory": {
                      "description": "C Large",
                      "cost": 10,
                      "retail": 20
                  }
              }
          ]
      },
      {
          "id": 281,
          "title": "FoCa Large",
          "skuNumber": "090724-1",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 57,
          "bundleItems": [
              {
                  "itemNumber": "24515",
                  "quantity": 3,
                  "inventory": {
                      "description": "Ca Large",
                      "cost": 10,
                      "retail": 20
                  }
              },
              {
                  "itemNumber": "24514",
                  "quantity": 3,
                  "inventory": {
                      "description": "Fo Large",
                      "cost": 10,
                      "retail": 20
                  }
              }
          ]
      },
      {
          "id": 282,
          "title": "FoCa Medium",
          "skuNumber": "090724-2",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 43,
          "bundleItems": [
              {
                  "itemNumber": "24516",
                  "quantity": 2,
                  "inventory": {
                      "description": "Ca Medium",
                      "cost": 10,
                      "retail": 20
                  }
              },
              {
                  "itemNumber": "24517",
                  "quantity": 2,
                  "inventory": {
                      "description": "Fo Medium",
                      "cost": 10,
                      "retail": 20
                  }
              }
          ]
      },
      {
          "id": 283,
          "title": "pack of two",
          "skuNumber": "160724-1",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 75,
          "bundleItems": [
              {
                  "itemNumber": "24520",
                  "quantity": 2,
                  "inventory": {
                      "description": "F Single",
                      "cost": 50,
                      "retail": 100
                  }
              },
              {
                  "itemNumber": "24518",
                  "quantity": 3,
                  "inventory": {
                      "description": "Item 0907",
                      "cost": 30,
                      "retail": 50
                  }
              }
          ]
      },
      {
          "id": 284,
          "title": "Pack of 2 test",
          "skuNumber": "220724-1",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 62,
          "bundleItems": [
              {
                  "itemNumber": "24506",
                  "quantity": 1,
                  "inventory": {
                      "description": "Newkind Green",
                      "cost": 800,
                      "retail": 1000
                  }
              },
              {
                  "itemNumber": "24505",
                  "quantity": 1,
                  "inventory": {
                      "description": "Newkind Lavendar",
                      "cost": 800,
                      "retail": 1000
                  }
              }
          ]
      },
      {
          "id": 285,
          "title": "Pack of 2",
          "skuNumber": "250724-1",
          "shopifyVariantId": null,
          "shopifyLocationId": null,
          "shopifyInventoryId": null,
          "shopifyQuantity": 62,
          "bundleItems": [
              {
                  "itemNumber": "24505",
                  "quantity": 1,
                  "inventory": {
                      "description": "Newkind Lavendar",
                      "cost": 800,
                      "retail": 1000
                  }
              },
              {
                  "itemNumber": "24506",
                  "quantity": 1,
                  "inventory": {
                      "description": "Newkind Green",
                      "cost": 800,
                      "retail": 1000
                  }
              }
          ]
      }
  ]);
    const [selectedBundles, setSelectedBundles] = useState([]);
    const [selectAllBundles,setSelectAllBundles] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
    
        getInventoryBundlesListLoading,
        getInventoryBundlesListResp,
        inventoryErrorResp,
        deleteBundleItemResp
      } = useSelector(
        ({ inventory }) => ({
        getInventoryBundlesListResp: inventory.getInventoryBundlesListResp,
        deleteBundleItemResp: inventory.deleteBundleItemResp,
        getInventoryBundlesListLoading : inventory.getInventoryBundlesListLoading,
        inventoryErrorResp: inventory.inventoryErrorResp,
        }),
        shallowEqual
    );
    const inventoryResp = useRef({
        prevGetInventoryBundlesListResp:getInventoryBundlesListResp,
        prevInventoryErrorResp: inventoryErrorResp,
        prevDeleteBundleItemResp: deleteBundleItemResp
        
    });
    useEffect(() => {
        const { prevGetInventoryBundlesListResp, prevInventoryErrorResp, prevDeleteBundleItemResp } =
          inventoryResp.current || {};
        if (getInventoryBundlesListResp && prevGetInventoryBundlesListResp !== getInventoryBundlesListResp) {
          const list = [];
          (getInventoryBundlesListResp?.data?.bundles || []).forEach(({ type,title , id}) => {
            list.push({
              type,
              title,
              id
            });
          });
          setBundlesList(list);
          setSearchListOptions(list);
          if(!dropdownBtnClicked.current){
            setBundlesList(getInventoryBundlesListResp?.data?.bundles || [])
          }
          setIsBlocked(false);
          if(isRouteChangeApiCalled.current){
            isRouteChangeApiCalled.current=false;
          }
  
        }
        if (deleteBundleItemResp && prevDeleteBundleItemResp !== deleteBundleItemResp) {
          toast.success("Successfully deleted bundle items");
          getInventoryBundlesListApiCall(true);
        }
        // if (inventoryErrorResp && prevInventoryErrorResp !== inventoryErrorResp) {
        //   const { errors, error, message } = inventoryErrorResp || {};
        //   toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
        // }
        inventoryResp.current.prevGetInventoryBundlesListResp = getInventoryBundlesListResp;
        inventoryResp.current.prevDeleteBundleItemResp = deleteBundleItemResp;
        inventoryResp.current.prevInventoryErrorResp = inventoryErrorResp;
    }, [getInventoryBundlesListResp, inventoryErrorResp, deleteBundleItemResp]);
    const getInventoryBundlesListApiCall = useCallback(
        (showAllSuppliers, page) => {
          if (showAllSuppliers) {
            let params = {
              page: page || 1,
              pageSize: 50,
            };
            dispatch(getInventoryBundlesListAct(generateApiUrl(`get_inventory_bundles_list`), params));

            dropdownBtnClicked.current = true;
          } 
          else if (search) {
            let params = {
              page: 1,
              pageSize: 50,
            };
            if (isNaN(Number(search))) params.title = search;
            dispatch(getInventoryBundlesListAct(generateApiUrl(`get_inventory_bundles_list`), params));
            dropdownBtnClicked.current = false;
          }
        },
        [search]
    );
      useEffect(()=> {
        getInventoryBundlesListApiCall(true);
        setColumnsList(generateBundlesColumns)
      },[])
      useEffect(()=>{
        if (parentRef) {
          parentRef.current = { getInventoryBundlesListApiCall };
        }
      },[parentRef])
      useEffect(() => {
        const debounceFn = setTimeout(() => {
          getInventoryBundlesListApiCall();
        }, 200);
        return () => {
          clearTimeout(debounceFn);
        };
      }, [getInventoryBundlesListApiCall]);
    const handleCheckboxChange = (bundleId) => {
       setSelectedBundles(prevItems => {
          if (prevItems.includes(bundleId)) {
              setSelectAllBundles(false);
              return prevItems.filter(id => id !== bundleId);
          } else {
              return [...prevItems, bundleId];
          }
            
       });
     

    };
    const handleSelectAllChange = (isChecked)=>{
      if(isChecked){
        const bundlesListId = bundlesList.map(item => item.id);
        setSelectAllBundles(true);
        setSelectedBundles(bundlesListId)
      }else{
        setSelectAllBundles(false);
        setSelectedBundles([]);
      }
    }
    useEffect(()=>{
      if(selectedBundles.length > 0){
        if(selectedBundles.length === bundlesList.length){
          setSelectAllBundles(true);
        }
        setRowSelectedIds(selectedBundles);
      }else{
        setRowSelectedIds(selectedBundles);
      }
    },[selectedBundles])
    useEffect(()=>{
      setSelectAllBundles(false)
    },[deleteBundleItemResp])

    useEffect(() => {
      if (history.location?.state?.showCreateBundleItemSuccess) {
        toast.success("Successfully saved the bundle details");
        history.replace();
      } 
    }, []);

  return (
    <Container fluid className={'px-4'}>
        <Row className={'justify-content-center px-3 py-4 wh-bundle-container-bg'} >
            <Col xs='12' className={'wh-bundles-container'}>
                {
                !getInventoryBundlesListLoading && 
                (
                    <ul>
                      <li
                        className='wh-bundle-title-bg px-5 py-2 my-1 d-flex flex-row justify-content-start align-items-center' style={{minHeight:'10px'}}>
                          <label className="checkbox checkbox-primary ml-2 mb-0 pe-3">
                              <input
                                  type="checkbox"
                                  onChange={(e) => handleSelectAllChange(e.target.checked)}
                                  checked={selectAllBundles}
                                  style={{height:'17px',width:'17px'}}
                              />
                              
                          </label>
                          <h4 className='align-self-stretch flex-grow-1 text-center'> Title </h4>
                          <h4 className='align-self-stretch flex-grow-2 text-center pe-3'> Type </h4>

                      </li>
                    {bundlesList.length > 0 ? (bundlesList.map((item,index)=>(
                        <li  key={index}
                        className='wh-bundle-bg px-5 py-2 my-1 d-flex flex-row justify-content-start align-items-center' style={{minHeight:'10px'}}>
                          <label className="checkbox checkbox-primary ml-2 mb-0">
                              <input
                                  type="checkbox"
                                  onChange={() => handleCheckboxChange(item.id)}
                                  checked={selectedBundles.includes(item.id)}
                                  style={{height:'17px',width:'17px'}}
                              />
                              
                          </label>
                        <Link
                        className={'align-self-stretch flex-grow-1 text-center'}
                        to={`/warehouseinventory/bundles-createbundle/${item.id}`}
                        >
                            {item.title}
                        </Link>
                        <div className={'align-self-stretch flex-grow-2 text-center'}>{item.type}</div>
                    </li>
                    ))):(
                      <div className='py-5'>
                        <NoDataComponent />
                      </div>
                    )} 
                    </ul>
                )}
                {getInventoryBundlesListLoading && (
                    <TableLoader />
                )}
            </Col>
        </Row>
    </Container>
  )
}

export default Bundles;
