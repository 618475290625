import React, { useState, useRef, useEffect, useMemo, Fragment, useCallback, useContext } from "react";
import { Col, Container, Input, Label, Row, Button, FormFeedback } from "reactstrap";
import CancelIcon from "../../../../../assets/images/warehouse/salespage/cancel_icon.png";
import PrinterIcon from "../../../../../assets/images/warehouse/salespage/printer_old.png";
import BackIcon from "../../../../../assets/images/warehouse/menu/back.png";
//Utility
import { formatAmount, formatAmountSymbol } from "../../../../../utility/commonutility";
//Constants
import { ccTaxPercent, currencySymbol } from "../../../../../constants";
import ConfirmModal from "../../../../Common/Confirmation";
import WhTableContainer from "../../../../Common/WhTables";
import { generateColumns } from "./helper";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { generateApiUrl } from "../../../../../api/apihelper";
import { acceptPaymentsAnywhereAct, getCustomerAct, getInvoicesOfCustomerAct } from "../../../../../store/customer/customeractions";
import moment from "moment-timezone";
export function getCkValue(payment) {
  const { paymentType, amountPaid, chequeNo } = payment;
  const tax = (ccTaxPercent / 100) * amountPaid;
  if (paymentType === "Credit Card") {
    return `@${ccTaxPercent}% = ${currencySymbol}${(Number(amountPaid) + tax).toFixed(2)}`;
  } else if (paymentType === "Check" || paymentType === "UPI" || paymentType === "BANKTRANSFER") return chequeNo;
  else return "";
}

const PaymentsModalBody = ({
  customerDetails,
  getMaxInvoiceNoResp,
  id,
  // totalAmount,
  validation,
  togglePaymentsModal,
  prevBalance,
  adjustInBalance,
  setAdjustInBalance,
  // tax,
  // freight,
  // sgst,
  // cgst,
  // igst,
  // other,
  // totalDiscount,
  isCreditHold,
  isBillToBill,
  currentBalance,
  change,
  totalPaid,
  totalAllocated,
  status,
  isDownloadBtnClicked,
  shouldDownloadPdf,
  paymentTypes,
  customerCreditLimit,
  toast,
  permissions,
  balanceDue,
  invTotal,
  setUtilizedIsChecked,
  utilizedCustBal,
  setInvoicesForPayOrder,
  invoicesForPayOrder
}) => {

  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  // const [invoicesForPayOrder, setInvoicesForPayOrder] = useState([]);
  const { customerNumber, companyName } = customerDetails;
  const inputRefs = useRef([]);
  const silentBtnClickedRef = useRef(false);
  const allowExtraPayRef = useRef(false);
  const [rows, setRows] = useState(0);
  const [isConfirm,setIsConfirm] = useState(false);
  const [activeIndex,setActiveIndex] = useState(0);
  const handleKeyDown = (event, rowIndex, fieldName) => {
    const value = event.target.value.trim();
    if (event.key === 'Enter') {
      event.preventDefault();
      if( totalPaid > balanceDue && !allowExtraPayRef.current){
          setActiveIndex(rowIndex)
          // if(!allowExtraPayRef.current){
            setIsConfirm(true)
          // }
          setTimeout(()=> {
            if(!allowExtraPayRef.current){
              allowExtraPayRef.current = true;
            }
          },100)   
      } else if (fieldName === 'chequeNo' && isLastFieldInLastRow(rowIndex, fieldName)) {
        addNewRowAndFocusFirstField(rowIndex);
      } else if(fieldName === 'paymentType'){
        focusNextInput(rowIndex, fieldName,value);
      } else {
        focusNextInput(rowIndex, fieldName);
      }
    }
     
  };
  const addNewRowAndFocusFirstField = (rowIndex) => {
    
    addNewPaymentsRow();
    setRows(rows+1);
    
    setTimeout(() => {
      
      focusFirstFieldOfRow(rowIndex + 1);
    }, 0);
  };
  const isLastFieldInLastRow = (rowIndex, fieldName) => {
    return rowIndex === rows && fieldName === 'chequeNo';
  };
  const focusNextInput = (rowIndex, fieldName,value='default') => {
    const fields = ['amountPaid', 'amountAlloc', 'paymentType', 'chequeNo', 'holdDate'];
  
    const currentFieldIndex = fields.indexOf(fieldName);
    let nextFieldName = fields[currentFieldIndex + 1];
  
    if (fieldName === 'amountPaid') {
      
      nextFieldName = 'paymentType';
    }
    if ((value === 'Cash' || value === 'Debit Card' || value === 'Credit Card') && fieldName === 'paymentType') {
      return addNewRowAndFocusFirstField(rowIndex);
    }
  
    if (nextFieldName) {
      const nextFieldRef = inputRefs.current[rowIndex][nextFieldName];
      if (nextFieldRef) {
        nextFieldRef.focus();
      }
    }
  };
  
  const focusFirstFieldOfRow = (rowIndex) => {
    
    const firstFieldName = 'amountPaid'; 
    if (inputRefs.current[rowIndex] && inputRefs.current[rowIndex][firstFieldName]) {
      
      inputRefs.current[rowIndex][firstFieldName].focus();
    }
  };
  useEffect(() => {
    focusFirstFieldOfRow(0);
    setInvoicesForPayOrder([]);
  }, []); 
 
  const addNewPaymentsRow = () => {
    validation.setValues((prev) => ({ 
      ...prev,
      payments: [
        ...prev.payments,
        {
          amountAlloc: "",
          amountPaid: "",
          paymentType: "",
          chequeNo: "",
          holdDate: "",
          tax: "",
        },
      ],
    }));
    
  };
  const removePaymentsRow = (index) => {
    const updatedPayments = [...validation.values.payments];
    updatedPayments.splice(index, 1);
    validation.setValues((prev) => ({ ...prev, payments: updatedPayments }));
    setRows(rows-1)
  };

  const paymentsChngHndlr = (e, index) => {
    const { value, name } = e.target;
    const updatedPayments = [...validation.values.payments];
    updatedPayments.splice(index, 1, { ...updatedPayments[index], [name]: value });
    if (name === "amountPaid") {
      let totalRemaining = balanceDue;
      for (let i in updatedPayments) {
        const { amountPaid } = updatedPayments[i];
        if (totalRemaining - amountPaid >= 0) {
          updatedPayments[i].amountAlloc = amountPaid;
          totalRemaining -= amountPaid;
        } else if (totalRemaining > 0 && totalRemaining < amountPaid) {
          updatedPayments[i].amountAlloc = totalRemaining;
          totalRemaining = 0;
        } else updatedPayments[i].amountAlloc = 0;
      }
    }
    validation.setValues((prev) => ({ ...prev, payments: updatedPayments }));
  };
  useEffect(() => {
    if (id === "new" || status === "open") {
      const updatedPayments = [...validation.values.payments];
      let totalRemaining = balanceDue;
      for (let i in updatedPayments) {
        const { amountPaid } = updatedPayments[i];
        if (adjustInBalance) {
          updatedPayments[i].amountAlloc = amountPaid;
        } else if (totalRemaining - amountPaid >= 0) {
          updatedPayments[i].amountAlloc = amountPaid;
          totalRemaining -= amountPaid;
        } else if (totalRemaining > 0 && totalRemaining < amountPaid) {
          updatedPayments[i].amountAlloc = totalRemaining;
          totalRemaining = 0;
        } else updatedPayments[i].amountAlloc = 0;
      }
      validation.setValues((prev) => ({ ...prev, payments: updatedPayments }));
    }
  }, [adjustInBalance]);
  const handleCreditMethodChange = (e) => {
    const { name, checked } = e.target;
    validation.setValues((prev) => ({
      ...prev,
      paymentMethod: "",
      creditCustomersBalance: name === "creditCustomer" ? checked : !checked,
    }));
  };
  const handleInvoiceCreation = (e, isPrintInvoice) => {
    if( !silentBtnClickedRef.current && totalPaid > balanceDue && !(balanceDue < 0)){
        setIsConfirm(true)
    } 
    else if (balanceDue - totalAllocated > 0 && !permissions["MAKE_CREDITS"]) {
      toast.error("You have not the permission to make credits.");
    } else if ((isCreditHold ? totalAllocated < balanceDue : false)){
        toast.error("Please Pay full invoice amount."); 
    } else if ((customerCreditLimit || customerCreditLimit === 0) && -change > customerCreditLimit) {
      toast.error(`Customer credit limit is $${customerCreditLimit}, cannot have balance more than that`);
    } else if ((prevBalance !== 0) && (totalPaid < balanceDue) && !utilizedCustBal) {
      toast.error(`Please Pay full invoice amount.`);
    } else {
      validation.handleSubmit(e);
      isDownloadBtnClicked.current = true
      shouldDownloadPdf.current = isPrintInvoice;
      // if(utilizedCustBal && balanceDue < 0 && customerNumber && (invoicesForPayOrder.length > 0)){
      //   const paymentsArr = [];
      // dispatch(
      //   acceptPaymentsAnywhereAct(generateApiUrl(`accept_payments_anywhere`,{customerNumber}), {
      //     invoices: invoicesForPayOrder,
      //     payments: paymentsArr,
      //     creditCustomersBalance: validation.values.creditCustomersBalance,
      //     paymentMethodForPayCustomer: validation.values.paymentMethod,
      //     currentDate: moment()
      //   })
      // );
      // }
    }
    // if(silentBtnClickedRef.current || (totalPaid < balanceDue) || (balanceDue < 0)){
    //   if (balanceDue - totalAllocated > 0 && !permissions["MAKE_CREDITS"]) {
    //     toast.error("You have not the permission to make credits.");
    //   } else if ((isCreditHold ? totalAllocated < balanceDue : false)){
    //       toast.error("Please Pay full invoice amount."); 
    //   } else if ((customerCreditLimit || customerCreditLimit === 0) && -change > customerCreditLimit) {
    //     toast.error(`Customer credit limit is $${customerCreditLimit}, cannot have balance more than that`);
    //   } else {
    //     validation.handleSubmit(e);
    //     isDownloadBtnClicked.current = true
    //     shouldDownloadPdf.current = isPrintInvoice;
    //   }
    // } else if(totalPaid > balanceDue){
    //   setIsConfirm(true)
    // } 
  };

  // const sgstAmount = useMemo(() => (totalAmount * sgst) / 100, [
  //   totalAmount,
  //   sgst,
  // ]);

  // const cgstAmount = useMemo(() => (totalAmount * cgst) / 100, [
  //   totalAmount,
  //   cgst,
  // ]);

  // const igstAmount = useMemo(() => (totalAmount * igst) / 100, [
  //   totalAmount,
  //   cgst,
  // ]);

  // const subtotal = useMemo(() => totalAmount + sgstAmount + cgstAmount + igstAmount,
  //  [
  //   totalAmount,
  //   sgstAmount,
  //   cgstAmount,
  //   igstAmount,
  // ]);

  // const invTotalAmtTemp = useMemo(
  //   () => subtotal + Number(tax) + Number(freight) + Number(other) - totalDiscount,
  //   [subtotal, tax, freight, totalDiscount, other]
  // );
  // const invTotal = useMemo(()=> Math.round(invTotalAmtTemp),[invTotalAmtTemp]);
  // const balanceDue = useMemo(() => prevBalance + invTotal, [prevBalance, invTotal]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'F5') {
        const button = document.getElementById('PrintInvoice');  
        if (button && (isCreditHold ? (totalAllocated >= balanceDue) : false)) { 
          toast.error("This customer does not allow for Credit hold.");
        }
        else if(button && (isBillToBill ? totalAllocated < currentBalance : false)){
          toast.error(`Please clear Previous invoice first $${currentBalance.toFixed(2)}.`);
        } 
        else {
          button.click();
        }
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isCreditHold, toast, balanceDue, totalAllocated, isBillToBill, currentBalance]);
 
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'F4') {
        const button = document.getElementById('SilentInvoice');  
        if (button && (isCreditHold ? (totalAllocated >= balanceDue) : false)) {
          toast.error("This customer does not allow for Credit hold.");
        }
        else if(button && (isBillToBill ? totalAllocated < currentBalance : false)){
          toast.error(`Please clear Previous invoice first $${currentBalance.toFixed(2)}.`);
        } 
        else {
          button.click();
        }
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isCreditHold, toast, balanceDue, totalAllocated, isBillToBill, currentBalance]);

  const onConfirmClickHndr = () => {
    setIsConfirm(false);
    setTimeout(()=>{
        if(!silentBtnClickedRef.current){
          silentBtnClickedRef.current = true;
        }
    },400)
    setTimeout(()=>{
      focusNextInput(activeIndex,"amountPaid");
    },500);
  }

  const onCloseClickHndlr = () => {
    const e = {
      target: {
        value : 0.00,
        name : 'amountPaid'
      }
    }
    setTimeout(()=>{
      if(!silentBtnClickedRef.current){
        silentBtnClickedRef.current = false;
      }
  },400)
  setTimeout(()=> {
    if(allowExtraPayRef.current){
      allowExtraPayRef.current = false;
    }
  },100)
    setTimeout(() => {
      if(inputRefs.current[activeIndex]){
        inputRefs.current[activeIndex].amountPaid.value = ''
        paymentsChngHndlr(e,activeIndex)
        
      }
    },200)
    setIsConfirm(false);
  }

  const amountPaidBlur = (e,index) => {
    let { value } = e.target;
    value = Number(value).toFixed(2);
    setTimeout(() => {
      if(inputRefs.current[index]){
        inputRefs.current[index].amountPaid.value = value;
      }
    },200)
  }

  useEffect(() => {
    if(balanceDue < 0){
      setTimeout(()=>{
        if(!silentBtnClickedRef.current){
          silentBtnClickedRef.current = true;
        }
      },200)
    }
  },[])

  const { getAllCustomersResp, getInvoicesOfCustomerResp, acceptPaymentsAnywhereResp, getCustomerResp, customerErrResp } = useSelector(
    ({ customer }) => ({
      getAllCustomersResp: customer.getAllCustomersResp,
      getInvoicesOfCustomerResp: customer.getInvoicesOfCustomerResp,
      acceptPaymentsAnywhereResp: customer.acceptPaymentsAnywhereResp,
      getCustomerResp: customer.getCustomerResp,
      customerErrResp: customer.customerErrResp,
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetAllCustomersResp: getAllCustomersResp,
    prevGetInvoicesOfCustomerResp: getInvoicesOfCustomerResp,
    prevAcceptPaymentsAnywhereResp: acceptPaymentsAnywhereResp,
    prevGetCustomerResp: getCustomerResp,
    prevCustomerErrResp: customerErrResp,
  });
  useEffect(() => {
    const { prevGetAllCustomersResp, prevGetInvoicesOfCustomerResp, prevGetCustomerResp, prevAcceptPaymentsAnywhereResp, prevCustomerErrResp } = apiResp.current;
    // if (getAllCustomersResp && prevGetAllCustomersResp !== getAllCustomersResp && showModal) {
    //   const list = [];
    //   (getAllCustomersResp?.data?.customers || []).forEach(({ customerNumber, firstName, lastName, companyName }) => {
    //     list.push({
    //       customerNumber,
    //       customerName: `${firstName || ""} ${lastName || ""}`,
    //       companyName,
    //     });
    //   });
    //   setSearchListOptions(list);
    // }
    if (getInvoicesOfCustomerResp && prevGetInvoicesOfCustomerResp !== getInvoicesOfCustomerResp) {
      setTableData(getInvoicesOfCustomerResp.data.invoices);
    }
    // if (getCustomerResp && prevGetCustomerResp !== getCustomerResp) {
    //   const {
    //     data: {
    //       customer: { firstName, lastName, companyName, customerNumber, currentBalance },
    //     },
    //   } = getCustomerResp;
      // setCustomersBalance(currentBalance);
      // setCustomerNumber(customerNumber);
      // setSearch(`${companyName || ""}${firstName || lastName ? "-" : ""}${firstName || ""} ${lastName || ""}`);
    // }
    // if (acceptPaymentsAnywhereResp && prevAcceptPaymentsAnywhereResp !== acceptPaymentsAnywhereResp && showModal) {
    //   toast.success("Successfully accepted the payments");
    //   togglePaymentModal();
    // }
    if (customerErrResp && prevCustomerErrResp !== customerErrResp) {
      const { errors, error, message } = customerErrResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    apiResp.current.prevGetAllCustomersResp = getAllCustomersResp;
    apiResp.current.prevGetInvoicesOfCustomerResp = getInvoicesOfCustomerResp;
    apiResp.current.prevGetCustomerResp = getCustomerResp;
    apiResp.current.prevAcceptPaymentsAnywhereResp = acceptPaymentsAnywhereResp;
    apiResp.current.prevCustomerErrResp = customerErrResp;
  }, [getAllCustomersResp, getInvoicesOfCustomerResp, getCustomerResp, acceptPaymentsAnywhereResp, customerErrResp]);


  const handlePageChange = (page, pageSize) => {
    dispatch(
      getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
        customerNumber: customerNumber,
        status: "unpaid",
        page: page + 1,
        pageSize,
      })
    );
  };
  useEffect(() => {
    if(utilizedCustBal && customerNumber) {
      dispatch(
        getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
          customerNumber: customerNumber,
          status: "unpaid",
          posted: true,
          page: 1,
          pageSize: 10,
        })
      );
    }
  }, [customerNumber,utilizedCustBal,balanceDue]);

  useEffect(()=>{
    if(!utilizedCustBal){
      setInvoicesForPayOrder([]);
    }
  },[utilizedCustBal])
  // function handleAcceptPaymentsAnywhere() {
  //   if(utilizedCustBal && balanceDue < 0 && customerNumber && (invoicesForPayOrder.length > 0)){
  //     console.log('heloo')
  //     const paymentsArr = [];
  //   // for (let i in payments) {
  //   //   const { amountPaid, amountAlloc } = payments[i];
  //   //   paymentsArr.push({ ...payments[i], change: adjustInBalance ? 0 : amountPaid - amountAlloc, comments: getCkValue(payments[i])});
  //   // }
  //   dispatch(
  //     acceptPaymentsAnywhereAct(generateApiUrl(`accept_payments_anywhere`,{customerNumber}), {
  //       invoices: invoicesForPayOrder,
  //       payments: paymentsArr,
  //       creditCustomersBalance: validation.values.creditCustomersBalance,
  //       paymentMethodForPayCustomer: validation.values.paymentMethod,
  //       currentDate: moment()
  //     })
  //   );
  //   }
  // }

  // useEffect(() => {
  //   if(payToRemainingInv){
  //     handleAcceptPaymentsAnywhere();
  //   }
  //   setPayToRemainingInv(false);
  // },[payToRemainingInv])
  const columns = useMemo(() => generateColumns(invoicesForPayOrder, setInvoicesForPayOrder), [invoicesForPayOrder, setInvoicesForPayOrder]);
  return (
    <>
      <Container className="px-0">
      <Row className="m-0">
        <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
          General Payments
        </Col>
      </Row>
      <Row className="p-3 m-0 border-bottom">
        <Col xs={8}>
          <Row className="mb-3">
            <Col xs="4">
              <div className="fs-4 fs-lg-3 fw-medium d-flex flex-column me-3">
                <span className="mb-1">{customerNumber}</span>
                <span>{companyName}</span>
              </div>
            </Col>
            <Col xs="8">
              <div className="d-flex align-items-center me-5 flex-grow-1">
                <div
                  className="fs-5 fs-lg-4 fw-bold d-flex align-items-center cancledrag justify-content-center p-3 px-5 wh-item-bg"
                  // style={{ background: "#FFC10754" }}
                >
                  {id === "new" ? getMaxInvoiceNoResp?.data?.invoiceNumber || "" : id}
                </div>
              </div>
            </Col>
          </Row>
          {
            (<Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Customer Prev. Balance :</Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="invoiceTotal"
                  id="invoiceTotal"
                  className="form-control cancledrag"
                  type="text"
                  readOnly
                  value={formatAmount(prevBalance.toFixed(2))}
                />
              </Col>
              <Col xs={2} className={'d-flex flex-row'}>
                    <div className="form-check ms-2">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        name="prevBalance"
                        id="prevBalance"
                        checked={utilizedCustBal}
                        onChange={() => setUtilizedIsChecked(!utilizedCustBal)}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="prevBalance">
                        Utilizing
                      </Label>
                    </div>
              </Col>
            </Row>)
          }
          <Row className="mb-3">
            <Col xs={4} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Invoice Total :</Label>
            </Col>
            <Col xs={8}>
              <Input
                name="invoiceTotal"
                id="invoiceTotal"
                className="form-control cancledrag"
                type="text"
                readOnly
                value={formatAmount(invTotal.toFixed(2))}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Balance Due :</Label>
            </Col>
            <Col xs={8}>
              <Input
                name="invoiceTotal"
                id="invoiceTotal"
                className="form-control cancledrag"
                type="text"
                readOnly
                value={formatAmount(balanceDue.toFixed(2))}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Button
           id="PrintInvoice"
            color="primary"
            className="w-100 mb-2 wh-btn"
            outline
            onClick={(e) => {
              handleInvoiceCreation(e, true);
            }}
            // disabled={status === "posted"}
            disabled={status === "posted" || (isBillToBill ? totalAllocated < currentBalance : false) || (isCreditHold ? totalAllocated < balanceDue : false)}
          >
            <span className="d-flex justify-content-center align-items-center">
              <img src={PrinterIcon} alt="" style={{ height: "16px", width: "16px" }} />
              <span className="ms-2">Print Invoice - F5</span>
            </span>
          </Button>
          <Button
           id="SilentInvoice"
            color="primary"
            className="w-100 mb-2 wh-btn"
            outline
            onClick={(e) => {
              handleInvoiceCreation(e, false);
            }}
            disabled={status === "posted" || (isBillToBill ? totalAllocated < currentBalance : false) || (isCreditHold ? totalAllocated < balanceDue : false)}
          >
            <span className="d-flex justify-content-center align-items-center">
              <span className="ms-2">Silent - F4</span>
            </span>
          </Button>
          <Button color="danger" className="w-100 mb-2 wh-btn-danger" outline onClick={togglePaymentsModal}>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={CancelIcon} alt="" style={{ height: "16px", width: "16px" }} />
              <span className="ms-2">Close - F12</span>
            </span>
          </Button>
        </Col>
      </Row>
      {balanceDue >= 0 && (
        <Row className="p-3 m-0 border-bottom">
          <h4>Collect Payments</h4>
          <Col xs="12 pt-4 mb-3">
            <div className="d-flex mb-2">
              <div className="w-18 ">
                <Label className="fw-bold text-center w-100">Amount Paid</Label>
              </div>
              <div className="w-18">
                <Label className="fw-bold text-center w-100">Amount Allocated</Label>
              </div>
              <div className="w-18">
                <Label className="fw-bold text-center w-100">Payment Type</Label>
              </div>
              <div className="w-18">
                <Label className="fw-bold text-center w-100">CK/Card/UPI/Acc Number</Label>
              </div>
              {/* <div className="w-18">
                <Label className="fw-bold text-center w-100">Hold date</Label>
              </div> */}
              <div className="w-10"></div>
            </div>
            {validation.values.payments.map((item, index) => (
              <div key={index} className="d-flex mb-2">
                <div className="w-18 pe-2 cancledrag">
                  <div className="form-icon">
                    <Input
                      innerRef={(input) => {
                        if (!inputRefs.current[index]) {
                          inputRefs.current[index] = {};
                        }
                        inputRefs.current[index]['amountPaid'] = input;
                      }}
                      className="form-control form-control-icon"
                      placeholder="0.00"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="amountPaid"
                      // value={(validation.values.payments[activeIndex]?.amountPaid === 'NaN')? 0.00 : validation.values.payments[activeIndex]?.amountPaid}
                      
                      onChange={(e) => paymentsChngHndlr(e, index)}
                      onBlur={(e) => amountPaidBlur(e,index)}
                      readOnly={status === "posted"}
                      invalid={
                        Array.isArray(validation.touched.payments) &&
                        validation.touched.payments[index]?.amountPaid &&
                        Array.isArray(validation.errors.payments) &&
                        validation.errors.payments[index]?.amountPaid
                          ? true
                          : false
                      }
                      // onKeyDown={(e) => handleKeyDown(e, paymentTypeRef, index)}
                      onKeyDown={(e) => handleKeyDown(e, index, 'amountPaid')}
                    />
                    <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                  </div>
                  {Array.isArray(validation.touched.payments) &&
                    validation.touched.payments[index]?.amountPaid &&
                    Array.isArray(validation.errors.payments) &&
                    validation.errors.payments[index]?.amountPaid && (
                      <div className="custom-invalid-feedback">{validation.errors.payments[index].amountPaid}</div>
                    )}
                </div>
                <div className="w-18 px-2 cancledrag">
                  <div className="form-icon">
                    <Input
                    innerRef={(input) => {
                      if (!inputRefs.current[index]) {
                        inputRefs.current[index] = {};
                      }
                      inputRefs.current[index]['amountAlloc'] = input;
                    }}
                      className="form-control form-control-icon"
                      placeholder="0.00"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      readOnly={true}
                      value={Number(validation.values.payments[index]["amountAlloc"]).toFixed(2)}
                      // invalid={validation.touched.paymentType && validation.errors.paymentType ? true : false}
                      onKeyDown={(e) => handleKeyDown(e, index, 'amountAlloc')}
                      
                    />
                    <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                  </div>
                </div>
                <div className="w-18 px-2 cancledrag">
                  <Input
                  innerRef={(input) => {
                    if (!inputRefs.current[index]) {
                      inputRefs.current[index] = {};
                    }
                    inputRefs.current[index]['paymentType'] = input;
                  }}
                    type="select"
                    className="form-select"
                    name="paymentType"
                    value={validation.values.payments[index].paymentType}
                    onChange={(e) => paymentsChngHndlr(e, index)}
                    disabled={status === "posted"}
                    invalid={
                      Array.isArray(validation.touched.payments) &&
                      validation.touched.payments[index]?.paymentType &&
                      Array.isArray(validation.errors.payments) &&
                      validation.errors.payments[index]?.paymentType
                        ? true
                        : false
                    }
                    onKeyDown={(e) => handleKeyDown(e, index, 'paymentType')}
                  >
                    <option value="">Select</option>
                    {paymentTypes.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {Array.isArray(validation.touched.payments) &&
                    validation.touched.payments[index]?.paymentType &&
                    Array.isArray(validation.errors.payments) &&
                    validation.errors.payments[index]?.paymentType && (
                      <div className="custom-invalid-feedback">{validation.errors.payments[index]?.paymentType}</div>
                    )}
                </div>
                <div className="w-18 px-2 cancledrag">
                  {validation.values.payments[index].paymentType === "Check" || validation.values.payments[index].paymentType === "UPI" || validation.values.payments[index].paymentType === "BANKTRANSFER" ? (
                    <>
                      <Input
                        innerRef={(input) => {
                          if (!inputRefs.current[index]) {
                            inputRefs.current[index] = {};
                          }
                          inputRefs.current[index]['chequeNo'] = input;
                        }}
                        type="text"
                        onWheel={(e) => e.target.blur()}
                        name="chequeNo"
                        value={validation.values.payments[index].chequeNo}
                        onChange={(e) => paymentsChngHndlr(e, index)}
                        readOnly={status === "posted"}
                        invalid={
                          Array.isArray(validation.touched.payments) &&
                          validation.touched.payments[index]?.chequeNo &&
                          Array.isArray(validation.errors.payments) &&
                          validation.errors.payments[index]?.chequeNo
                            ? true
                            : false
                        }
                        onKeyDown={(e) => handleKeyDown(e, index, 'chequeNo')}
                      />
                      {Array.isArray(validation.touched.payments) &&
                        validation.touched.payments[index]?.chequeNo &&
                        Array.isArray(validation.errors.payments) &&
                        validation.errors.payments[index]?.chequeNo && (
                          <div className="custom-invalid-feedback">{validation.errors.payments[index].chequeNo}</div>
                        )}
                    </>
                  ) : (
                    <Input readOnly type="text" value={getCkValue(validation.values.payments[index])} />
                  )}
                </div>
                {/* <div className="w-18 ps-2 cancledrag">
                  <Input
                  innerRef={(input) => {
                    if (!inputRefs.current[index]) {
                      inputRefs.current[index] = {};
                    }
                    inputRefs.current[index]['holdDate'] = input;
                  }}
                    type={status !== "posted" ? "date" : "text"}
                    name="holdDate"
                    value={validation.values.payments[index].holdDate}
                    onChange={(e) => paymentsChngHndlr(e, index)}
                    readOnly={status === "posted"}
                    onKeyDown={(e) => handleKeyDown(e, index, 'holdDate')}
                  />
                </div> */}
                {status !== "posted" && (
                  <div className="w-10 d-flex align-items-center justify-content-center fs-5">
                    <i className="ri-delete-bin-line text-danger" onClick={() => removePaymentsRow(index)}></i>
                  </div>
                )}
              </div>
            ))}
            {status !== "posted" && (
              <Button color="primary" onClick={addNewPaymentsRow}>
                + Add New
              </Button>
            )}
          </Col>
        </Row>
      )}
      {utilizedCustBal && tableData.length > 0 && (
        <Row className="p-3 pt-1 m-0 border-bottom">
        <h4>Unpaid Invoices</h4>
        <Col xs="12 mb-1">
        <WhTableContainer
          columns={columns}
          data={tableData || []}
          className="custom-header-css"
          divClass="table-responsive mt-1"
          tableClass="align-middle table-bordered"
          theadClass="table-light"
          handlePageChange={handlePageChange}
          pageCount={Math.ceil((getInvoicesOfCustomerResp?.data?.total || 0) / 10)}
          customPageSize={30}
          showNoDataComponent={true}
        />
        </Col>
      </Row>
      )}
      {balanceDue < 0 && (
        <Fragment>
        <Row className="p-3 m-0 border-bottom">
          <h4>Select Credit Method</h4>
          <Col xs="12 pt-3 mb-3">
            <div 
              className="d-flex align-items-center mb-3 form-radio-outline form-check form-radio-primary"
              onClick={()=>{
                if(!permissions["RETURN_PAYMENTS"]){
                  toast.error("You have not the permission to make return payments.")
                  return
                }
              }}
              >
              <Input
                type="radio"
                className="form-check-input my-0 me-2"
                checked={validation.values.creditCustomersBalance && permissions['RETURN_PAYMENTS']}
                onChange={handleCreditMethodChange}
                name="creditCustomer"
                disabled={!permissions["RETURN_PAYMENTS"]}
              />
              <Label className="my-0">Credit Customer's Balance</Label>
            </div>
            <div 
            className="d-flex align-items-center mb-3 form-radio-outline form-check form-radio-primary"
            onClick={()=>{
              if(!permissions["RETURN_PAYMENTS"]){
                toast.error("You have not the permission to make return payments.")
                return
              }
            }}
            >
              <Input
                type="radio"
                className="form-check-input my-0 me-2 cancledrag"
                checked={!validation.values.creditCustomersBalance && permissions['RETURN_PAYMENTS'] }
                onChange={handleCreditMethodChange}
                name="payCustomer"
                disabled={!permissions["RETURN_PAYMENTS"]}
              />
              <Label className="my-0 f">Pay Customer : {currencySymbol}{balanceDue.toFixed(2)}</Label>
            </div>
          </Col>
          <Col xs="8">
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Payment Method :</Label>
              </Col>
              <Col xs={8}>
                <Input
                  name="paymentMethod"
                  id="paymentMethod"
                  className="form-control form-control-icon"
                  placeholder="0.00"
                  type="select"
                  value={validation.values.paymentMethod}
                  onChange={validation.handleChange}
                  disabled={validation.values.creditCustomersBalance}
                >
                  <option value="">Select</option>
                  <option>Cash</option>
                  <option>Credit Card</option>
                  <option>Debit Card</option>
                  <option>UPI </option>
                  <option>Bank Transfer</option>
                </Input>
              </Col>
            </Row>
          </Col>
        </Row>
        </Fragment>
      )}
      {balanceDue >= 0 && (
        <Row className="p-3 m-0">
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Total Allocated :</Label>
              </Col>
              <Col xs={8}>
                <Input
                  className="form-control cancledrag"
                  placeholder="0.00"
                  type="text"
                  readOnly
                  value={formatAmount(totalAllocated.toFixed(2))}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Total Paid :</Label>
              </Col>
              <Col xs={8}>
                <Input
                  className="form-control cancledrag"
                  placeholder="0.00"
                  type="text"
                  readOnly
                  value={formatAmount(totalPaid.toFixed(2))}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Change :</Label>
              </Col>
              <Col xs={8}>
                <Input
                  name="change"
                  id="change"
                  className="form-control cancledrag"
                  placeholder="0.00"
                  type="text"
                  readOnly
                  value={formatAmount(change.toFixed(2))}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4} className="d-flex align-items-center">
            {status !== "posted" && (
              <div
                onClick={
                  (id === "new" || status === "open") && balanceDue - totalPaid < 0
                    ? () => setAdjustInBalance((prev) => !prev)
                    : () => {}
                }
                className={`d-flex align-items-center mb-3 ${
                  (id === "new" || status === "open") && balanceDue - totalPaid < 0
                    ? "show-cursor-pointer"
                    : "show-cursor-not-allowed opacity-50"
                }`}
              >
                <img src={BackIcon} width="24" height="24" alt="back-icon" />
                <div className="text-decoration-underline mb-0 wh-link-color ms-2">Adjust in Balance</div>
              </div>
            )}
          </Col>
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Balance :</Label>
              </Col>
              <Col xs={8}>
                <Input
                  name="invoiceTotal"
                  id="invoiceTotal"
                  className="form-control cancledrag"
                  placeholder="0.00"
                  type="text"
                  readOnly
                  value={formatAmount((balanceDue - totalAllocated).toFixed(2))}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4} />
        </Row>
      )}
    </Container>
    <ConfirmModal 
      show={isConfirm}
      onConfirmClick={() => onConfirmClickHndr()}
      onCloseClick={() => onCloseClickHndlr()}
      title="Paying extra amount"
      confirmationText="Do you want to continue with this payment"
    />
    </>
  );
};
export default PaymentsModalBody;
