import zohoconstants from "./zohoconstants";

export const getZohoInReportAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: zohoconstants.GET_ZOHO_IN_REPORT_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
  };
  
  export const changeGetZohoInReportLoadingStatusAct = (status) => {
    return {
      type: zohoconstants.CHANGE_GET_ZOHO_IN_REPORT_LOADING_STATUS,
      payload: status,
    };
  };

  export const getZohoInvAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: zohoconstants.GET_ZOHO_INVOICES_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
  };
  
  export const changeGetZohoInvStatusAct = (status) => {
    return {
      type: zohoconstants.CHANGE_GET_ZOHO_INVOICES_LOADING_STATUS,
      payload: status,
    };
  };