import * as Yup from "yup";
import { Input } from "reactstrap";
export const customStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #ccc', 
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white', 
  }),
};

export const inventoryDetailsInitialValues = {
  description: "",
  salesLimit: "",
  category: "",
  manufacturer: "",
  subCategory: "",
  family: "",
  cost: "",
  retail: "",
  profit: "",
  casePack: "",
  unitCharge: "",
  class: "",
  stateReport: "",
  caseQuantity: "",
  palletQuantity: "",
  uCount: "",
  weight: "",
  weightUnit: "",
  asin:"",
  location: "",
  location2: "",
  location3: "",
  location4: "",
  groupCode: "",
  orderQuantity: "",
  model: "",
  iColor: "#777777",
  upcCode: "",
  barcodes: [
    {
      description: "",
      upcCode: "",
      units: "",
    },
  ],
  active: true,
};

export const inventoryDetailsValidationSchema = Yup.object({
  // description: Yup.string().required("Product Name is required"),
  // category: Yup.string().required("Category is required"),
     weightUnit: Yup.string().required("Unit is required"),
  // manufacturer: Yup.string().required("Manufacturer is required"),
  // family: Yup.string().required("Family is required"),
  // caseQuantity: Yup.string().required("Case Quantity is required"),
  // retail: Yup.string().required("Retail Price is required"),
  // class: Yup.string().when("category", {
  //   is: (category) => category === "TOBACCO" || category === "CIGARETTES",
  //   then : Yup.string().required("MSA Report as is required")
  // }),
  // stateReport: Yup.string().when("category", {
  //   is: (category) => category === "TOBACCO" || category === "CIGARETTES",
  //   then : Yup.string().required("State Report as is required")
  // }),
  // uCount: Yup.string().when("category", {
  //   is: (category) => category === "TOBACCO" || category === "CIGARETTES",
  //   then : Yup.string().required("Single Unit MSA is required")
  // }),
  // caseQuantity: Yup.string().required("Case Qty is required"),
  // casePack: Yup.string().required("case pack is required"),
});

export const calculateUnitRetail = (retailprice, casePack) => {
  if (retailprice && casePack) return (Number(retailprice) / Number(casePack) || 0).toFixed(2);
  else return "";
};

export const getDropdownOptions = (options, customOption) => {
  if (customOption) {
    return [...options, { label: customOption, value: customOption }].sort((a, b) => a.label.localeCompare(b.label));
  } else return options;
};

export function generateAddBarcodeColumns(removeRow, fieldChngHndlr) {
  return [
    {
      Header: "Description",
      accessor: "description",
      style: { width: "35%" },
      Cell: ({ row }) => {
        const {
          original: { description },
          index,
        } = row;
        return <Input name="description" value={description} onChange={(e) => fieldChngHndlr(e, index)} />;
      },
    },
    {
      Header: "UPC Scan",
      accessor: "upcCode",
      style: { width: "25" },
      Cell: ({ row }) => {
        const {
          original: { upcCode },
          index,
        } = row;
        return <Input name="upcCode" value={upcCode} onChange={(e) => fieldChngHndlr(e, index)} />;
      },
    },
    {
      Header: "Units",
      accessor: "units",
      style: { width: "15%" },
      Cell: ({ row }) => {
        const {
          original: { units },
          index,
        } = row;
        return <Input name="units" value={units} onChange={(e) => fieldChngHndlr(e, index)} />;
      },
    },
    {
      Header: "Unit Price",
      accessor: "unitPrice",
      style: { width: "15%" },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <i
            className="ri-delete-bin-line text-danger show-cursor-pointer fs-5"
            onClick={() => removeRow(row.index)}
          ></i>
        </div>
      ),
    },
  ];
}

export function conditionalValidationBasedOnCategory(category) {
  if(category === "TOBACCO" || category === "CIGARETTES") return "required";
  else return "";
}
