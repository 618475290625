export const tabsOption = [
    {
        id: "invoice_edit_report",
        name: "Invoice Edit / Void Report"
    },
    // {
    //     id: "msa_report",
    //     name: "MSA Report"
    // },
    {
        id: "inventory_detecion",
        name: "Inventory Deduction"
    }
]