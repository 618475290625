import { call, takeLatest, delay, put } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import zohoconstants from "./zohoconstants";
import { changeGetZohoInReportLoadingStatusAct, changeGetZohoInvStatusAct } from "./zohoactions";

function* getZohoInReportGen(action) {
    yield put(changeGetZohoInReportLoadingStatusAct(true));
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: zohoconstants.GET_ZOHO_IN_REPORT_SUCCESS,
      failAction: zohoconstants.GET_ZOHO_IN_REPORT_FAIL,
    });
    yield delay(500);
    yield put(changeGetZohoInReportLoadingStatusAct(false));
  }

function* getZohoInvGen(action) {
    yield put(changeGetZohoInvStatusAct(true));
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: zohoconstants.GET_ZOHO_INVOICES_SUCCESS,
      failAction: zohoconstants.GET_ZOHO_INVOICES_FAIL,
    });
    yield delay(500);
    yield put(changeGetZohoInvStatusAct(false));
  }

  export function* zohoSaga() {
    
    yield takeLatest(zohoconstants.GET_ZOHO_IN_REPORT_REQUEST, getZohoInReportGen);
    yield takeLatest(zohoconstants.GET_ZOHO_INVOICES_REQUEST, getZohoInvGen);

  }