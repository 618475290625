import React, { useRef, useState, useEffect } from "react";
import { Input } from "reactstrap";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
export default function ClickedInputDate({
  inputType,
  value = moment().format('YYYY-MM-DD'),
  itemKey,
  fieldChangeHndlr,
  index,
  
  containerClass = "",
  onKeyDown = () => {},
  
  addObserver = false,
  allowEdit,
  innerInputClass="",
  inputStyle,
}) {
  const [inputValue, setInputValue] = useState(value);
  const [showValueState, setShowValueState] = useState(false);
  const ref = useRef(null);
  const containerRef = useRef(null);
  useEffect(()=>{
    if(value){
      setInputValue(value)
    }
  },[])
 
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      let v;
      setInputValue((prev) => {
        v = prev;
        return prev;
      });
      setShowValueState((prev) => {
        if (prev === true) {
          if(fieldChangeHndlr){
            fieldChangeHndlr(itemKey, v, index);
          }
          return false;
        }
        return prev;
      });
    }
  };
  useEffect(() => {
    if(showValueState) document.addEventListener("mousedown", handleClickOutside, true);
    else document.removeEventListener("mousedown", handleClickOutside, true);
    return () => document.removeEventListener("mousedown", handleClickOutside, true);
  }, [showValueState]);

  function onClickHanlder() {
    if (allowEdit) {
      setInputValue(prev => prev); 
      setShowValueState(true);
    }
  }
  
  useEffect(() => {
    if (showValueState) ref.current.focus();
  }, [showValueState]);

  function onChangeHandler(event) {
    const { name, value } = event.target;
      setInputValue(value);
  }

  function onKeyDownHanlder(e) {
    onKeyDown(e);
    if (e.key === "Enter") {
      setShowValueState(false);
      fieldChangeHndlr(itemKey, inputValue, index);
    } else {
      return;
    }
  }

  useEffect(() => {
    let observer;
    if (addObserver) {
      const element = containerRef.current;
      // Create an observer that watches for changes in the className of the element
      observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === "attributes" && mutation.attributeName === "class") {
            if (element.className.includes("active")) setShowValueState(true);
          }
        });
      });
      // Start observing the element for changes in its attributes
      observer.observe(element, { attributes: true });
    }
    // Cleanup function to stop observing the element when the component unmounts
    return () => {
      if (addObserver) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!showValueState) {containerRef.current.classList.remove("active")}
  }, [showValueState]);
  
  return (
    <div style={{ height: "30px" }} className={containerClass} ref={containerRef}>
      {showValueState ? (
          <Input
            type={inputType || "text"}
            id="date"
            name="date"
            className={`form-control form-control-icon ${innerInputClass}`}
            value={inputValue ? moment(inputValue).format('YYYY-MM-DD'):""}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownHanlder}
            innerRef={ref}
            style={inputStyle}
          />
        )
       : (
        <span className={`d-flex align-items-center w-100 h-100`} onMouseDown={onClickHanlder}>

          {(value ? moment(value).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"))}
        </span>
       )}
    </div> 
  );
}
