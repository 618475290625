import React, { useMemo } from "react";
import { Col, Container, Row } from 'reactstrap';
import { actions, generateColumns } from "./helper";
import WhTableContainer from "../../../../../Components/Common/WhTables";


export default function EmployeesList({setActiveId, tableData, setEditEmployeeData, setRowSelection, toggleDeleteConfirmation, deleteEmployeeConfig, employeeApiLoading}) {
    const columns = useMemo(() => generateColumns(setEditEmployeeData, setActiveId, toggleDeleteConfirmation, deleteEmployeeConfig), []);
    return (
      <React.Fragment>
        <div className="sale-wh-wrapper">
          <Container fluid>
            <Row className="px-4">
              <Col xs={12} className="d-flex justify-content-end align-items-end">
                <div className="d-flex justify-content-center border border-section py-2 ps-4 h-100 me-3">
                  {actions(setActiveId,toggleDeleteConfirmation, deleteEmployeeConfig).map((item) => {
                    return (
                      <div key={item.id} className="action-icon-container me-4" onClick={item.clickHandler || (() => {})}>
                        <img className="action-icon" src={item.icon} />
                        <span>{item.label}</span>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="px-0" xs={12}>
                <WhTableContainer
                  columns={columns}
                  data={tableData || []}
                  customPageSize={7}
                  className="custom-header-css"
                  divClass="table-responsive mt-2 table-height-66vh"
                  tableClass="align-middle table-bordered"
                  theadClass="table-light"
                  showRowSelection={true}
                  setRowSelection={setRowSelection}
                  loading={employeeApiLoading}
                  showNoDataComponent={true}
                />
              </Col>
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    );
}