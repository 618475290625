import React from "react";
import {
     Col, Container, Input, Label, Row, Button,
} from 'reactstrap';
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset-blue.png";
import SaveIcon from "../../../../assets/images/warehouse/salespage/save.png";

const statesData = [
  { id: 1, code: 'AP', name: 'Andhra Pradesh' },
  { id: 2, code: 'AR', name: 'Arunachal Pradesh' },
  { id: 3, code: 'AS', name: 'Assam' },
  { id: 4, code: 'BR', name: 'Bihar' },
  { id: 5, code: 'CG', name: 'Chhattisgarh' },
  { id: 6, code: 'GA', name: 'Goa' },
  { id: 7, code: 'GJ', name: 'Gujarat' },
  { id: 8, code: 'HR', name: 'Haryana' },
  { id: 9, code: 'HP', name: 'Himachal Pradesh' },
  { id: 10, code: 'JH', name: 'Jharkhand' },
  { id: 11, code: 'KA', name: 'Karnataka' },
  { id: 12, code: 'KL', name: 'Kerala' },
  { id: 13, code: 'MP', name: 'Madhya Pradesh' },
  { id: 14, code: 'MH', name: 'Maharashtra' },
  { id: 15, code: 'MN', name: 'Manipur' },
  { id: 16, code: 'ML', name: 'Meghalaya' },
  { id: 17, code: 'MZ', name: 'Mizoram' },
  { id: 18, code: 'NL', name: 'Nagaland' },
  { id: 19, code: 'OD', name: 'Odisha' },
  { id: 20, code: 'PB', name: 'Punjab' },
  { id: 21, code: 'RJ', name: 'Rajasthan' },
  { id: 22, code: 'SK', name: 'Sikkim' },
  { id: 23, code: 'TN', name: 'Tamil Nadu' },
  { id: 24, code: 'TS', name: 'Telangana' },
  { id: 25, code: 'TR', name: 'Tripura' },
  { id: 26, code: 'UP', name: 'Uttar Pradesh' },
  { id: 27, code: 'UK', name: 'Uttarakhand' },
  { id: 28, code: 'WB', name: 'West Bengal' }
];

export const CompanyProfile = () => {
  return (
    <Container fluid className="px-5 py-3">
      <Row>
        <Col xs="9">
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Company Name :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="companyName"
                id="companyName"
                className="form-control py-1"
                placeholder="Enter Company Name"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="customerName" className="form-label mb-0 text-nowrap">
                Slogan :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="customerName"
                id="customerName"
                className="form-control py-1"
                placeholder="Enter Slogan"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="customerName" className="form-label mb-0 text-nowrap">
                Contact :
              </Label>
            </Col>
            <Col xs={5}>
              <Input className="form-control py-1" type="text" />
            </Col>
            <Col xs={5}>
              <Input className="form-control py-1" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Branding :
              </Label>
            </Col>
            <Col xs={10}>
              <Input className="form-control py-1" placeholder="Enter Branding" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Street :
              </Label>
            </Col>
            <Col xs={10} className="d-flex align-items-center">
              <Input className="form-control me-2 py-1" placeholder="Enter Street" type="text" />
              <i className="ri-map-pin-line fs-5"></i>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Suit :
              </Label>
            </Col>
            <Col xs={10}>
              <Input className="form-control py-1" placeholder="Enter Suit" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                City :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" placeholder="Enter City" type="text" />
            </Col>
            <Col xs="1"></Col>
            <Col xs={1} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                State :
              </Label>
            </Col>
            <Col xs={4}>
            <Input
                  type="select"
                  className="form-select"
                  name="state"
                  id="state"
                 
                >
          
                  <option value="">Select</option>
                        {statesData.map((item) => (
                          <option key={item.id} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                </Input>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={4} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
              Flat, House no., Building, Company, Apartment :
              </Label>
            </Col>
            <Col xs={8}>
              <Input className="form-control py-1" placeholder="Enter State" type="text" />
            </Col>
            
            <Col xs={2} className="d-flex align-items-center mt-1">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Pin Code :
              </Label>
            </Col>
            <Col xs={4} className={'mt-1'}>
              <Input className="form-control py-1" placeholder="Enter Zip" type="text" />
            </Col>
            <Col xs="1"></Col>
            <Col xs={1} className="d-flex align-items-center mt-1">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Landmark :
              </Label>
            </Col>
            <Col xs={4} className={'mt-1'}>
              <Input className="form-control py-1" placeholder="Enter landmark" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Phone :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" placeholder="Enter Phone" type="text" />
            </Col>
            <Col xs="1"></Col>
            <Col xs={1} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Fax :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" placeholder="Enter Fax" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Email Id :
              </Label>
            </Col>
            <Col xs={10}>
              <Input className="form-control py-1" placeholder="Enter Email Id" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Company Logo :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" type="file" id="formFile" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Company Header :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" type="file" id="formFile" />
            </Col>
            <Col xs="2" className="d-flex align-items-center">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                  Use Company Header on Report
                </Label>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="3">
          <Button color="primary" className="mb-2 w-100 wh-btn" outline>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={ResetIcon} style={{ height: "32px", width: "32px" }} />
              <span className="ms-2">Update Details</span>
            </span>
          </Button>
          <Button color="primary" className="mb-2 w-100 wh-btn" outline>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={SaveIcon} style={{ height: "32px", width: "32px" }} />
              <span className="ms-2">Save</span>
            </span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};