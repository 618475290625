import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Input,
  ModalFooter,
  Button,
  FormFeedback,
  Form,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
//Actions
import { createUserRoleAct, updateUserRoleAct } from "../../../../../store/usermanagement/usermanagementactions";

function getInitialValues(editModalData) {
  if (editModalData) {
    const { name, isActive } = editModalData;
    return { name, status : isActive };
  } else return { name: "", status: true };
}

const AddRole = ({ isOpen, toggle, editModalData, dispatch, generateApiUrl }) => {
  const addRoleValidation = useFormik({
    initialValues: getInitialValues(editModalData),
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      const { name, status } = values;
      if (!editModalData) createRoleHanlder({ name, isActive:status });
      else updateRoleHanlder(editModalData.id, { name, isActive:status });
    },
  });

  const createRoleHanlder = (payload) => {
    dispatch(createUserRoleAct(generateApiUrl("create_user_role"), payload));
  };
  const updateRoleHanlder = (id, payload) => {
    dispatch(updateUserRoleAct(generateApiUrl("update_user_role", { id }), payload));
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="border-bottom my-0 py-2" toggle={toggle}>
        {editModalData ? "Edit Role" : "Add Role"}
      </ModalHeader>
      <Form onSubmit={addRoleValidation.handleSubmit}>
        <ModalBody>
          <Row>
            <Col xs="12" className="mb-3">
              <Label>Name:</Label>
              <Input
                type="text"
                name="name"
                value={addRoleValidation.values.name}
                onChange={addRoleValidation.handleChange}
                invalid={!!addRoleValidation.touched.name && !!addRoleValidation.errors.name}
              />
              <FormFeedback>{addRoleValidation.touched.name && addRoleValidation.errors.name}</FormFeedback>
            </Col>
            <Col xs="12" className="mb-3">
              <Label>Status:</Label>
              <Input
                type="select"
                className="form-select"
                name="status"
                value={addRoleValidation.values.status}
                onChange={addRoleValidation.handleChange}
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-center">
            <Button color="primary"
              onClick={addRoleValidation.handleSubmit}
            >
              Save
            </Button>
            <Button color="secondary ms-3" onClick={toggle}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddRole;
