import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  FormFeedback,
} from "reactstrap";
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset-blue.png";
import SpecialPricesIcon from "../../../../assets/images/warehouse/salespage/special-prices.png";
import SpecialPrices from "../Details/specialprices";
import { calculateProfitPercent } from "./helper";
//Utility
import { blockInvalidChar, formatAmountSymbol } from "../../../../utility/commonutility";

export const FinancialTab = ({
  id,
  inventoryFinancialValidation: validation,
  inventoryDetailsValidation: { setValues: setDetails },
  dispatch,
  generateApiUrl,
  getSpecialPricesResp,
  getAllCustomersResp,
  createCustomerInventoryPriceResp,
  deleteSpecialPriceForCustomerResp,
  toast,
  permissions
}) => {
  const [showSpecialPricesModal, setShowSpecialPricesModal] = useState(false);
  const prevCreateCustomerInventoryPriceResp = useRef(createCustomerInventoryPriceResp);
  useEffect(() => {
    if (
      createCustomerInventoryPriceResp &&
      createCustomerInventoryPriceResp !== prevCreateCustomerInventoryPriceResp.current
    ) {
      toast.success("Successfully saved customer special prices");
      toggleSpecialPricesModal();
    }
    prevCreateCustomerInventoryPriceResp.current = createCustomerInventoryPriceResp;
  }, [createCustomerInventoryPriceResp]);
  function toggleSpecialPricesModal() {
    setShowSpecialPricesModal(!showSpecialPricesModal);
  }
  function handleDiscountChange(e) {
    const { name, value } = e.target;
    const { retailPrice } = validation.values;
    let discount = "",
      discountValue = "";
    if (value !== "") {
      if (name === "discount") {
        discount = value;
        discountValue = ((retailPrice * value) / 100);
      } else if (name === "discountValue") {
        discountValue = value;
        discount = ((discountValue * 100) / retailPrice);
      }
    }
    validation.setValues((prev) => ({
      ...prev,
      discount,
      discountValue,
    }));
  }
  const handleCostChange = (e) => {
    const { value } = e.target;
    const { retailPrice } = validation.values;
    const profit = retailPrice ? (((retailPrice - value) / retailPrice) * 100).toFixed(2) : "";
    validation.setValues((prev) => ({ ...prev, cost: value, profit }));
  };
  const handleRetailPriceChange = (e) => {
    const { value } = e.target;
    const { cost } = validation.values;
    const profit = value ? (((value - cost) / value) * 100).toFixed(2) : "";
    setDetails((prev) => ({ ...prev, retail: Number(value).toFixed(2) }));
    validation.setValues((prev) => ({ ...prev, retailPrice: value, profit }));
  };
  const handleProfitChange = (e) => {
    const { value } = e.target;
    const { cost } = validation.values;
    const retail = ((100 * cost) / (100 - value)).toFixed(2);
    validation.setValues((prev) => ({ ...prev, retailPrice: retail, profit: value }));
    setDetails((prev) => ({ ...prev, retail }));
  };
  return (
    <>
      <Container fluid className="px-5 py-2 whbrand-text-color">
        <Form>
          <Row className="mb-0">
            <Col xs="9">
              <Row>
                <Col xs="4">
                  <FormGroup>
                    <Label htmlFor="cost" className="form-label mb-0 text-nowrap p-0 required">
                      Cost
                    </Label>
                    <div className="form-icon">
                      <Input
                        className="form-control form-control-icon"
                        placeholder="0.00"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="cost"
                        id="cost"
                        value={validation.values.cost}
                        onChange={handleCostChange}
                        onBlur={() => validation.setValues((prev) => ({ ...prev, cost: Number(prev.cost).toFixed(2) }))}
                        invalid={validation.touched.cost && validation.errors.cost ? true : false}
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                    {validation.touched.cost && validation.errors.cost && (
                      <div className="custom-invalid-feedback">{validation.errors.cost}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <Label htmlFor="retailPrice" className="form-label mb-0 text-nowrap p-0 required">
                      Retail Price
                    </Label>
                    <div className="form-icon">
                      <Input
                        name="retailPrice"
                        id="retailPrice"
                        className="form-control form-control-icon"
                        placeholder="0.00"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={validation.values.retailPrice}
                        onChange={handleRetailPriceChange}
                        onBlur={() =>
                          validation.setValues((prev) => ({
                            ...prev,
                            retailPrice: Number(prev.retailPrice).toFixed(2),
                          }))
                        }
                        invalid={validation.touched.cost && validation.errors.cost ? true : false}
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                    {validation.touched.retailPrice && validation.errors.retailPrice && (
                      <div className="custom-invalid-feedback">{validation.errors.retailPrice}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <Label htmlFor="profitPercent" className="form-label mb-0 text-nowrap p-0">
                      Profit %
                    </Label>
                    <Input
                      className="form-control"
                      placeholder="0.00"
                      type="text"
                      id="profitPercent"
                      value={validation.values.profit}
                      onChange={handleProfitChange}
                      onBlur={() =>
                        validation.setValues((prev) => ({ ...prev, profit: Number(prev.profit).toFixed(2) }))
                      }
                      readOnly={!permissions["UPDATE_INV_PRICES"]}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="4">
                  <FormGroup>
                    <Label htmlFor="mfgPromotion" className="form-label mb-0 text-nowrap p-0">
                      Mfg Promotion
                    </Label>
                    <div className="form-icon">
                      <Input
                        name="mfgPromotion"
                        id="mfgPromotion"
                        value={validation.values.mfgPromotion}
                        onChange={validation.handleChange}
                        className="form-control form-control-icon"
                        placeholder="0.00"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onBlur={() =>
                          validation.setValues((prev) => ({
                            ...prev,
                            mfgPromotion: Number(prev.mfgPromotion).toFixed(2),
                          }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>

                    <div className="form-check mt-2">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="costFixed"
                        name="costFixed"
                        checked={validation.values.costFixed}
                        onChange={validation.handleChange}
                        disabled={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="costFixed">
                        Fixed Cost
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <Label htmlFor="addToCost" className="form-label mb-0 text-nowrap p-0">
                    Add to Cost
                  </Label>
                  <Row>
                    <Col xs="6">
                      <div className="input-group">
                        <Input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          aria-describedby="basic-addon2"
                          name="addToCost"
                          id="addToCost"
                          value={validation.values.addToCost}
                          onChange={validation.handleChange}
                          onBlur={() =>
                            validation.setValues((prev) => ({ ...prev, addToCost: Number(prev.addToCost).toFixed(2) }))
                          }
                          readOnly={!permissions["UPDATE_INV_PRICES"]}
                        />
                        <span className="input-group-text py-1 wh-input-bg" id="basic-addon2">
                          %
                        </span>
                      </div>
                    </Col>
                    <Col xs="6">
                      <Input className="form-control" type="text" readOnly />
                    </Col>
                  </Row>
                  <div className="form-check mt-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="costPerQty"
                      name="costPerQty"
                      checked={validation.values.costPerQty}
                      onChange={validation.handleChange}
                      disabled={!permissions["UPDATE_INV_PRICES"]}
                    />
                    <Label className="form-check-label text-nowrap" htmlFor="costPerQty">
                      Cost Per Quantity
                    </Label>
                  </div>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <Label htmlFor="unitCharge" className="form-label mb-0 text-nowrap p-0">
                      Unit Charge
                    </Label>
                    {/* <div className="form-icon">
                      <Input
                        name="unitCharge"
                        id="unitCharge"
                        className="form-control form-control-icon"
                        placeholder="0.00"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div> */}
                    <Input
                      name="unitCharge"
                      id="unitCharge"
                      className="form-control"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      readOnly={!permissions["UPDATE_INV_PRICES"]}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="4">
                  <FormGroup>
                    <Label htmlFor="osCost" className="form-label mb-0 text-nowrap p-0">
                      Out Of State Cost
                    </Label>
                    <div className="form-icon">
                      <Input
                        name="osCost"
                        id="osCost"
                        value={validation.values.osCost}
                        onChange={validation.handleChange}
                        className="form-control form-control-icon"
                        placeholder="0.00"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onBlur={() =>
                          validation.setValues((prev) => ({ ...prev, osCost: Number(prev.osCost).toFixed(2) }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <Label htmlFor="osRetail" className="form-label mb-0 text-nowrap p-0">
                      Out of State Retail
                    </Label>
                    <div className="form-icon">
                      <Input
                        name="osRetail"
                        id="osRetail"
                        value={validation.values.osRetail}
                        onChange={validation.handleChange}
                        className="form-control form-control-icon"
                        placeholder="0.00"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onBlur={() =>
                          validation.setValues((prev) => ({ ...prev, osRetail: Number(prev.osRetail).toFixed(2) }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="4" className="d-flex align-items-end">
                  <FormGroup className="flex-grow-1">
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap p-0"></Label>
                    <Input name="companyName" id="companyName" className="form-control" type="text"  readOnly={!permissions["UPDATE_INV_PRICES"]} />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col xs="3">
              {id === "new" ? (
                <Button color="primary" className="mb-2 w-100 p-0 wh-btn" outline onClick={validation.handleSubmit} disabled={!permissions["UPDATE_INV_PRICES"]}>
                  <span className="d-flex justify-content-center align-items-center">
                    <i className="ri-save-line" style={{ fontSize: "28px" }}></i>
                    <span className="ms-2">Save</span>
                  </span>
                </Button>
              ) : (
                <Button color="primary" className="mb-2 w-100 wh-btn" outline onClick={validation.handleSubmit} disabled={!permissions["UPDATE_INV_PRICES"]}>
                  <span className="d-flex justify-content-center align-items-center">
                    <img src={ResetIcon} style={{ height: "32px", width: "32px" }} />
                    <span className="ms-2">Update Info</span>
                  </span>
                </Button>
              )}

              <Button color="primary" className="mb-2 w-100 wh-btn" onClick={toggleSpecialPricesModal} outline disabled={!permissions["UPDATE_INV_PRICES"]}>
                <span className="d-flex justify-content-center align-items-center">
                  <img src={SpecialPricesIcon} style={{ height: "32px", width: "32px" }} />
                  <span className="ms-2">Special Customer Price</span>
                </span>
              </Button>
            </Col>
          </Row>
          <hr />
          {/* <div className="border ps-4 my-2 wh-border-thick" style={{ position: "relative" }}>
            <Label className="fs-5 " style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg">Sale Price</span>
            </Label>
            <div className="d-flex mt-3">
              <FormGroup className="pe-4" style={{ width: "20%" }}>
                <Label htmlFor="salePrice" className="form-label mb-0 text-nowrap p-0">
                  Price
                </Label>
                <div className="form-icon">
                  <Input
                    name="salePrice"
                    id="salePrice"
                    className="form-control form-control-icon"
                    placeholder="0.00"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={validation.values.salePrice}
                    onChange={validation.handleChange}
                    onBlur={() =>
                      validation.setValues((prev) => ({ ...prev, salePrice: Number(prev.salePrice).toFixed(2) }))
                    }
                    readOnly={!permissions["UPDATE_INV_PRICES"]}
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
              </FormGroup>
              <FormGroup className="pe-4" style={{ width: "20%" }}>
                <Label htmlFor="companyName" className="form-label mb-0 text-nowrap p-0">
                  Min. Quantity
                </Label>
                <Input
                  name="companyName"
                  id="companyName"
                  className="form-control"
                  placeholder=""
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  readOnly={!permissions["UPDATE_INV_PRICES"]}
                />
              </FormGroup>
              <FormGroup className="pe-4" style={{ width: "20%" }}>
                <Label htmlFor="companyName" className="form-label mb-0 text-nowrap p-0">
                  Qty Price
                </Label>
                <Input
                  name="companyName"
                  id="companyName"
                  className="form-control"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  readOnly={!permissions["UPDATE_INV_PRICES"]}
                />
              </FormGroup>
              <FormGroup className="pe-4" style={{ width: "20%" }}>
                <Label htmlFor="saleFrom" className="form-label mb-0 text-nowrap p-0">
                  Sales From
                </Label>
                <Input
                  name="saleFrom"
                  id="saleFrom"
                  value={validation.values.saleFrom}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter Sales From"
                  type="date"
                  readOnly={!permissions["UPDATE_INV_PRICES"]}
                />
              </FormGroup>
              <FormGroup className="pe-4" style={{ width: "20%" }}>
                <Label htmlFor="saleTo" className="form-label mb-0 text-nowrap p-0">
                  Sales To
                </Label>
                <Input
                  name="saleTo"
                  id="saleTo"
                  value={validation.values.saleTo}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter Out of State Retail"
                  type="date"
                  readOnly={!permissions["UPDATE_INV_PRICES"]}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup className="d-inline-block pe-4" style={{ width: "20%" }}>
                <Label htmlFor="salePriceOos" className="form-label mb-0 text-nowrap p-0">
                  Out Of State Sale Price
                </Label>
                <div className="form-icon">
                  <Input
                    name="salePriceOos"
                    id="salePriceOos"
                    value={validation.values.salePriceOos}
                    onChange={validation.handleChange}
                    className="form-control form-control-icon"
                    placeholder="0.00"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onBlur={() =>
                      validation.setValues((prev) => ({ ...prev, salePriceOos: Number(prev.salePriceOos).toFixed(2) }))
                    }
                    readOnly={!permissions["UPDATE_INV_PRICES"]}
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
              </FormGroup>
              <FormGroup className="d-inline-block pe-4" style={{ width: "20%" }}>
                <Label htmlFor="companyName" className="form-label mb-0 text-nowrap p-0"></Label>
                <Input name="companyName" id="companyName" className="form-control" type="text" readOnly={!permissions["UPDATE_INV_PRICES"]} />
              </FormGroup>
              <FormGroup className="d-inline-block pe-4" style={{ width: "20%" }}>
                <Label htmlFor="companyName" className="form-label mb-0 text-nowrap p-0"></Label>
                <Input name="companyName" id="companyName" className="form-control" type="text" readOnly={!permissions["UPDATE_INV_PRICES"]} />
              </FormGroup>
            </div>
          </div> */}

          <div className="border ps-4 my-3 wh-border-thick" style={{ position: "relative" }}>
            <Label className="fs-5 " style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg">Discount</span>
            </Label>
            <div className="d-flex mt-3">
              <FormGroup className="pe-4" style={{ width: "20%" }}>
                <Label htmlFor="discount" className="form-label mb-0 text-nowrap p-0">
                  Discount
                </Label>
                <div className="d-flex">
                  <div className="w-50">
                    <div className="form-icon">
                      <Input
                        className="form-control form-control-icon"
                        placeholder="0.00"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="discountValue"
                        // value={getDiscountValue(validation.values.retailPrice, validation.values.discount)}
                        value={validation.values.discountValue}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value <= validation.values.retailPrice)
                            handleDiscountChange(e);
                        }}
                        onBlur={() =>
                          validation.setValues((prev) => ({
                            ...prev,
                            discountValue: Number(prev.discountValue).toFixed(2),
                          }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                  </div>
                  <div className="w-50">
                    <div className="form-icon right">
                      <Input
                        name="discount"
                        id="discount"
                        className="form-control form-control-icon"
                        // placeholder="0"
                        type="number"
                        value={Number(validation.values.discount).toFixed(2)}
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value <= 100) handleDiscountChange(e);
                        }}
                        onKeyDown={blockInvalidChar}
                        onWheel={(e) => e.target.blur()}
                        onBlur={() =>
                          validation.setValues((prev) => ({ ...prev, discount: Number(prev.discount).toFixed(2) }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>%</i>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="pe-4" style={{ width: "20%" }}>
                <Label htmlFor="discFrom" className="form-label mb-0 text-nowrap p-0">
                  From
                </Label>
                <Input
                  name="discFrom"
                  id="discFrom"
                  value={validation.values.discFrom}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter Sales From"
                  type="date"
                  readOnly={!permissions["UPDATE_INV_PRICES"]}
                />
              </FormGroup>
              <FormGroup className="pe-4" style={{ width: "20%" }}>
                <Label htmlFor="discTo" className="form-label mb-0 text-nowrap p-0">
                  To
                </Label>
                <Input
                  name="discTo"
                  id="discTo"
                  value={validation.values.discTo}
                  onChange={validation.handleChange}
                  className="form-control"
                  placeholder="Enter Out of State Retail"
                  type="date"
                  readOnly={!permissions["UPDATE_INV_PRICES"]}
                />
              </FormGroup>
            </div>
            <div className="d-flex mt-1">
              <Label className="me-5">Apply to all</Label>
              <FormGroup className="me-5">
                <Input
                  type="checkbox"
                  name="discR"
                  id="discR"
                  checked={validation.values.discR}
                  onChange={validation.handleChange}
                  className="form-check-input"
                  placeholder="Enter Out of State Retail"
                  disabled={!permissions["UPDATE_INV_PRICES"]}
                />
                <Label htmlFor="discR" className="form-label mb-0 text-nowrap p-0 ms-2">
                  Retail
                </Label>
              </FormGroup>
              <FormGroup className="me-5">
                <Input
                  type="checkbox"
                  name="discA"
                  id="discA"
                  checked={validation.values.discA}
                  onChange={validation.handleChange}
                  className="form-check-input"
                  placeholder="Enter Out of State Retail"
                  disabled={!permissions["UPDATE_INV_PRICES"]}
                />
                <Label htmlFor="discA" className="form-label mb-0 text-nowrap p-0 ms-2">
                  Code A
                </Label>
              </FormGroup>
              <FormGroup className="me-5">
                <Input
                  type="checkbox"
                  name="discB"
                  id="discB"
                  checked={validation.values.discB}
                  onChange={validation.handleChange}
                  className="form-check-input"
                  placeholder="Enter Out of State Retail"
                  disabled={!permissions["UPDATE_INV_PRICES"]}
                />
                <Label htmlFor="discB" className="form-label mb-0 text-nowrap p-0 ms-2">
                  Code B
                </Label>
              </FormGroup>
              <FormGroup className="me-5">
                <Input
                  type="checkbox"
                  name="discC"
                  id="discC"
                  checked={validation.values.discC}
                  onChange={validation.handleChange}
                  className="form-check-input"
                  placeholder="Enter Out of State Retail"
                  disabled={!permissions["UPDATE_INV_PRICES"]}
                />
                <Label htmlFor="discC" className="form-label mb-0 text-nowrap p-0 ms-2">
                  Code C
                </Label>
              </FormGroup>
              <FormGroup className="me-5">
                <Input
                  type="checkbox"
                  name="discD"
                  id="discD"
                  checked={validation.values.discD}
                  onChange={validation.handleChange}
                  className="form-check-input"
                  placeholder="Enter Out of State Retail"
                  disabled={!permissions["UPDATE_INV_PRICES"]}
                />
                <Label htmlFor="discD" className="form-label mb-0 text-nowrap p-0 ms-2">
                  Code D
                </Label>
              </FormGroup>
              <FormGroup className="me-5">
                <Input
                  type="checkbox"
                  name="custDisc"
                  id="custDisc"
                  checked={validation.values.custDisc}
                  onChange={validation.handleChange}
                  className="form-check-input"
                  placeholder="Enter Out of State Retail"
                  disabled={!permissions["UPDATE_INV_PRICES"]}
                />
                <Label htmlFor="custDisc" className="form-label mb-0 text-nowrap p-0 ms-2">
                  Add Customers Discount
                </Label>
              </FormGroup>
            </div>
          </div>

          <Row>
            <Col xs="9">
              <div className="border ps-4 h-100 wh-border-thick" style={{ position: "relative" }}>
                <Label className="fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
                   <span className=" px-1 wh-item-bg">Customer Code Pricing</span>
                </Label>
                <div className="mt-3">
                  <div className="d-inline-block" style={{ width: "13%" }}>
                    <Label className="form-label text-nowrap p-0">Code</Label>
                  </div>
                  <FormGroup className="pe-4 d-inline-block" style={{ width: "29%" }}>
                    <Label className="form-label text-nowrap p-0">Price</Label>
                  </FormGroup>
                  <FormGroup className="pe-4 d-inline-block" style={{ width: "29%" }}>
                    <Label className="form-label text-nowrap p-0">Qty</Label>
                  </FormGroup>
                  <FormGroup className="pe-4 d-inline-block" style={{ width: "29%" }}>
                    <Label className="form-label text-nowrap p-0">Qty Price</Label>
                  </FormGroup>
                </div>
                <div>
                  <div style={{ width: "13%" }} className="h-100 d-inline-block">
                    <Label className="form-label mb-0  text-nowrap ps-2">A</Label>
                  </div>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <div className="form-icon">
                      <Input
                        name="priceA"
                        id="priceA"
                        value={validation.values.priceA}
                        onChange={validation.handleChange}
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                        onBlur={() =>
                          validation.setValues((prev) => ({ ...prev, priceA: Number(prev.priceA).toFixed(2) }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    {/* <div className="form-icon">
                      <Input
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div> */}
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                </div>
                <div>
                  <div style={{ width: "13%" }} className="h-100 d-inline-block">
                    <Label className="form-label mb-0  text-nowrap ps-2">B</Label>
                  </div>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <div className="form-icon">
                      <Input
                        name="priceB"
                        id="priceB"
                        value={validation.values.priceB}
                        onChange={validation.handleChange}
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                        onBlur={() =>
                          validation.setValues((prev) => ({ ...prev, priceB: Number(prev.priceB).toFixed(2) }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    {/* <div className="form-icon">
                      <Input
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div> */}
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                </div>
                <div>
                  <div style={{ width: "13%" }} className="h-100 d-inline-block">
                    <Label className="form-label mb-0  text-nowrap ps-2">C</Label>
                  </div>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <div className="form-icon">
                      <Input
                        name="priceC"
                        id="priceC"
                        value={validation.values.priceC}
                        onChange={validation.handleChange}
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                        onBlur={() =>
                          validation.setValues((prev) => ({ ...prev, priceC: Number(prev.priceC).toFixed(2) }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    {/* <div className="form-icon">
                      <Input
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div> */}
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                </div>
                <div>
                  <div style={{ width: "13%" }} className="h-100 d-inline-block">
                    <Label className="form-label mb-0  text-nowrap ps-2">D</Label>
                  </div>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <div className="form-icon">
                      <Input
                        name="priceD"
                        id="priceD"
                        value={validation.values.priceD}
                        onChange={validation.handleChange}
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                        onBlur={() =>
                          validation.setValues((prev) => ({ ...prev, priceD: Number(prev.priceD).toFixed(2) }))
                        }
                        readOnly={!permissions["UPDATE_INV_PRICES"]}
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    {/* <div className="form-icon">
                      <Input
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div> */}
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                </div>
                <div>
                  <div style={{ width: "13%" }} className="h-100 d-inline-block">
                    <Label className="form-label mb-0  text-nowrap ps-2">Out of State Price</Label>
                  </div>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}></FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 pe-4 d-inline-block" style={{ width: "29%" }}>
                    {/* <div className="form-icon">
                      <Input
                        className="form-control form-control-icon"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="0.00"
                      />
                      <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                    </div> */}
                    <Input className="form-control" type="number" onWheel={(e) => e.target.blur()}  readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                  </FormGroup>
                </div>
              </div>
            </Col>
            {/* <Col xs="3">
              <div className="border ps-4 pt-2 h-100" style={{ position: "relative" }}>
                <Label className="fs-5 " style={{ position: "absolute", transform: "translateY(-50%)" }}>
                  Customer Code Pricing
                </Label>
                <FormGroup className="d-flex align-items-center mb-0">
                  <Input type="checkbox" className="form-check-input my-0 me-4"  disabled={!permissions["UPDATE_INV_PRICES"]}/>
                  <Label className="form-label text-nowrap p-0 my-0">Ask for Pricing</Label>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0">
                  <Input type="checkbox" className="form-check-input my-0 me-4" disabled={!permissions["UPDATE_INV_PRICES"]}/>
                  <Label className="form-label text-nowrap p-0 my-0">Ask for Description</Label>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0">
                  <Input type="checkbox" className="form-check-input my-0 me-4" disabled={!permissions["UPDATE_INV_PRICES"]}/>
                  <Label className="form-label text-nowrap p-0 my-0">Serialized</Label>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0">
                  <Input type="checkbox" className="form-check-input my-0 me-4" disabled={!permissions["UPDATE_INV_PRICES"]}/>
                  <Label className="form-label text-nowrap p-0 my-0">Tax on Sales</Label>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0">
                  <Input type="checkbox" className="form-check-input my-0 me-4" disabled={!permissions["UPDATE_INV_PRICES"]}/>
                  <Label className="form-label text-nowrap p-0 my-0 me-5">Purchase</Label>
                  <Input placeholder="0.00%" style={{ width: "100px" }} readOnly={!permissions["UPDATE_INV_PRICES"]}/>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0">
                  <Input type="checkbox" className="form-check-input my-0 me-4" disabled={!permissions["UPDATE_INV_PRICES"]}/>
                  <Label className="form-label text-nowrap p-0 my-0">No Such Discount</Label>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0">
                  <Input type="checkbox" className="form-check-input my-0 me-4" disabled={!permissions["UPDATE_INV_PRICES"]}/>
                  <Label className="form-label text-nowrap p-0 my-0">No Returns</Label>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0">
                  <Input type="checkbox" className="form-check-input my-0 me-4" disabled={!permissions["UPDATE_INV_PRICES"]}/>
                  <Label className="form-label text-nowrap p-0 my-0">Sell Below Cost</Label>
                </FormGroup>
              </div>
            </Col> */}
          </Row>
        </Form>
      </Container>
      <Modal id="showSearchModal" isOpen={showSpecialPricesModal} toggle={toggleSpecialPricesModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggleSpecialPricesModal}>
          Customer Price
        </ModalHeader>
        <ModalBody className="px-0 pt-0">
          <SpecialPrices
            dispatch={dispatch}
            generateApiUrl={generateApiUrl}
            getSpecialPricesResp={getSpecialPricesResp}
            id={id}
            getAllCustomersResp={getAllCustomersResp}
            toggleSpecialPricesModal={toggleSpecialPricesModal}
            deleteSpecialPriceForCustomerResp={deleteSpecialPriceForCustomerResp}
            toast={toast}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
