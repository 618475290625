export default {
  CHANGE_USER_MANAGEMENT_API_LOADING_STATUS: "CHANGE_USER_MANAGEMENT_API_LOADING_STATUS",

  CREATE_USER_ROLE_REQUEST: "CREATE_USER_ROLE_REQUEST",
  CREATE_USER_ROLE_SUCCESS: "CREATE_USER_ROLE_SUCCESS",
  CREATE_USER_ROLE_FAIL: "CREATE_USER_ROLE_FAIL",

  UPDATE_USER_ROLE_REQUEST: "UPDATE_USER_ROLE_REQUEST",
  UPDATE_USER_ROLE_SUCCESS: "UPDATE_USER_ROLE_SUCCESS",
  UPDATE_USER_ROLE_FAIL: "UPDATE_USER_ROLE_FAIL",

  GET_USER_ROLES_REQUEST: "GET_USER_ROLES_REQUEST",
  GET_USER_ROLES_SUCCESS: "GET_USER_ROLES_SUCCESS",
  GET_USER_ROLES_FAIL: "GET_USER_ROLES_FAIL",

  GET_ALL_PERMISSIONS_REQUEST: "GET_ALL_PERMISSIONS_REQUEST",
  GET_ALL_PERMISSIONS_SUCCESS: "GET_ALL_PERMISSIONS_SUCCESS",
  GET_ALL_PERMISSIONS_FAIL: "GET_ALL_PERMISSIONS_FAIL",

  GET_PERMISSIONS_BY_ROLE_REQUEST: "GET_PERMISSIONS_BY_ROLE_REQUEST",
  GET_PERMISSIONS_BY_ROLE_SUCCESS: "GET_PERMISSIONS_BY_ROLE_SUCCESS",
  GET_PERMISSIONS_BY_ROLE_FAIL: "GET_PERMISSIONS_BY_ROLE_FAIL",

  CREATE_ROLE_PERMISSION_REQUEST: "CREATE_ROLE_PERMISSION_REQUEST",
  CREATE_ROLE_PERMISSION_SUCCESS: "CREATE_ROLE_PERMISSION_SUCCESS",
  CREATE_ROLE_PERMISSION_FAIL: "CREATE_ROLE_PERMISSION_FAIL",
  
  GET_ALL_STATIONS_REQUEST : "GET_ALL_STATIONS_REQUEST", 
  GET_ALL_STATIONS_SUCCESS : "GET_ALL_STATIONS_SUCCESS", 
  GET_ALL_STATIONS_FAIL : "GET_ALL_STATIONS_FAIL",

  DELETE_ROLES_REQUEST: "DELETE_ROLES_REQUEST",
  DELETE_ROLES_SUCCESS: "DELETE_ROLES_SUCCESS",
  DELETE_ROLES_FAIL: "DELETE_ROLES_FAIL",
};
